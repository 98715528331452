export interface BankOption {
  name: string;
  minNum: number;
  maxNum: number;
}

export const starterPages: string[] = [
  'email-verification',
  'store-owner',
  'store-type',
  'business-hours',
  'store-details',
  'pickup-address',
  'address-info',
  'account-details',
];

export const bankOptions: BankOption[] = [
  { name: 'Banco de Oro (BDO)', minNum: 10, maxNum: 12 },
  { name: 'Bank of Commerce', minNum: 12, maxNum: 12 },
  { name: 'Bank of the Philippine Islands (BPI)', minNum: 10, maxNum: 10 },
  { name: 'China Bank', minNum: 10, maxNum: 12 },
  { name: 'China Bank Savings', minNum: 10, maxNum: 12 },
  { name: 'EastWest Bank', minNum: 12, maxNum: 12 },
  { name: 'GCash', minNum: 11, maxNum: 11 },
  { name: 'Metrobank Philippines', minNum: 10, maxNum: 13 },
  { name: 'Maya', minNum: 11, maxNum: 11 },
  { name: 'Philippine National Bank', minNum: 10, maxNum: 12 },
  { name: 'Philippine Savings Bank', minNum: 12, maxNum: 12 },
  { name: 'Rizal Commercial Bank Corporation (RCBC)', minNum: 10, maxNum: 10 },
  { name: 'Robinsons Bank Corporation', minNum: 12, maxNum: 15 },
  { name: 'Security Bank', minNum: 13, maxNum: 13 },
  { name: 'UCPB Savings Bank', minNum: 10, maxNum: 12 },
  { name: 'UnionBank of the Philippines', minNum: 12, maxNum: 12 },
];
