import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  forwardRef,
  Input,
  Provider,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

const REACTIVE_DROPDOWN_INPUT_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveDropdownInputComponent),
  multi: true,
};

@Component({
  selector: 'app-reactive-dropdown-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './reactive-dropdown-input.component.html',
  styleUrls: ['./reactive-dropdown-input.component.scss'],
  providers: [REACTIVE_DROPDOWN_INPUT_ACCESSOR],
})
export class ReactiveDropdownInputComponent<T extends { [key: string]: any }>
  implements ControlValueAccessor
{
  @Input() label: string = '';
  @Input() options: T[] = [];
  @Input() bindValue: keyof T | string = 'id';
  @Input() bindLabel: keyof T | string = 'name';
  @Input() id: string = '';
  @Input() control: FormControl<T | null | string | number> = new FormControl(
    null
  );
  @Input() required: boolean = false;
  @Input() errorMessage: string = 'Invalid selection';
  @Input() placeholder: string = 'Select an option';
  @Input() errors: ValidationErrors | null = null;

  @ContentChild('optionsSlot') optionsSlot: TemplateRef<any> | null = null;

  constructor() {}

  ngOnInit(): void {
    if (this.required) {
      this.control.setValidators(Validators.required);
    }
  }

  private onChange: (value: T | null) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(obj: T | null): void {
    this.onChange(obj);
    this.control.setValue(obj, {
      emitEvent: false,
    });
  }
  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  getOptionValue(option: T): any {
    return option[this.bindValue as keyof T];
  }

  getOptionLabel(option: T): any {
    return option[this.bindLabel as keyof T];
  }
}
