import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { starterPages } from '../starter-data';
import { ReactiveTextField2Component } from 'src/app/components/inputs/reactive-text-field2/reactive-text-field2.component';
import { StarterFormDataService } from '../starter-form-data.service';
import { lastValueFrom } from 'rxjs';
interface FormData {
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-store-owner',
  standalone: true,
  imports: [CtaButtonsComponent, ReactiveTextField2Component],
  templateUrl: './store-owner.component.html',
  styleUrls: ['./store-owner.component.scss'],
})
export class StoreOwnerComponent implements OnInit {
  starterPages: string[] = starterPages;
  formData: FormData[] = [];
  firstNameControl: FormControl = new FormControl('', Validators.required);
  lastNameControl: FormControl = new FormControl('', Validators.required);
  merchantData: any;
  loading = false;
  constructor(private starterService: StarterFormDataService) { }

  ngOnInit() {
    this.starterService.setCheckPointIndex(1);
    setTimeout(() => {
      this.merchantData = this.starterService.merchantData;
      if (typeof this.merchantData === 'undefined') {
        this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
          this.starterService.merchantData = data;
          this.merchantData = this.starterService.merchantData;
          this.firstNameControl.setValue(this.merchantData.first_name);
          this.lastNameControl.setValue(this.merchantData.last_name);
        });
      } else {
        this.firstNameControl.setValue(this.merchantData.first_name);
        this.lastNameControl.setValue(this.merchantData.last_name);
      }
    }, 3000);
  }

  isFormValid(): boolean {
    return this.firstNameControl.valid && this.lastNameControl.valid;
  }

  handleNextClick = async () => {
    this.loading = true;
    const formData = {
      first_name: this.firstNameControl.value,
      last_name: this.lastNameControl.value,
      email: this.merchantData.email,
      mobile_number: this.merchantData.mobile_number,
    };
    console.log('Form Data:', formData);
    return await lastValueFrom(this.starterService.updateMerchantDetails(formData)).then(
      (response) => {
        console.log('update profile', response);
        // Navigate to next page
        this.loading = false;
        return true
      },
      (error) => {
        console.error('update profile:', error);
        this.loading = false;
        return false
      }
    );
  }
}
