import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private get localStorage() {
    return window.localStorage;
  }

  constructor() {}

  public getUserId() {
    return this.localStorage.getItem('userId');
  }

  public saveUserId(userId: string) {
    this.localStorage.setItem('userId', userId);
    this.localStorage.setItem('user_id', userId);
    return userId;
  }
}
