<div class="main-container">
  <h3 class="title">Email Verification</h3>
  <span class="note"
    >We have sent you a verification email. Please enter code sent to you.</span
  >

  <app-otp #input="ngModel" [(ngModel)]="data" [size]="6" (ngModelChange)="handlechange()"></app-otp>

  <div class="tw-flex tw-justify-center tw-pb-2">
    <div class="tw-text-red-500 tw-text-[12px]"> {{ errorMessage }}</div>
  </div>

  <div class="note otp">
    <span>Didn’t receive OTP?
       
      <a href="" 
      (click)="resendOtp($event)"
      [class.disabled]="!canResend"> 
      {{ countdownString }}
    </a
    ></span>
  </div>

  <app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [nextCallback]="handleNext.bind(this)"
    [backButton]="false"
    [disabled]="!validated"

  ></app-cta-buttons>
</div>
