
<div class="tw-flex tw-flex-row tw-gap-[5px] tw-pt-[10px]" style="width: 100%">
  <div class="tw-flex tw-flex-col tw-grow" style="width: 100%">
    <div
      class="tw-flex tw-h-[28px] tw-items-center tw-pb-[0.31rem]"
      style="width: 100%"
    >
      <div class="tw-flex" style="width: 100%">
        <div class="tw-flex tw-justify-between" style="width: 100%">
          <h4
            class="tw-font-inter tw-text-[1.125rem] tw-font-semibold tw-text-enstack-onyx-100"
            style="padding: 0; margin: 0"
          >
            {{ itemName }}
          </h4>

          <reactive-slide-toggle
            (toggleChanged)="handleToggleChange($event)"
            [isChecked]="isChecked"
            [isDisabled]="isDisabled"
            [toggleOffDisabled]="toggleOffDisabled"
            *ngIf="withToggle"
          />
        </div>

        <div class="tw-w-[3px]"></div>

        <div *ngIf="isShowBeta">
          <div
            class="tw-text-bold tw-text-white tw-text-[11px] tw-bg-[#0044FF] tw-p-[4px] tw-rounded-[3px] tw-flex-wrap"
          >
            BETA
          </div>
        </div>
      </div>
      <div *ngIf="hasBadge">
        <div
          *ngIf="badgeType === 'warning'"
          class="tw-flex tw-items-center tw-justify-center tw-p-1 tw-ml-2 tw-bg-enstack-status-yellow tw-rounded-[1px]"
        >
          <p
            class="tw-font-inter tw-text-[10px] tw-font-semibold tw-text-enstack-onyx-100 tw-uppercase"
          >
            {{ badgeContent }}
          </p>
        </div>
        <div
          *ngIf="badgeType !== 'warning'"
          class="tw-flex tw-items-center tw-justify-center tw-p-1 tw-ml-2 tw-bg-enstack-royalBlue-25 tw-rounded-[1px]"
        >
          <p
            class="tw-font-inter tw-text-[10px] tw-font-semibold tw-text-white tw-uppercase"
          >
            {{ badgeContent }}
          </p>
        </div>
      </div>
      <!-- <div *ngIf="hasTooltip">
        <tooltip [tooltipContent]="tooltipContent"></tooltip>
      </div> -->
    </div>
    <p
      *ngIf="itemDescription != ''"
      class="tw-font-inter tw-text-enstack-onyx-80"
      style="margin-bottom: 0.31rem"
    >
      {{ itemDescription }}
    </p>
    <p
      *ngIf="itemHelper != ''"
      class="tw-font-inter tw-text-[10px] tw-text-enstack-onyx-80"
      style="margin-bottom: 0.31rem"
    >
      {{ itemHelper }}
    </p>

    <div *ngIf="specialContent != ''" [innerHTML]="specialContent"></div>
  </div>
</div>

<!-- Sample usage
<app-toggle-item 
    itemName="Non-cash"
    itemDescription="Accept e-wallet and bank transfer payments"
    (toggleChanged)="handleToggleChange('nonCash', $event)"
    [hasBadge]="true"
    badgeType = "warning"
    badgeContent = "Warning"
    [hasTooltip]="true"
    tooltipContent="Testing tooltip content"
/> -->
