
<div class="container">
    <div class="section">
        <h3 class="title">{{ 'address-info.title' | i18next }}</h3>
        <p class="note">
            {{ 'address-info.description' | i18next }}
        </p>
    </div>
    
    @if (!withPinLocation) {
    <div class="section">
        <!-- <div class="title">Province <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="{{'address-info.province-input' | i18next}}*"
            bindLabel="name"
            id="province"
            appearance="outline"
            [options]="provinceQuery.data() ?? []"
            [loading]="provinceQuery.isFetching()"
            [formControl]="getFormControl('selectedProvince')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedProvince')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">City <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="{{ 'address-info.city-input' | i18next}}*"
            bindLabel="name"
            id="city"
            appearance="outline"
            [options]="cityQuery.data() ?? []"
            [loading]="cityQuery.isFetching()"
            [formControl]="getFormControl('selectedCity')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedCity')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">Barangay <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="{{ 'address-info.barangay-input' | i18next}}*"
            bindLabel="name"
            id="barangay"
            appearance="outline"
            [options]="barangayQuery.data() ?? []"
            [loading]="barangayQuery.isFetching()"
            [formControl]="getFormControl('selectedBarangay')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedBarangay')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">Zip Code <span class="required">*</span></div>
    <app-reactive-text-input
      type="number"
      placeholder="Enter zip code"
      [required]="true"
      [control]="zipCode"
    ></app-reactive-text-input> -->

        <app-reactive-text-field2
            type="number"
            label="{{ 'address-info.zipcode-input' | i18next}}"
            [required]="true"
            [control]="zipCode"
            inputType="number"
        >
        </app-reactive-text-field2>
    </div>

    <div class="section">
        <!-- <div class="title">
      House/Unit Number, Street name, Subdivision
      <span class="required">*</span>
    </div>
    <app-reactive-text-input
      placeholder="Enter address"
      [required]="true"
      [control]="addressDetails"
    ></app-reactive-text-input> -->

        <app-reactive-text-field2
            label="{{ 'address-info.street-input' | i18next }}"
            [required]="true"
            [control]="addressDetails"
        >
        </app-reactive-text-field2>
    </div>

        <button 
        class="pin-location-button"
        [ngClass]="{'disabled': !allFilled}"
        [disabled]="!allFilled"
        (click)=triggerPinLocation()
        >
        <span>Pin Location</span>
    </button>
}


@else {
    
    <app-pickup-address
    [city]="this.formGroup.get('selectedCity')?.value?.name || ''"
    [province]="this.formGroup.get('selectedProvince')?.value?.name || ''"
    [barangay]="this.formGroup.get('selectedBarangay')?.value?.name || ''"
    [zipCode]="this.formGroup.get('zipCode')?.value || ''"
    [addressDetails]="this.formGroup.get('addressDetails')?.value || ''"
    (togglePinLocation)="withPinLocation = !withPinLocation"
    (longlat)="longlat"
    #pickupAddress
    >    
    </app-pickup-address>

        <app-cta-buttons
        currentPage="address-info"
        [pageNames]="starterPages"
        tier="starter"
        [disabled]="!allFilled"
        [data]="formData"
        (nextEvent)="handleNextClick()"
        [nextCallback]="handleNextClick"
        [isLoading]="loading"
        >
    </app-cta-buttons>
}

</div>