import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private uploadUrl = `${environment.UPLOAD_URL}/upload`;

  constructor(
    private http: HttpClient, 
    private imageCompress: NgxImageCompressService
  ) {}

  dataURLtoFile(dataurl: string, filename: string) {
    console.log("IC02 Data to url");
    const arr = dataurl.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : 'application/octet-stream'; // Default to a generic binary type if match fails
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  compressImage(file: File): Promise<File | null> {
    console.log("IC01 Compressor start", file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = (event: any) => {
        this.imageCompress.compressFile(event.target.result, -1, 50, 50).then(
          (result: string) => {
            if (!result) {
              console.error('Compression failed');
              resolve(null);
              return;
            }
  
            const compressedFile = this.dataURLtoFile(result, file.name);
            console.log("IC03 Compress done", compressedFile);
            resolve(compressedFile);
          }
        ).catch((error) => {
          console.error('Error compressing image:', error);
          reject(null);
        });
      };
  
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(null);
      };
    });
  }

  uploadFile(
    file: File, 
    merchantId: number, 
    uid: string, 
    documentName: string, 
    status: string,
    extras?: any
): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('merchant_id', merchantId.toString());
    formData.append('uid', uid);
    formData.append('document_name', documentName);
    formData.append('status', status);
    if (extras) {
        formData.append('extras', extras)
    }

    const headers = new HttpHeaders({});

    return this.http.post<any>(this.uploadUrl, formData, { headers });
  }
}
