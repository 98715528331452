<div class="content">
  <app-reactive-text-field2
    label="Store Name"
    [control]="storeNameControl"
    [required]="true"
  ></app-reactive-text-field2>

  <div class="store-type-section">
    <h3 class="section-title">Store Type*</h3>

    <div class="options">
      <div class="option">
        <label for="food">
          <div class="img-option-container">
            <img src="../../../assets/images/Food Icon.svg" alt="Food" />
          </div>
        </label>
        <div class="option-label">
          <input
            [formControl]="storeTypeControl"
            type="radio"
            name="store-type"
            id="food"
            value="food"
          />
          <label for="food">Food</label>
        </div>
      </div>

      <div class="option">
        <label for="retail">
          <div class="img-option-container">
            <img src="../../../assets/images/Retail Icon.svg" alt="" />
          </div>
        </label>
        <div class="option-label">
          <input
            [formControl]="storeTypeControl"
            type="radio"
            name="store-type"
            id="retail"
            value="retail"
          />
          <label for="retail">Retail</label>
        </div>
      </div>

      <div class="option">
        <label for="services">
          <div class="img-option-container">
            <img
              src="../../../assets/images/Services Icon.svg"
              alt="Services"
            />
          </div>
        </label>
        <div class="option-label">
          <input
            [formControl]="storeTypeControl"
            type="radio"
            name="store-type"
            id="services"
            value="services"
          />
          <label for="services">Services</label>
        </div>
      </div>
    </div>
  </div>

  <div class="store-category section">
    <!-- <h3 class="section-title">
      Store Category <span class="required">*</span>
    </h3> -->
    <!-- <app-reactive-text-input placeholder="Store category" [required]="true" /> -->

    <app-reactive-dropdown-input
      label=""
      id="storeCategoriesDropdown"
      [options]="storeCategories"
      [bindLabel]="'value'"
      errorMessage="Please select a category"
      [required]="true"
      placeholder="Store category*"
      [control]="storeCategoryControl"
      name="category"
    ></app-reactive-dropdown-input>
  </div>
</div>

<app-cta-buttons
  currentPage="email-verification"
  [pageNames]="starterPages"
  tier="starter"
  [disabled]="!allFilled"
  [data]="formData"
  (nextEvent)="handleNextClick()"
  [isLoading]="loading"
  [nextCallback]="handleNextClick"
/>
