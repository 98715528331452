<div class="content">
  <h3 class="section-title">Business Hours*</h3>
  <app-toggle-item
    itemName="Always Open"
    specialContent="
    <p class='tw-font-inter tw-text-[10px] tw-text-enstack-onyx-80'>
      <span>Switch toggle to </span>
      <span class='tw-text-[#0099E0]'>ON to accept orders at any time, 24/7</span><span>, or OFF to decline orders when closed.</span>
    </p>"
    (toggleChanged)="toggleAlwaysOpen($event)"
    [isChecked]="isAlwaysOpen"
  />

  <!-- Old business hours picker -->
  <!-- <div *ngFor="let businessHours of businessHoursArrayOld; let i = index">
    <app-toggle-item
      [itemName]="businessHours.day"
      (toggleChanged)="setAvailability(i, businessHours.key, $event)"
      [isChecked]="businessHours.isAvailable"
    />
    <div class="divider"></div>

    <app-time-picker
      [day]="businessHours.day"
      [startTime]="businessHours.startTime"
      [endTime]="businessHours.endTime"
      (timeChanged)="handleChangeTime(i, businessHours.key, $event)"
    />
  </div> -->

  <!-- New business hours picker -->
  <div *ngFor="let businessHours of businessHoursArray; let i = index">
    <div class="weekday-head">
      <div class="weekday-title">{{ businessHours.key }}</div>
      <button
        *ngIf="!isEditing[i]"
        (click)="toggleEditMode(i)"
        class="edit-btn"
      >
        <span>Edit</span>
      </button>

      <button
        *ngIf="isEditing[i]"
        (click)="saveChanges(i)"
        class="save-btn"
        [disabled]="hasErrors(i)"
        [ngClass]="{ 'save-btn-disabled': hasErrors(i) }"
      >
        <span>Save</span>
      </button>
    </div>

    <div class="divider"></div>

    <div *ngIf="isBusinessHoursVisible[i] && !isEditing[i]">
      <p class="business-hours-display">{{ getBusinessHoursDisplay(i) }}</p>
    </div>

    <!-- 24hrs and Closed Checkboxes -->
    <ng-container *ngIf="isEditing[i]">
      <div class="quick-btns">
        <label class="radio-option">
          <input
            type="radio"
            name="hoursOption{{ i }}"
            value="24hrs"
            [checked]="has24HourRange(businessHours.openHrs)"
            (change)="setBusinessHoursOption(i, '24hrs')"
          />
          <span>Open 24 Hours</span>
        </label>

        <label class="radio-option">
          <input
            type="radio"
            name="hoursOption{{ i }}"
            value="closed"
            [checked]="businessHours.isAvailable"
            (change)="setBusinessHoursOption(i, 'closed')"
          />
          <span>Closed</span>
        </label>

        <label class="radio-option">
          <input
            type="radio"
            name="hoursOption{{ i }}"
            value="custom"
            [checked]="
              !has24HourRange(businessHours.openHrs) &&
              !businessHours.isAvailable
            "
            (change)="setBusinessHoursOption(i, 'custom')"
          />
          <span>Custom</span>
        </label>
      </div>

      <!-- Conditionally display open hours based on 24hrs and Closed checkboxes -->
      <div class="open-hrs" *ngIf="!businessHours.isAvailable">
        <ng-container
          *ngFor="let hrs of businessHours.openHrs; let timeRangeIndex = index"
        >
          <div class="time-picker">
            <app-time-picker
              [day]="businessHours.key"
              [startTime]="hrs.startTime"
              [endTime]="hrs.endTime"
              (timeChanged)="checkForRemainingErrors(i)"
            ></app-time-picker>

            <button (click)="removeTimeRange(i, timeRangeIndex)">
              <img
                src="../../../../assets/icons/close-icon-02.svg"
                alt="Close"
              />
            </button>
          </div>
        </ng-container>

        <div *ngIf="errorMessages[i]" class="error-message">
          {{ errorMessages[i] }}
        </div>
      </div>

      <button
        (click)="addTimeRange(i)"
        class="add-hrs-btn"
        *ngIf="
          !has24HourRange(businessHours.openHrs) && !businessHours.isAvailable
        "
      >
        <img src="../../../../assets/icons/add-btn.svg" alt="Add Hours" />
        <span>Add hours</span>
      </button>

      <div
        style="margin-bottom: -20px"
        *ngIf="
          businessHours.isAvailable || has24HourRange(businessHours.openHrs)
        "
      ></div>
    </ng-container>
  </div>

  <app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="false"
    [data]="formData"
    (nextEvent)="handleNextClick()"
    [nextCallback]="handleNextClick"
    [isLoading]="loading"
  ></app-cta-buttons>
</div>
