import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-reactive-text-input',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './reactive-text-input.component.html',
  styleUrls: ['./reactive-text-input.component.scss'],
})
export class ReactiveTextInputComponent implements OnChanges {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() id: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() errorMessage: string = 'Something is wrong!';
  @Input() reference: string = '';
  @Input() minNum: number = 10;
  @Input() maxNum: number = 12;

  ngOnChanges(): void {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.reference === 'bank-number') {
      validators.push(this.bankNumberValidator.bind(this));
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity(); // Trigger validation
  }

  bankNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const regex = new RegExp(`^\\d{${this.minNum},${this.maxNum}}$`);
    const valid = regex.test(value);
    return valid ? null : { bankNumberInvalid: true };
  }
}
