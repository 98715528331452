<div class="container">
    <div class="section">
        <h3 class="title">More Address Information*</h3>
        <p class="note">
            Provide the address where your orders will be picked up for delivery
        </p>
    </div>

    <div class="section">
        <!-- <div class="title">Province <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="Province*"
            bindLabel="name"
            id="province"
            appearance="outline"
            [options]="provinceQuery.data() ?? []"
            [loading]="provinceQuery.isFetching()"
            [formControl]="getFormControl('selectedProvince')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedProvince')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">City <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="City*"
            bindLabel="name"
            id="city"
            appearance="outline"
            [options]="cityQuery.data() ?? []"
            [loading]="cityQuery.isFetching()"
            [formControl]="getFormControl('selectedCity')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedCity')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">Barangay <span class="required">*</span></div> -->
        <app-reactive-ng-select-field
            placeholder="Barangay*"
            bindLabel="name"
            id="barangay"
            appearance="outline"
            [options]="barangayQuery.data() ?? []"
            [loading]="barangayQuery.isFetching()"
            [formControl]="getFormControl('selectedBarangay')"
            [validationMessage]="validationMessages"
            [errors]="getFieldError('selectedBarangay')"
        ></app-reactive-ng-select-field>
    </div>

    <div class="section">
        <!-- <div class="title">Zip Code <span class="required">*</span></div>
    <app-reactive-text-input
      type="number"
      placeholder="Enter zip code"
      [required]="true"
      [control]="zipCode"
    ></app-reactive-text-input> -->

        <app-reactive-text-field2
            type="number"
            label="Zip Code"
            [required]="true"
            [control]="zipCode"
            inputType="number"
        >
        </app-reactive-text-field2>
    </div>

    <div class="section">
        <!-- <div class="title">
      House/Unit Number, Street name, Subdivision
      <span class="required">*</span>
    </div>
    <app-reactive-text-input
      placeholder="Enter address"
      [required]="true"
      [control]="addressDetails"
    ></app-reactive-text-input> -->

        <app-reactive-text-field2
            label="House/Unit #, St. Name, Subd."
            [required]="true"
            [control]="addressDetails"
        >
        </app-reactive-text-field2>
    </div>
</div>
<app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="!allFilled"
    [data]="formData"
    (nextEvent)="handleNextClick()"
    [nextCallback]="handleNextClick"
    [isLoading] = "loading"
></app-cta-buttons>
