import { AfterViewInit, Component, Input, OnInit, inject, signal } from '@angular/core';
import {
  ImageCroppedEvent,
  LoadedImage,
  ImageTransform,
} from 'ngx-image-cropper';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type CropperDialogData = {
  image: File;
  width: number;
  height: number;
};

export type CropperDialogResult = {
  blob: Blob;
  imageUrl: string;
};

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss'],
})
export class CropperDialogComponent implements AfterViewInit {
  constructor() {}
  ngAfterViewInit(): void {
    console.log('image cropper data', this.data);
  }

  @Input() data: CropperDialogData = inject(MAT_DIALOG_DATA);
  result = signal<CropperDialogResult | undefined>(undefined);

  imageCropped(event: any) {
    console.log('image cropper data imageCropped', this.data);
    const { blob, objectUrl } = event;
    if (blob && objectUrl) {
      this.result.set({ blob, imageUrl: objectUrl });
    }
  }
}
