import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveSlideToggleComponent } from '../reactive-slide-toggle/reactive-slide-toggle.component';

type badge = 'warning' | 'info';

@Component({
  selector: 'app-toggle-item',
  templateUrl: './toggle-item.component.html',
  // styleUrls: ['./settings-payments-header.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveSlideToggleComponent,
  ],
  providers: [],
  standalone: true,
})
export class ToggleItemComponent {
  @Input({ required: true }) itemName: string = '';
  @Input() itemDescription: string = '';
  @Input() itemHelper: string = '';
  @Input() hasBadge: boolean = false;
  @Input() badgeType: badge = 'info';
  @Input() badgeContent: string = '';
  @Input() hasTooltip: boolean = false;
  @Input() tooltipContent: string = '';
  @Input() isChecked: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() toggleOffDisabled: boolean = false; // There are conditions where you only want to disable toggle off
  @Input() withToggle: boolean = true;
  @Input() isShowBeta: boolean = false;
  @Input() specialContent: string = '';
  @Input() isWithDialogToggle: boolean = false;
  @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleToggleChange(event: boolean) {
    this.toggleChanged.emit(event);
  }
}
