<!-- reactive-dropdown-input.component.html -->
<div class="input-container">
  <select
    [id]="id"
    [formControl]="control"
    class="tw-font-inter tw-text-[16px]"
  >
    <option value="" disabled selected>{{ placeholder }}</option>
    <ng-container *ngIf="!optionsSlot">
      <option *ngFor="let option of options" [value]="getOptionValue(option)">
        {{ getOptionLabel(option) }}
      </option>
    </ng-container>
    <ng-container *ngIf="optionsSlot">
      <ng-container
        style="padding-right: 50px"
        *ngTemplateOutlet="optionsSlot; context: { $implicit: options }"
      ></ng-container>
    </ng-container>
  </select>
</div>

<div *ngIf="control.invalid && control.touched" class="error-message">
  {{ errorMessage }}
</div>
