import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AppService } from './app.service';
import { StarterFormDataService } from './pages/starter/starter-form-data.service';
import { UserService } from './services/user/user.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private appService: AppService,
    private activatedroute: ActivatedRoute,
    private startservice: StarterFormDataService,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.activatedroute.queryParams.subscribe(async (data: any) => {
        console.log('Data: ', data);
        console.log('get token params', data);
        const checkPointIndex = localStorage.getItem('checkPointIndex');
        if (checkPointIndex) {
          const savedUserId = checkPointIndex.split('_')[1];
          if (data.user) {
            const userId = data.user;
            console.log(savedUserId);
            console.log(userId);
            if (savedUserId === userId) {
              console.log('Same user, do not delete checkpoint');
            } else {
              console.log('Different user, deleting checkpoint');
              localStorage.removeItem('checkPointIndex');
            }
          }
        }

        if ('isMobile' in data) {
          localStorage.setItem('isMobile', data.isMobile);
        }

        if ('uid' in data) {
          await lastValueFrom(this.appService.getToken(data.uid)).then(
            (results) => {
              console.log('get token', results.secretToken);
              localStorage.setItem('token', results['secretToken']);
              this.startservice
                .getMerchantDetailsV2()
                .subscribe((data: any) => {
                  this.startservice.merchantData = data;
                });
              this.startservice
                .getShopBusinessHoursV2()
                .subscribe((data: any) => {
                  this.startservice.shopBusinessHours = data;
                });
              this.startservice
                .getShopDescriptionV2()
                .subscribe((data: any) => {
                  this.startservice.shopDescription = data.shopdescription;
                });
              this.startservice
                .getMerchantDetailsV2()
                .subscribe((data: any) => {
                  console.log('VD merchant details', data);
                  this.startservice.updateStoreLogoURL(data.shop_image);
                });
            }
          );
        }
        if ('user' in data) {
          this.userService.saveUserId(data.user);
        }
      });
      return true;
    }
  }

  
  public getUserIdFromQueryParams(): Promise<string | null> {
    return new Promise((resolve) => {
      this.activatedroute.queryParams.subscribe((data: any) => {
        if ('user' in data) {
          resolve(data.user);
        } else {
          resolve(null);
        }
      });
    });
  }
}
