<div class="tw-flex tw-flex-col" (click)="toggleDatepicker()">
  <mat-form-field>
    <input
      (dateChange)="onDateChange($event)"
      matInput
      [value]="value"
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [required]="true"
      (click)="picker.open()"
      [disabled]="false"
      readonly
    />
    <mat-datepicker-toggle matIconPrefix [for]="picker">
      <img
        matDatepickerToggleIcon
        src="../../../../assets/icons/calendar-icon.svg"
        alt="calendar-icon"
        width="20px"
        height="20px"
      />
    
    <mat-datepicker #picker></mat-datepicker>
  </mat-datepicker-toggle>
    <mat-error>
      {{ errorMessage }}
    </mat-error>
  </mat-form-field>
  <div *ngIf="hasErrors && isTouched" class="error-message">
    {{ errorMessage }}
  </div>
</div>