<div class="custom-tooltip-content">
    <div class="tooltip-header">
      <div class="tw-text-[16px]">
        ⓘ
      </div>
      <div (click)="closeToolTip()">
        <img src="../../../../assets/icons/close-icon.svg">
      </div>
    </div>
    <div class="tw-pt-2 tw-font-thin">
      {{ tooltipText }}
    </div>
  </div>
  