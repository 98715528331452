<h1
  class="tw-font-inter tw-text-[23px] tw-font-bold tw-text-enstack-royalBlue-main tw-p-6 tw-pb-0"
>
  Crop Image
</h1>

<div mat-dialog-content>
  <image-cropper
    [imageFile]="data.image"
    [maintainAspectRatio]="true"
    [aspectRatio]="data.width / data.height"
    [resizeToHeight]="data.height"
    [resizeToWidth]="data.width"
    (imageCropped)="imageCropped($event)"
    [resizeToHeight]="data.height"
    [resizeToWidth]="data.width"
    [imageQuality]="100"
    [onlyScaleDown]="true"
  >
  </image-cropper>
</div>

<div mat-dialog-actions class="tw-w-full tw-flex tw-gap-2">
  <button [mat-dialog-close]="false" class="btn-secondary tw-w-1/2 tw-flex-1">
    Back
  </button>
  <button class="btn-primary tw-w-1/2 tw-flex-1" [mat-dialog-close]="result()">
    Upload
  </button>
</div>
