import { Component, OnInit } from "@angular/core";
import { CtaButtonsComponent } from "src/app/components/cta-buttons/cta-buttons.component";
import { ReactiveTextInputComponent } from "src/app/components/inputs/reactive-text-input/reactive-text-input.component";
import { starterPages } from "../starter-data";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StarterFormDataService } from "../starter-form-data.service";
import { ReactiveNgSelectFieldComponent } from "src/app/components/inputs/reactive-ng-select-field/reactive-ng-select-field.component";
import { bankOptions } from "../starter-data";
import { BankOption } from "../starter-data";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-account-details",
  standalone: true,
  templateUrl: "./account-details.component.html",
  styleUrls: ["./account-details.component.scss"],
  imports: [
    ReactiveTextInputComponent,
    CtaButtonsComponent,
    ReactiveNgSelectFieldComponent,
  ],
})
export class AccountDetailsComponent implements OnInit {
  starterPages: string[] = starterPages;
  bankOptions: BankOption[] = bankOptions;
  bankNames: string[] = [];
  bankName: FormControl = new FormControl();
  accountName: FormControl = new FormControl();
  accountNumber: FormControl = new FormControl();
  minNum: number = 10; // Default minNum
  maxNum: number = 12; // Default maxNum
  isAllFilled = false;

  formData: any = {
    bankName: null,
    accountName: null,
    accountNumber: null,
  };
  formGroup: FormGroup;

  constructor(private starterService: StarterFormDataService) {
    this.formGroup = new FormGroup({
      bankName: this.bankName,
      accountName: this.accountName,
      accountNumber: this.accountNumber,
    });

    this.bankName.valueChanges.subscribe((value) => {
      this.formData.bankName = value;
      this.updateBankDetails(value);
      this.checkRequiredFields();
    });

    this.accountName.valueChanges.subscribe(() => {
      this.formData.accountName = this.accountName.value;
      this.checkRequiredFields();
    });

    this.accountNumber.valueChanges.subscribe(() => {
      this.formData.accountNumber = this.accountNumber.value;
      this.checkRequiredFields();
    });
  }

  checkRequiredFields() {
    this.isAllFilled =
      this.bankName.valid && this.accountName.valid && this.accountNumber.valid;
  }

  ngOnInit() {
    this.starterService.setCheckPointIndex(7);
    this.bankNames = this.bankOptions.map((bank) => bank.name);
    if (typeof this.starterService.merchantData === "undefined") {
      this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
        this.starterService.merchantData = data;
        this.bankName.setValue(
          this.starterService.merchantData.bank_details.bank_name,
        );
        this.accountName.setValue(
          this.starterService.merchantData.bank_details.account_name,
        );
        this.accountNumber.setValue(
          this.starterService.merchantData.bank_details.account_number,
        );
      });
    } else {
      this.bankName.setValue(
        this.starterService.merchantData.bank_details.bank_name,
      );
      this.accountName.setValue(
        this.starterService.merchantData.bank_details.account_name,
      );
      this.accountNumber.setValue(
        this.starterService.merchantData.bank_details.account_number,
      );
    }

    this.bankName.setValue(
      this.starterService.merchantData.bank_details.bank_name,
    );
    this.accountName.setValue(
      this.starterService.merchantData.bank_details.account_name,
    );
    this.accountNumber.setValue(
      this.starterService.merchantData.bank_details.account_number,
    );
  }

  // check if form is valid
  checkIfFormIsValid() {
    this.isAllFilled = this.formGroup.valid;
  }

  updateBankDetails(bankName: string) {
    const selectedBank = this.bankOptions.find(
      (bank) => bank.name === bankName,
    );
    if (selectedBank) {
      this.minNum = selectedBank.minNum;
      this.maxNum = selectedBank.maxNum;
    } else {
      this.minNum = 10; // Default minNum
      this.maxNum = 12; // Default maxNum
    }
  }

  handleNext = async () => {
    var merchantData = this.starterService.merchantData;
    merchantData.bank_details.bank_name =
      this.bankName.value == "Maya" ? "PayMaya" : this.bankName.value;
    merchantData.bank_details.account_number = this.accountNumber.value;
    merchantData.bank_details.account_name = this.accountName.value;
    merchantData.account_number = this.accountNumber.value;
    merchantData.bank_name =
      this.bankName.value == "Maya" ? "PayMaya" : this.bankName.value;
    merchantData.account_name = this.accountName.value;

    return await lastValueFrom(this.starterService.updateBankAccountDetails(merchantData)).then(
      async (response) => {
        console.log("update bank account", response);
        return await lastValueFrom(this.starterService
          .activateStandardShipping())
          .then(async (response) => {
            console.log("activate standard shipping", response);
            return await lastValueFrom(this.starterService
              .activateOndemandShipping())
              .then((response) => {
                console.log("activate on demand shipping", response);
                parent.postMessage(
                  JSON.stringify({
                    target: "/starter-success",
                    store_name: localStorage.getItem("store_name"),
                  }),
                  "*",
                );
                console.log("starter success");
                return true
              });
          });
        // console.log('update bank account', response);
        // parent.postMessage(
        //   JSON.stringify({
        //     "target": "/starter-success"
        //   }), "*"
        // )
      },

    );

    // process.env["START_SUCCESS_URL"]
  }
}
