import { Component } from '@angular/core';
import { ReactiveFileInputComponent } from '../../components/inputs/reactive-file-input/reactive-file-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { FileSizeValidator } from 'src/app/components/inputs/reactive-file-input/validators/file-size.validator';
import { ValidationMessageFn } from 'src/app/components/inputs/types/validation-message-fn';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { Router, RouterModule } from '@angular/router';
import { advancedPages } from './advanced-data';
import { ReactiveNgSelectFieldComponent } from 'src/app/components/inputs/reactive-ng-select-field/reactive-ng-select-field.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advanced',
  standalone: true,
  templateUrl: './advanced.component.html',
  styleUrl: './advanced.component.scss',
  imports: [
    ReactiveFileInputComponent,
    ReactiveFormsModule,
    ProgressBarComponent,
    CtaButtonsComponent,
    ReactiveNgSelectFieldComponent,
    RouterModule,
  ],
})
export class AdvancedComponent {
  count: number = 0;
  // pages: string[] = ['poi', 'upload-id', 'upload-selfie'];
  advancedPages = advancedPages;
  formData: FormControl = new FormControl();

  ngOnInit(): void {
    const pathSegments = this.router.url.split('/');
    const endPath = pathSegments[pathSegments.length - 1];
    this.count = this.advancedPages.indexOf(endPath);
  }

  constructor(private router: Router) {
    console.log(environment);
  }

  receiveCount($event: number) {
    this.count = $event;

    this.router.navigateByUrl(`/advanced/${this.advancedPages[this.count]}`);

    console.log(
      'Passed through here!',
      this.advancedPages[this.count],
      this.count
    );
  }

  public form: FormGroup = new FormGroup({
    file: new FormControl<File | null>(null, {
      validators: [FileSizeValidator.validate(100)],
    }),
    file2: new FormControl<File | null>(null, {
      validators: [FileSizeValidator.validate(1000)],
    }),
  });

  public fileValidationMessage: Record<string, ValidationMessageFn> = {
    maxSize: (errors) =>
      'File size is too large. Max size is 100 bytes Woah invalid.',
  };
  public file2ValidationMessage: Record<string, ValidationMessageFn> = {
    maxSize: (errors) => 'File(2) size is too large. Max size is 100 bytessss.',
  };
}
