import { Component } from '@angular/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import {
  DocumentChangeEvent,
  DocumentUploadComponent,
} from 'src/app/components/document-upload/document-upload.component';
import { advancedPages } from '../advanced-data';
import {
  DocumentUploadForm,
  DocumentUploadFormModel,
} from 'src/app/components/document-upload/models/document-upload-form.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from 'src/app/services/upload.service';
import { lastValueFrom } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'app-upload-selfie',
  standalone: true,
  imports: [DocumentUploadComponent, CtaButtonsComponent, SharedModule],
  templateUrl: './upload-selfie.component.html',
  styleUrls: ['./upload-selfie.component.scss'],
})
export class UploadSelfieComponent {
  advancedPages: string[] = advancedPages;
  fileSizeLimit = 2 * 1024 * 1024
  loading: boolean = false;
  uploadError: string = "";
  form: DocumentUploadForm = DocumentUploadFormModel.build({
    expiration: {
      validators: [],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [],
    },
  });
  formData: { [key: string]: DocumentChangeEvent | undefined } = {};

  constructor(private uploadService: UploadService, private imageCompress: NgxImageCompressService) { }

  handleUploadError(error: Error, file: File | null | undefined): boolean {
    if (file instanceof File) {
      this.uploadError = "The file was too large to upload, please upload a smaller file."
      console.error('Error uploading primary ID: ', error, file.size);
      this.loading = false
      return false
    } else {
      console.error('File is not valid:', file);
      this.loading = false;
      return false;
    }
  }

  async validateAndHandleLargeFile(
    file: File, 
    idType: string, 
  ): Promise<File | null> {
    if (file.size > this.fileSizeLimit) {
      console.log("IC00 Trigger for image compression", file.size, idType);
      const compressedFile = await this.uploadService.compressImage(file);
      if (!compressedFile) {
        this.loading = false
        return null
      }
      return compressedFile
    }
    return file
  }

  onDocumentChange(
    type: 'primaryId' | 'secondaryId1' | 'secondaryId2' | 'selfie',
    event: DocumentChangeEvent | undefined
  ) {
    console.log('Selfie document change event', event);
    // this.form.updateValueAndValidity()
  }

  onNextPressed = async () => {
    try {
      const merchantId = parseInt(localStorage.getItem('userId')!);
      console.log("Selfie with ID submit clicked", merchantId);
      this.loading = true;
      this.form.markAllAsTouched();

      // Checks if form is valid
      if (!this.form.valid) {
        this.loading = false;
        console.log("Form is not valid");
        return false;
      }

      // Checks if there is a merchant ID
      if (!merchantId) {
        this.uploadError = "Something went wrong with the upload, please try again"
        console.log("No merchant ID on selfie upload");
        this.loading = false
        return false
      }

      // Checks if there is a file
      const file = this.form.value.file
      if (!file) {
        this.uploadError = "A file is required before proceeding"
        this.form.controls.file.setErrors({ noFile: true })
        console.log("No selfie file uploaded");
        this.loading = false
        return false
      }

      const validatedSelfieFile = await this.validateAndHandleLargeFile(
        file,
        "Selfie",
      )

      // Uploads selfie
      const payload = {
        file: validatedSelfieFile,
        merchant_id: merchantId,
        status: 'active',
        uid: uuidv4(),
        document_name: 'selfie_with_id',
        extras: JSON.stringify({
          id_subtype: 'selfie',
          expiration_date: null,
        }),
      };
      
      console.log('Selfie Payload', payload, merchantId);
      const selfieUploadResult = await this.uploadFile(payload).catch((error) => {
        return this.handleUploadError(error, this.form.value.file)
      });

      if (!selfieUploadResult) {
        this.uploadError = "Your selfie failed to upload, please try again"
        console.log("Selfie upload failed");
        this.loading = false
        return false
      }

      console.log("Selfie uploaded successfully", payload);
      this.loading = false
      this.uploadError = ""
      return true;
    } catch (error) {
      this.uploadError = "There was an error with the upload, please try again"
      this.loading = false;
      console.log("Error during selfie upload: ", error);
      return false;
    }
  };

  async uploadFile(payload: any): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.uploadService.uploadFile(
          payload.file,
          payload.merchant_id,
          payload.uid,
          payload.document_name,
          payload.status,
          payload.extras
        )
      );

      console.log('Uploaded successfully: ', response);
      this.loading = false;
      return true
    } catch (error) {
      console.error('Error uploading: ', error);
      this.loading = false;
      return false
    }
  }
}
