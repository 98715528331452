import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AddressOption } from './models/address-option.model';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const PROVINCE_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/country`;
const CITY_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/province`;
const BARANGAY_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/city`;

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private http = inject(HttpClient);

  async getProvinces(country: number = 1) {
    return lastValueFrom(
      this.http.get<AddressOption[]>(`${PROVINCE_API_URL}/${country}/province/`)
    );
  }

  async getCities(province: number) {
    return lastValueFrom(
      this.http.get<AddressOption[]>(`${CITY_API_URL}/${province}/city/`)
    );
  }

  async getBarangays(city: number) {
    return lastValueFrom(
      this.http.get<AddressOption[]>(`${BARANGAY_API_URL}/${city}/barangay/`)
    );
  }

  updateAddress(payload: {
    address: {
      line_1: string;
      barangay: string;
      city: string;
      province: string;
      postal_code: string;
      latitude: number;
      longitude: number;
    };
    from_onboarding: boolean;
  }): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/shipping-address/`, // url
      payload, // body
      {
        // options
        headers: headers,
      }
    );
    console.log(response);
    return response;
  }
}
