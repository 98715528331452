import { AbstractControl, ValidatorFn } from '@angular/forms';

type FileAbstractControl = AbstractControl<File | null>;

export class FileSizeValidator {
  /**
   *
   * @param maxSize The maximum size of the file in bytes
   * @returns A validator function that returns an error if the file size is greater than the maxSize
   */
  public static validate(maxSize: number): ValidatorFn {
    return (control) => {
      const typedControl = control as FileAbstractControl;
      const file = typedControl.value;
      if (file) {
        return file.size > maxSize ? { maxSize: true } : null;
      }
      return null;
    };
  }
}
