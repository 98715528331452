import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { ReactiveFileInputComponent } from 'src/app/components/inputs/reactive-file-input/reactive-file-input.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddressInfoComponent } from './address-info/address-info.component';
import { PickupAddressComponent } from './pickup-address/pickup-address.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { starterPages } from './starter-data';
import { StarterFormDataService } from './starter-form-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-starter',
  standalone: true,
  imports: [
    ReactiveTextInputComponent,
    ReactiveFormsModule,
    ProgressBarComponent,
    ReactiveFileInputComponent,
    CtaButtonsComponent,
    EmailVerificationComponent,
    StoreDetailsComponent,
    CommonModule,
    AccountDetailsComponent,
    AddressInfoComponent,
    PickupAddressComponent,
    RouterModule,
  ],
  templateUrl: './starter.component.html',
  styleUrl: './starter.component.scss',
})
export class StarterComponent implements OnInit {
  count: number = 0;
  starterPages: string[] = starterPages;
  currentPath: string = this.router.url;

  constructor(
    private router: Router,
    private starterService: StarterFormDataService,
    private activatedroute: ActivatedRoute
  ) {}

  ngOnInit() {
    let checkPointIndex = this.starterService.getCheckPointIndex();
    console.log('checkPointIndex', checkPointIndex);
    if (checkPointIndex) {
      checkPointIndex -= 1;
      checkPointIndex = parseInt(checkPointIndex.toString());
      console.log('checkPointIndex found', checkPointIndex);
    }

    // let emailVerified = this.activatedroute.snapshot.queryParamMap.get('verified');

    // console.log('Email Verified Taninga mo:', emailVerified);

    // // Retrieve the updated checkPointIndex from localStorage
    // if (emailVerified && emailVerified === 'true' && checkPointIndex == 0) {
    //   checkPointIndex = 1;
    // }
    else {
      checkPointIndex = 0;
      console.log('no current checkpoint');
    }

    console.log('checkPointIndex', checkPointIndex);

    const queryParams = {
      pages: 4,
      count: checkPointIndex,
    };

    this.router.navigate(
      [`/starter/${[starterPages[Number(checkPointIndex)]]}`],
      {
        queryParams,
      }
    );
  }
}
