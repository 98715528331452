import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StarterComponent } from './pages/starter/starter.component';
import { AdvancedComponent } from './pages/advanced/advanced.component';
import { VerifiedComponent } from './pages/verified/verified.component';
import { EmailVerificationComponent } from './pages/starter/email-verification/email-verification.component';
import { StoreDetailsComponent } from './pages/starter/store-details/store-details.component';
import { PickupAddressComponent } from './pages/starter/pickup-address/pickup-address.component';
import { AddressInfoComponent } from './pages/starter/address-info/address-info.component';
import { AccountDetailsComponent } from './pages/starter/account-details/account-details.component';
import { PoiComponent } from './pages/advanced/poi/poi.component';
import { UploadIdComponent } from './pages/advanced/upload-id/upload-id.component';
import { UploadSelfieComponent } from './pages/advanced/upload-selfie/upload-selfie.component';
import { StoreOwnerComponent } from './pages/starter/store-owner/store-owner.component';
import { StoreTypeComponent } from './pages/starter/store-type/store-type.component';
import { BusinessHoursComponent } from './pages/starter/business-hours/business-hours.component';
import { BusinessInfoComponent } from './pages/advanced/business-info/business-info.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'starter',
    component: StarterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
      },
      {
        path: 'store-owner',
        component: StoreOwnerComponent,
      },
      {
        path: 'store-type',
        component: StoreTypeComponent,
      },
      {
        path: 'business-hours',
        component: BusinessHoursComponent,
      },
      {
        path: 'store-details',
        component: StoreDetailsComponent,
      },
      {
        path: 'pickup-address',
        component: PickupAddressComponent,
      },
      {
        path: 'address-info',
        component: AddressInfoComponent,
      },
      {
        path: 'account-details',
        component: AccountDetailsComponent,
      },
      {
        path: '',
        redirectTo: 'email-verification',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'advanced',
    component: AdvancedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'poi',
        component: PoiComponent,
      },
      {
        path: 'upload-id',
        component: UploadIdComponent,
      },
      {
        path: 'upload-selfie',
        component: UploadSelfieComponent,
      },
      {
        path: 'business-info',
        component: BusinessInfoComponent,
      },
      { path: '', redirectTo: 'poi', pathMatch: 'full' },
    ],
  },
  {
    path: 'verified',
    component: VerifiedComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'card-activation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/card-payments-activation/card-payments-activation.module'
      ).then((m) => m.CardPaymentsActivationPageModule),
  },
  {
    path: 'iframe-content',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/iframe-content/iframe-content.module').then(
        (m) => m.IframeContentPageModule
      ),
  },
  {
    path: 'iframe/:intent/:token',
    component: IframeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/starter',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
