import { Component, OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  @Input() tooltipText: string = '';
  @Output() close = new EventEmitter<void>();

  closeToolTip(): void {
    this.close.emit();
  }
}
