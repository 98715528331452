import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export enum DocumentUploadFormKeys {
  FILE = 'file',
  ID_TYPE = 'idType',
  EXPIRATION = 'expiration',
}

export type DocumentUploadForm = ReturnType<
  typeof DocumentUploadFormModel.build
>;

export type DocumentUploadFormModelBuildPayload = {
  [DocumentUploadFormKeys.FILE]: {
    validators: ValidatorFn[];
  };
  [DocumentUploadFormKeys.ID_TYPE]: {
    validators: ValidatorFn[];
  };
  [DocumentUploadFormKeys.EXPIRATION]: {
    validators: ValidatorFn[];
  };
};

export class DocumentUploadFormModel {
  public static build(payload?: DocumentUploadFormModelBuildPayload) {
    return new FormGroup({
      [DocumentUploadFormKeys.FILE]: new FormControl<File | null>(null, {
        validators: payload?.[DocumentUploadFormKeys.FILE].validators ?? [],
      }),
      [DocumentUploadFormKeys.ID_TYPE]: new FormControl<string>('', {
        validators: payload?.[DocumentUploadFormKeys.ID_TYPE].validators ?? [],
      }),
      [DocumentUploadFormKeys.EXPIRATION]: new FormControl<Date | null>(null, {
        validators:
          payload?.[DocumentUploadFormKeys.EXPIRATION].validators ?? [],
      }),
    });
  }
}
