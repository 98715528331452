<div class="main-container">
  <div class="page-info">
    <h3 class="title">
      Business Name and Address<span class="required-mark">*</span>
    </h3>
    <p class="note tw-pt-4">
      Provide your registered business name and address as shown in business
      documents, bills, etc.
    </p>
  </div>

  <app-reactive-text-field2
    label="Registered Business Name"
    [required]="true"
    [control]="businessInfoForm.controls['businessName']"
  ></app-reactive-text-field2>

  <div class="checkbox-container">
    <input
      type="checkbox"
      id="same-as-pickup"
      [formControl]="businessInfoForm.controls['sameAsPickup']"
    />
    <label for="same-as-pickup">Same as pickup address</label>
  </div>

  <app-reactive-ng-select-field
    placeholder="Province*"
    bindLabel="name"
    id="province"
    appearance="outline"
    dropdownPosition="bottom"
    appendTo="app-root"
    [options]="provinceQuery.data() ?? []"
    [loading]="provinceQuery.isFetching()"
    [formControl]="businessInfoForm.controls['province']"
    [validationMessage]="validationMessages"
    [errors]="getFieldError(businessInfoFormKeys.province)"
  />
  <app-reactive-ng-select-field
    placeholder="City*"
    bindLabel="name"
    id="city"
    appearance="outline"
    dropdownPosition="bottom"
    appendTo="app-root"
    [options]="cityQuery.data() ?? []"
    [loading]="cityQuery.isFetching()"
    [formControl]="businessInfoForm.controls['city']"
    [validationMessage]="validationMessages"
    [errors]="getFieldError(businessInfoFormKeys.city)"
  />
  <app-reactive-ng-select-field
    placeholder="Barangay*"
    bindLabel="name"
    id="barangay"
    appearance="outline"
    dropdownPosition="bottom"
    appendTo="app-root"
    [options]="barangayQuery.data() ?? []"
    [loading]="barangayQuery.isFetching()"
    [formControl]="businessInfoForm.controls['barangay']"
    [validationMessage]="validationMessages"
    [errors]="getFieldError(businessInfoFormKeys.barangay)"
  />

  <app-reactive-text-field2
    label="Zip code"
    [required]="true"
    inputType="number"
    [control]="businessInfoForm.controls['zipCode']"
  ></app-reactive-text-field2>

  <app-reactive-text-field2
    label="House/Unit #, St. Name, Subd."
    [required]="true"
    [control]="businessInfoForm.controls['line1']"
  />

  <app-cta-buttons
    currentPage="business-info"
    [pageNames]="advancedPages"
    tier="advanced"
    [disabled]="businessInfoForm.invalid"
    (nextEvent)="handleNextClick()"
  ></app-cta-buttons>
</div>
