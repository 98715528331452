import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule, Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  standalone: true,
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  imports: [CommonModule], // Import CommonModule here
})
export class BackButtonComponent {
  public location = inject(Location);
  @Input() targetPath: string = '/tabs/home';

  currentUrl: string = '';

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });
  }

  public onBackClick(): void {
    this.location.back();
    // parent.postMessage(
    //   JSON.stringify({
    //     target: this.targetPath,
    //   }),
    //   '*'
    // );
  }
}
