import { ChangeDetectorRef, Component, OnInit, signal } from '@angular/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { starterPages } from '../starter-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StarterFormDataService } from '../starter-form-data.service';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { AddressAutocompleteComponent } from 'src/app/components/address-autocomplete/address-autocomplete.component';

@Component({
  selector: 'app-pickup-address',
  standalone: true,
  imports: [
    ReactiveTextInputComponent,
    CtaButtonsComponent,
    GoogleMap,
    MapMarker,
    AddressAutocompleteComponent,
  ],
  templateUrl: './pickup-address.component.html',
  styleUrl: './pickup-address.component.scss',
})
export class PickupAddressComponent implements OnInit {
  starterPages: string[] = starterPages;

  // constructor(private formDataService: StarterFormDataService) {
  //   this.formGroup = new FormGroup({
  //     usernameControl: new FormControl('', Validators.required),
  //     // Add more form controls as needed
  //   });
  // }

  isAllFilled = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private starterService: StarterFormDataService
  ) {}

  ngOnInit() {
    this.starterService.setCheckPointIndex(5);
    console.log('Starting navigator');
  }

  ngAfterViewInit() {
    // set coordinates
    const latLong = localStorage.getItem('loc');
    console.log('latlong', latLong);
    if (latLong) {
      this.center = {
        lat: Number(JSON.parse(latLong).lat),
        lng: Number(JSON.parse(latLong).lng),
      };
    } else {
      this.center = {
        lat: 14.582919,
        lng: 120.979683,
      };
    }
    console.log('latlong', this.center);
  }

  googleMap = signal<google.maps.Map | null>(null);
  selectedPosition = signal<google.maps.LatLngLiteral | null>(null);
  selectedPlace = signal<google.maps.places.PlaceResult | null>(null);
  address = signal<string | null>(null);

  zoom = 12;

  // set lat long here
  center: google.maps.LatLngLiteral = {
    lat: 14.582919,
    lng: 120.979683,
  };

  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    maxZoom: 20,
    minZoom: 8,
    clickableIcons: false,
    // center: this.center
  };

  getAddress(position: google.maps.LatLngLiteral): void {
    const geocoder = new google.maps.Geocoder();
    console.log('geocoder 1', geocoder);
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK') {
        if (results && results[0]) {
          const formattedAddress = results[0].formatted_address;
          console.log('geocoder 2', formattedAddress);
          this.address.set(formattedAddress); // Update the address signal
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to:', status);
      }
    });
  }

  onMarkerDrag(event: google.maps.MapMouseEvent): void {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();

    if (lat !== undefined && lng !== undefined) {
      const newPosition: google.maps.LatLngLiteral = {
        lat: lat,
        lng: lng,
      };
      this.selectedPosition.set(newPosition);
      this.getAddress(newPosition);
    }
  }

  public onMapClick(event: google.maps.MapMouseEvent) {
    const position = event.latLng?.toJSON();
    if (!position) return;

    this.selectedPosition.set(position);

    this.getAddress(position);
    this.isAllFilled = true;
  }

  public onPlaceChanged(place: google.maps.places.PlaceResult) {
    const { geometry } = place;
    console.log('onPlaceChanged', geometry);

    if (!geometry) {
      return;
    }

    const { location } = geometry;

    if (!location) {
      return;
    }

    this.selectedPosition.set({
      lat: location.lat(),
      lng: location.lng(),
    });
    this.selectedPlace.set(place);

    this.panCamera({
      lat: location.lat(),
      lng: location.lng(),
    });

    console.log('latlong', location.lat(), location.lng());
    // this.starterService.latLong = location
    var newPosition: google.maps.LatLngLiteral = {
      lat: location.lat(),
      lng: location.lng(),
    };

    var latLong = {
      latitude: location.lat(),
      longitude: location.lng(),
    };

    this.starterService.latLong = latLong;

    console.log('saved latlong', this.starterService);

    this.getAddress(newPosition);
    this.isAllFilled = true;
  }

  public panCamera(position: google.maps.LatLngLiteral) {
    const map = this.googleMap();

    if (!map) {
      return;
    }

    map.panTo(position);
  }

  public async onMapInitialized(event: google.maps.Map) {
    this.googleMap.set(event);

    try {
      const currentPosition = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        }
      );

      const position = {
        lat: currentPosition.coords.latitude,
        lng: currentPosition.coords.longitude,
      } satisfies google.maps.LatLngLiteral;
      console.log('Current position:', position);

      this.panCamera(position);
      this.selectedPosition.set(position);
    } catch (error) {
      console.error('Geolocation error:', error);
      const fallbackPosition = {
        lat: 14.582919,
        lng: 120.979683,
      };
      console.log('Using fallback position:', fallbackPosition);

      this.panCamera(fallbackPosition);
      this.selectedPosition.set(fallbackPosition);
    }
  }

  toggleNext() {
    this.starterService.setCheckPointIndex(4);
    console.log('Toggling next');
    const position = this.selectedPosition();
    const address = this.address();

    if (position !== null) {
      localStorage.setItem('loc', JSON.stringify(position));
      console.log('addressinput 1', address);
      if (address !== null) {
        localStorage.setItem('address', address);
      }
      console.log('addressinput 2', address);
    }
    // this.router.navigateByUrl('/tabs/addresses/(address-outlet:address-details)')
  }

  toggleBack() {
    this.router.navigateByUrl('/tabs/settings/shipping');
  }
}
