<div class="container">
  <div class="flex-container">
    <div class="flex-input-container">
      <label *ngIf="labelText !== ''" [for]="id" class="label">
        {{ labelText }}<span class="tw-text-[#9FE9FE]">*</span>
      </label>
      <!-- File Input Container -->
      <div class="file-input-container">
        <label
          [for]="id"
          class="file-label"
          (click)="onFileInputClick($event)"
          [ngClass]="{
            'has-errors': hasFile() && hasErrors,
            'has-file': hasFile() && !hasErrors
          }"
        >
          <div class="input-content">
            <div class="img-input-container">
              <img
                src="../../../../assets/images/Image Upload.png"
                alt="File Upload"
              />
            </div>

            <p class="placeholder-text">{{ placeholder }}</p>
          </div>
          <input
            [id]="id"
            type="file"
            class="hidden-input"
            [attr.capture]="capture"
            [accept]="buildAcceptString()"
            (change)="onFileChange($event)"
          />
          <button
            *ngIf="hasFile()"
            (click)="onFileRemoved()"
            class="remove-btn"
          >
            <img
              src="assets/icons/file-input-cancel.svg"
              alt="remove file"
              class="remove-icon"
            />
          </button>
          <div *ngIf="hasFile()" class="file-preview">
            <img
              *ngIf="isFileImage()"
              [src]="file()! | fileUrl"
              alt="Document Preview"
              class="preview-img"
            />
            <span *ngIf="!isFileImage()" class="preview-text">{{
              file()!.name
            }}</span>
          </div>
        </label>
      </div>
    </div>

    <!-- Sample Container -->
    <div class="sample-container">
      <div *ngIf="id == 'selfie'" style="margin-bottom: -16px"></div>
      <span class="sample-text">Sample</span>
      <img
        [src]="sampleImageUrl"
        alt="Sample Document Preview"
        class="sample-image"
        (click)="openImageDialog(sampleImageUrl)"
      />
    </div>
  </div>
  <div *ngIf="!!errors && isTouched" class="error-message">
    {{ errorMessage }}
  </div>
</div>
