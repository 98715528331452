import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileSizeValidator } from './components/inputs/reactive-file-input/validators/file-size.validator';
import { ValidationMessageFn } from './components/inputs/types/validation-message-fn';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { AppService } from './app.service';
import { StarterFormDataService } from './pages/starter/starter-form-data.service';
import { UserService } from './services/user/user.service';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'onboarding2.0';
  currentUrl: string | undefined;

  public form: FormGroup = new FormGroup({
    file: new FormControl<File | null>(null, {
      validators: [FileSizeValidator.validate(100)],
    }),
    file2: new FormControl<File | null>(null, {
      validators: [FileSizeValidator.validate(1000)],
    }),
  });

  public fileValidationMessage: Record<string, ValidationMessageFn> = {
    maxSize: (errors) =>
      'File size is too large. Max size is 100 bytes Woah invalid.',
  };
  public file2ValidationMessage: Record<string, ValidationMessageFn> = {
    maxSize: (errors) => 'File(2) size is too large. Max size is 100 bytessss.',
  };

  constructor(
    private router: Router,
    private appService: AppService,
    private activatedroute: ActivatedRoute,
    private startservice: StarterFormDataService,
    private userService: UserService
  ) {
    // Subscribe to router events to update the current URL
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });
    localStorage.removeItem('token');

    // localStorage.removeItem("checkPointIndex")
    this.activatedroute.queryParams.subscribe(async (data: any) => {
      console.log('Data: ', data);
      console.log('get token params', data);
      const checkPointIndex = localStorage.getItem('checkPointIndex');
      if (checkPointIndex) {
        const savedUserId = checkPointIndex.split('_')[1];
        if (data.user) {
          const userId = data.user;
          console.log(savedUserId);
          console.log(userId);
          if (savedUserId === userId) {
            console.log('Same user, do not delete checkpoint');
          } else {
            console.log('Different user, deleting checkpoint');
            localStorage.removeItem('checkPointIndex');
          }
        }
      }
      if ('uid' in data) {
        await lastValueFrom(this.appService.getToken(data.uid)).then((results) => {
          console.log('get token', results.secretToken);
          localStorage.setItem('token', results['secretToken']);
          this.startservice.getMerchantDetailsV2().subscribe((data: any) => {
            this.startservice.merchantData = data;
          });
          this.startservice.getShopBusinessHoursV2().subscribe((data: any) => {
            this.startservice.shopBusinessHours = data;
          });
          this.startservice.getShopDescriptionV2().subscribe((data: any) => {
            this.startservice.shopDescription = data.shopdescription;
          });
          this.startservice.getMerchantDetailsV2().subscribe((data: any) => {
            console.log('VD merchant details', data);
            this.startservice.updateStoreLogoURL(data.shop_image);
          });
        });
      }
      if ('user' in data) {
        this.userService.saveUserId(data.user);
      }
    });
  }

  ngOnInit(): void {}
}
