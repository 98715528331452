import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})

export class AppService{
    constructor (private client: HttpClient){}

    public url: string = environment.HANDOVER_URL

    getToken(uid: string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json',)
        let params = new HttpParams().set('uid', uid)
        return this.client.get<any>(this.url + '/get-token', {params: params, headers: headers})
    }
}