import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-reactive-textarea-input',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './reactive-textarea-input.component.html',
  styleUrl: './reactive-textarea-input.component.scss',
})
export class ReactiveTextAreaInputComponent {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() id: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() placeholder: string = '';
  @Input() required: boolean = false; // Add required attribute
  @Input() errorMessage: string = 'Something is wrong!';

  ngOnChanges(): void {
    // Set up validators based on user input
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity(); // Trigger validation
  }
}
