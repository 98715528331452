import { Title } from '@angular/platform-browser';
import { APP_INITIALIZER, Inject, Injectable, LOCALE_ID } from '@angular/core';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
// import { Platform } from '@ionic/angular';
import {
  I18NextModule,
  ITranslationService,
  I18NEXT_SERVICE,
  I18NextTitle,
  I18NextLoadResult,
  defaultInterpolationFormat,
} from 'angular-i18next';
import { environment } from 'src/environments/environment';

export function appInit(i18next: ITranslationService, 
  // platform: Platform
) {
  return () => {
    let promise: Promise<I18NextLoadResult> = i18next
      .use(LanguageDetector)
      .use(HttpBackend)
      .init({
        debug: true,
        fallbackLng: 'en',
        preload: ['en', 'th'],
        ns: ['translations'],
        compatibilityJSON: 'v4',
        defaultNS: 'translations',
        supportedLngs: ['en', 'th'],
        backend: {
          loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
          requestOptions: {
            cache: 'default',
          }
        },
        interpolation: {
          format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
        },
        detection: {
          order: ['querystring', 'cookie', 'localStorage', 'navigator'],
          lookupCookie: 'lang',
          lookupLocalStorage: 'i18nextLng',
          caches: ['localStorage', 'cookie'],
        },
        load: 'languageOnly',
      });

    return promise;
  };
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, 
    ],
    multi: true,
  },
  {
    provide: Title,
    useClass: I18NextTitle,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: (i18next: ITranslationService) => {
      return i18next.language === 'th' ? 'th-TH' : 'en-PH';
    },
  },
];

export function changeLanguage(i18next: ITranslationService, lang: string) {
  i18next.changeLanguage(lang).then(() => {
    localStorage.setItem('i18nextLng', lang);
    
  });
}
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(@Inject(I18NEXT_SERVICE) private i18n: ITranslationService) {}
  public language: string = 'en';
  public currency: string = 'PHP';

  setCurrency() {
    if (this.language === 'th') {
      this.currency = 'THB';
      environment.PLATFORM_API_URL = environment.LANGUAGE_CONFIG.th.PLATFORM_API_URL;
    } else if (this.language === 'en') {
      this.currency = 'PHP';
      environment.PLATFORM_API_URL = environment.LANGUAGE_CONFIG.en.PLATFORM_API_URL;
    } else {
      this.currency = 'PHP';
    }
    console.log('set currency', this.currency, this.language);
  }

  changeLanguage(lang: string) {
    this.i18n.changeLanguage(lang).then(() => {
      this.language = lang;
      this.setCurrency();
      document.location.reload();
      
    });
  }

  i18nInit() {
    if (this.i18n.isInitialized) {
      this.language = this.i18n.language;
      this.setCurrency();
    } else {
      this.i18n.events.initialized.subscribe((e) => {
        if (e) {
          this.language = this.i18n.language;
          this.setCurrency();
          document.location.reload();
        }
      });
    }

    this.i18n.events.languageChanged.subscribe((lang) => {
      if (lang !== null && lang !== undefined && this.language !== lang) {
        this.language = lang;
        this.setCurrency();
        document.location.reload();
      }
    });
  }

  getLanguage() {
    return this.language;
  }

  getCurrency() {
    return this.currency;
  }
}
