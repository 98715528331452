<header class="tw-relative">
  <app-back-button class="tw-absolute back-btn" *ngIf="!currentUrl.includes('card-activation')" />
  <app-starter-header *ngIf="currentUrl.includes('/starter')" />
  <img
    *ngIf="currentUrl.includes('/advanced')"
    src="../../../assets/images/Advanced Header.svg"
    alt="Advanced Header"
  />
  <app-verified-header *ngIf="currentUrl.includes('/verified')" />
</header>
