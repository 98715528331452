<div class="container">
    <div class="section">
        <h3 class="title">Bank Account Details*</h3>
        <p class="note">
            Provide bank account details where you wish to have the order
            payment transferred.
        </p>
    </div>

    <div class="section">
        <div class="title">Bank Name</div>
        <div class="tw-h-[12px]"></div>
        <app-reactive-ng-select-field
            placeholder="Bank Name"
            id="bankName"
            [formControl]="bankName"
            [options]="bankNames"
        />
    </div>

    <div class="section">
        <div class="title">Account Name</div>
        <app-reactive-text-input
            placeholder="Enter account name"
            [required]="true"
            [control]="accountName"
        ></app-reactive-text-input>
        <div class="note">
            <i>
                Note: Please ensure that the account name matches either the
                name on your ID or the business name you'll provide during the
                verification process.
            </i>
        </div>
    </div>

    <div class="section">
        <div class="title">Account Number</div>
        <app-reactive-text-input
            placeholder="Enter account number"
            type="number"
            [control]="accountNumber"
            reference="bank-number"
            [minNum]="minNum"
            [maxNum]="maxNum"
        ></app-reactive-text-input>
    </div>
</div>

<app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="!isAllFilled"
    [data]="formData"
    (nextEvent)="handleNext()"
    [nextCallback]="handleNext"
></app-cta-buttons>
