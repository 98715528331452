<div class="container">
  <div class="section">
    <h3 class="title">{{ "account-details.title" | i18next }}*</h3>
    <p class="note">
      {{ "account-details.description" | i18next }}
    </p>
  </div>

  <div class="section">
    <div class="title">{{ "account-details.bank-name-input" | i18next }}</div>
    <div class="tw-h-[12px]"></div>
    <app-reactive-ng-select-field
      placeholder="{{ 'account-details.bank-name-input' | i18next }}"
      id="bankName"
      [formControl]="getFormControl('bankName')"
      [options]="bankNames"
      [validationMessage]="validationMessages"
      [errors]="getFieldError('bankName')"
    />
  </div>

  <div class="section">
    <div class="title">
      {{ "account-details.account-name-input" | i18next }}
    </div>
    <app-reactive-text-input
      placeholder="{{ 'account-details.account-name-input' | i18next }}"
      [required]="true"
      [control]="getFormControl('accountName')"
    ></app-reactive-text-input>
    <div class="note-2">
      <i>
        {{ "account-details.accountname-guide" | i18next }}
      </i>
    </div>
  </div>

  <div class="section">
    <div class="title">
      {{ "account-details.account-number-input" | i18next }}
    </div>
    <app-reactive-text-input
      placeholder="{{ 'account-details.account-number-input' | i18next }}"
      type="number"
      [control]="getFormControl('accountNumber')"
      reference="bank-number"
      [minNum]="minNum"
      [maxNum]="maxNum"
    ></app-reactive-text-input>
  </div>
</div>

<app-cta-buttons
  currentPage="email-verification"
  [pageNames]="starterPages"
  tier="starter"
  [disabled]="!isAllFilled"
  [data]="formData"
  (nextEvent)="handleNext()"
  [nextCallback]="handleNext"
></app-cta-buttons>
