import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Provider,
  TemplateRef,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { ValidationMessageFn } from '../types/validation-message-fn';
import { getErrorMessage } from '../utils/get-error-message.utils';
import { twMerge } from 'tailwind-merge';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

const REACTIVE_NG_SELECT_FIELD_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveNgSelectFieldComponent),
  multi: true,
};

type SearchFn<T> = (term: string, item: T) => void;
type TrackByFn<T> = (item: T) => T;

type SearchEvent<T> = {
  term: string;
  items: T[];
};

@Component({
  selector: 'app-reactive-ng-select-field',
  templateUrl: './reactive-ng-select-field.component.html',
  styleUrls: ['./reactive-ng-select-field.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgSelectModule],
  providers: [REACTIVE_NG_SELECT_FIELD_ACCESSOR],
})
export class ReactiveNgSelectFieldComponent<T> implements ControlValueAccessor {
  /**
   * Ng Select Props
   */
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() bindLabel: NgSelectComponent['bindLabel'] = '';
  @Input() bindValue: NgSelectComponent['bindValue'] = '';
  @Input() options: T[] = [];
  @Input() appendTo: NgSelectComponent['appendTo'] = 'body';
  @Input() dropdownPosition: NgSelectComponent['dropdownPosition'] = 'auto';
  @Input() appearance: NgSelectComponent['appearance'] = '';
  @Input() clearable: boolean = false;
  @Input() loading: boolean = false;
  @Input() loadingText: NgSelectComponent['loadingText'] = 'Loading...';
  @Input() searchFn: SearchFn<T> | null = null;
  @Input() trackByFn: TrackByFn<T> | null = null;
  /**
   * Ng Select Outputs
   */
  @Output()
  searchEvent: EventEmitter<SearchEvent<T>> = new EventEmitter<
    SearchEvent<T>
  >();
  blurEvent: EventEmitter<any> = new EventEmitter<any>();
  focusEvent: EventEmitter<any> = new EventEmitter<any>();
  changeEvent: EventEmitter<any> = new EventEmitter<any>();
  openEvent: EventEmitter<any> = new EventEmitter<any>();
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  clearEvent: EventEmitter<any> = new EventEmitter<any>();
  addEvent: EventEmitter<any> = new EventEmitter<any>();
  removeEvent: EventEmitter<any> = new EventEmitter<any>();

  /**
   * TODO: Add More Support for NgSelect Templates
   */
  // optionTemplate: TemplateRef<any>;
  // optgroupTemplate: TemplateRef<any>;
  // labelTemplate: TemplateRef<any>;
  // multiLabelTemplate: TemplateRef<any>;
  // headerTemplate: TemplateRef<any>;
  // footerTemplate: TemplateRef<any>;
  // notFoundTemplate: TemplateRef<any>;
  // typeToSearchTemplate: TemplateRef<any>;
  // loadingTextTemplate: TemplateRef<any>;
  // tagTemplate: TemplateRef<any>;
  // loadingSpinnerTemplate: TemplateRef<any>;

  @Input()
  inputClass: string = '';

  @ContentChild('labelSlot') labelSlot?: TemplateRef<any>;
  @ContentChild('optionsSlot') optionsSlot: TemplateRef<any> | null = null;
  /**
   * Form Control Props
   */
  @Input()
  public formControl: FormControl<T | null> = new FormControl();

  @Input()
  public errors: ValidationErrors | null | undefined = null;

  @Input()
  public validationMessage: Record<string, ValidationMessageFn> = {};

  ngOnInit() {
    console.log('Placeholder:', this.placeholder);
  }

  public get errorMessage() {
    return getErrorMessage(this.errors, this.validationMessage);
  }
  public get merge() {
    return twMerge;
  }

  private onChange: (value: string | null) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  writeValue(value: string | null): void {
    this.onChange(value);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  onSearch(event: SearchEvent<T>) {
    this.searchEvent.emit(event);
  }

  onBlur(event: any) {
    this.blurEvent.emit(event);
    this.onTouched();
  }

  onFocus(event: any) {
    this.focusEvent.emit(event);
  }

  onChangeEvent(event: any) {
    this.changeEvent.emit(event);
  }

  onOpen(event: any) {
    this.openEvent.emit(event);
  }

  onClose(event: any) {
    this.closeEvent.emit(event);
  }

  onClear(event: any) {
    this.clearEvent.emit(event);
  }

  onAdd(event: any) {
    this.addEvent.emit(event);
  }

  onRemove(event: any) {
    this.removeEvent.emit(event);
  }
}
