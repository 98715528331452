<div class="container">
  <div class="flex-container">
    <div class="file-input-container">
      <label
        [for]="id"
        class="file-label"
        [ngClass]="{
          'has-errors': hasFile() && hasErrors,
          'has-file': hasFile() && !hasErrors
        }"
      >
            
          <div class="input-content"  *ngIf="!displayimage">
        
          <img
            src="../../../../assets/images/starter-upload-logo.svg"
            alt="Upload"
          />
          <p class="placeholder-text">{{ placeholder }}</p>
        </div>
        <input
          [id]="id"
          type="file"
          class="hidden-input"
          [accept]="buildAcceptString()"
          (change)="onFileChange($event)"
        />
        <button *ngIf="hasFile()||displayimage" (click)="onFileRemoved()" class="remove-btn">
          <img
            src="assets/icons/file-input-cancel.svg"
            alt="remove file"
            class="remove-icon"
          />
        </button>

        <div *ngIf="displayimage" >
          <img
            height="100px"
            width="100px"
            [src]="displayimage"
            alt="Document Preview"
          />
        </div>
        <div *ngIf="!displayimage">
          <div *ngIf="hasFile()" class="file-preview">
            <img
              *ngIf="isFileImage()"
              [src]="file()! | fileUrl"
              alt="Document Preview"
              class="preview-img"
            />
            <span *ngIf="!isFileImage()" class="preview-text">{{
              file()!.name
            }}</span>
          </div>

        </div>

      </label>
    </div>
  </div>

  <div *ngIf="errorMessage !== ''" class="error-message">
    {{ errorMessage }}
  </div>
</div>
