import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() pageNames: string[] = [];
  pages: number = 5;
  count: number = 3;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    console.log('progress bar count:', this.count);

    this.route.queryParamMap.subscribe((params) => {
      // Extract the desired query parameters
      const pages = params.get('pages');
      const count = params.get('count');

      if (pages != null) {
        this.pages = parseInt(pages);
      } else {
        this.pages = this.pageNames.length;
      }

      if (count != null) {
        this.count = parseInt(count);
      } else {
        this.count = 0;
      }
    });
  }

  filledCount(): any[] {
    return new Array(this.count + 1);
  }

  emptyCount(): any[] {
    return new Array(this.pages - this.count - 1);
  }
}
