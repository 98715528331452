import { Component } from '@angular/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { starterPages } from '../starter-data';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { ReactiveDropdownInputComponent } from '../../../components/inputs/reactive-dropdown-input/reactive-dropdown-input.component';
import { ReactiveTextField2Component } from 'src/app/components/inputs/reactive-text-field2/reactive-text-field2.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StarterFormDataService } from '../starter-form-data.service';
import { lastValueFrom } from 'rxjs';

interface FormData {
  storeName: string;
  storeType: string;
  storeCategory: string;
}

interface StoreCategory {
  id: number;
  name: string;
  store_type: number;
}

interface category {
  id: number;
  type: string;
  value: string;
}

@Component({
  selector: 'app-store-type',
  standalone: true,
  templateUrl: './store-type.component.html',
  styleUrl: './store-type.component.scss',
  imports: [
    CtaButtonsComponent,
    CommonModule,
    FormsModule,
    ReactiveDropdownInputComponent,
    ReactiveTextField2Component,
    ReactiveFormsModule,
  ],
})
export class StoreTypeComponent {
  starterPages: string[] = starterPages;
  formData: FormData[] = [];
  storeNameControl: FormControl = new FormControl();
  storeTypeControl: FormControl = new FormControl();
  storeCategoryControl: FormControl = new FormControl('');
  storeCategories: category[] = [];
  loading: boolean = false;
  allFilled: boolean = false;

  storeTypeDictionary: { [key: string]: number } = {
    food: 1,
    retail: 2,
    services: 3,
  };

  constructor(private starterService: StarterFormDataService) {
    this.storeCategoryControl.valueChanges.subscribe((value) => {
      console.log(
        'chosen storeCategoryControl',
        this.storeCategoryControl.value
      );
      this.checkRequiredFields();
    });

    this.storeNameControl.valueChanges.subscribe((value) => {
      this.checkRequiredFields();
    });

    this.storeCategoryControl.valueChanges.subscribe((value) => {
      this.checkRequiredFields();
    });
  }
  ngOnInit() {
    this.storeTypeControl.valueChanges.subscribe(async (value) => {
      await this.onStoreTypeChange(value);
    });
    this.storeNameControl.setValue(this.starterService.merchantData.shop_name);
    this.storeTypeControl.setValue(
      this.starterService.merchantData.store_type.name.toLowerCase()
    );
    this.storeCategoryControl.setValue(
      this.starterService.merchantData.store_category
    );
  }

  async onStoreTypeChange(value: string): Promise<void> {
    const storeTypeId = this.storeTypeDictionary[value.toLowerCase()];
    await this.starterService.getStoreCategories(storeTypeId).subscribe(
      (response) => {
        console.log('starter integration: store categories', response);
        //  this.storeCategories = response
        this.setupStoreCategories(response);
      },
      (error) => {
        console.error('shop description, error:', error);
      }
    );
  }

  // populate store type
  setupStoreCategories(storeCategories: any) {
    this.storeCategories = [];
    const categories = storeCategories;

    categories.sort((a: StoreCategory, b: StoreCategory) => {
      return a.name.localeCompare(b.name);
    });

    categories.forEach((item: StoreCategory) => {
      this.storeCategories.push({
        type: item.name,
        value: item.name,
        id: item.id,
      });
    });

    this.setStoreCategoryValue(
      this.starterService.merchantData.store_category.id
    );
  }

  handleNextClick = async () => {
    const formData = {
      storeName: this.storeNameControl.value,
      storeType: this.storeTypeControl.value,
      storeCategory: this.storeCategoryControl.value,
    };
    const storeTypeId =
      this.storeTypeDictionary[this.storeTypeControl.value.toLowerCase()];

    console.log('chosen category', this.storeCategoryControl.value);

    const storeCategoryId = this.getIdByValue(formData.storeCategory);

    var storeData = {
      store: {
        shop_name: this.storeNameControl.value,
        store_type: storeTypeId,
        store_category: this.storeCategoryControl.value,
        is_whole_day: true,
      },
    };

    return await lastValueFrom(
      this.starterService.updateStoreDetails(storeData)
    ).then(
      async (response) => {
        this.loading = true;
        this.starterService.setCheckPointIndex(3);
        console.log('update store details', response);

        return await lastValueFrom(
          this.starterService.getMerchantDetailsV2()
        ).then((data: any) => {
          this.starterService.merchantData = data;
          this.loading = false;
          return true;
        });
      },
      (error) => {
        console.error('update store details:', error);
        this.loading = false;
        return false;
      }
    );
  };

  checkRequiredFields() {
    this.allFilled =
      this.storeNameControl.valid &&
      this.storeTypeControl.valid &&
      this.storeCategoryControl.valid;
  }

  setStoreCategoryValue(value: string) {
    this.storeCategoryControl.setValue(value);
    console.log('storeCategoryControl', this.storeCategoryControl.value);
  }

  getIdByValue(value: string): number | null {
    const category = this.storeCategories.find(
      (category) => category.value === value
    );
    return category ? category.id : null;
  }
}
