import { Component, Input, OnInit } from '@angular/core';
import {
  DocumentChangeEvent,
  DocumentUploadComponent,
} from 'src/app/components/document-upload/document-upload.component';
import { CommonModule } from '@angular/common';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { advancedPages, idTypes } from '../advanced-data';
import { ActivatedRoute } from '@angular/router';
import {
  DocumentUploadFormKeys,
  DocumentUploadFormModel,
} from 'src/app/components/document-upload/models/document-upload-form.model';
import { Validators, FormGroup, Form } from '@angular/forms';
import { UploadService } from 'src/app/services/upload.service';
import { v4 as uuidv4 } from 'uuid';
import { SharedModule } from 'src/app/shared.module';

export type DocumentUploadType = 'primaryId' | 'secondaryId1' | 'secondaryId2';

@Component({
  selector: 'app-upload-id',
  standalone: true,
  imports: [DocumentUploadComponent, CommonModule, CtaButtonsComponent, SharedModule],
  templateUrl: './upload-id.component.html',
  styleUrls: ['./upload-id.component.scss'],
})
export class UploadIdComponent implements OnInit {
  isLoading: boolean = false;
  fileSizeLimit = 2 * 1024 * 1024;
  @Input() idCategory: string = 'primary';
  advancedPages: string[] = advancedPages;
  uploadError: string = '';
  primaryIdForm = DocumentUploadFormModel.build({
    expiration: {
      validators: [],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });
  primaryIdFormBack = DocumentUploadFormModel.build({
    expiration: {
      validators: [],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });
  secondaryId1Form = DocumentUploadFormModel.build({
    expiration: {
      validators: [],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });
  secondaryId1FormBack = DocumentUploadFormModel.build({
    expiration: {
      validators: [Validators.required],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });
  secondaryId2Form = DocumentUploadFormModel.build({
    expiration: {
      validators: [],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });
  secondaryId2FormBack = DocumentUploadFormModel.build({
    expiration: {
      validators: [Validators.required],
    },
    file: {
      validators: [Validators.required],
    },
    idType: {
      validators: [Validators.required],
    },
  });

  formData: { [key: string]: DocumentChangeEvent | undefined } = {};
  nextDisabled: boolean = false;
  merchantId: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private uploadService: UploadService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.idCategory = params['type'] || 'primary';
    });
  }

  ngOnInit(): void {
    this.nextDisabled = true;
    this.merchantId = parseInt(localStorage.getItem('userId')!);
    this.primaryIdForm.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'primary') {
        const selectedIdType = this.idTypes.find(
          (idType) => idType.name === this.primaryIdForm.value.idType
        );

        this.nextDisabled = false;

        if (!selectedIdType) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.primaryIdForm.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType && selectedIdType.hasExpiry) {
          if (!this.primaryIdForm.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType && selectedIdType.hasBackOfId) {
          console.log(this.primaryIdFormBack.value);
          if (!this.primaryIdFormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });

    this.primaryIdFormBack.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'primary') {
        const selectedIdType = this.idTypes.find(
          (idType) => idType.name === this.primaryIdForm.value.idType
        );

        this.nextDisabled = false;

        if (!selectedIdType) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.primaryIdForm.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType && selectedIdType.hasExpiry) {
          if (!this.primaryIdForm.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType && selectedIdType.hasBackOfId) {
          console.log(this.primaryIdFormBack.value);
          if (!this.primaryIdFormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });

    this.secondaryId1Form.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'secondary') {
        const selectedIdType1 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId1Form.value.idType
        );
        const selectedIdType2 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId2Form.value.idType
        );

        // Checks the validations for the first id
        this.nextDisabled = false;
        if (!selectedIdType1 || !selectedIdType2) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.secondaryId1Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType1 && selectedIdType1.hasExpiry) {
          if (!this.secondaryId1Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType1 && selectedIdType1.hasBackOfId) {
          console.log(this.secondaryId1FormBack.value);
          if (!this.secondaryId1FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }

        // Checks the validation for the second id
        if (!this.secondaryId2Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType2 && selectedIdType2.hasExpiry) {
          if (!this.secondaryId2Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType2 && selectedIdType2.hasBackOfId) {
          console.log(this.secondaryId2FormBack.value);
          if (!this.secondaryId2FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });

    this.secondaryId1FormBack.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'secondary') {
        const selectedIdType1 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId1Form.value.idType
        );
        const selectedIdType2 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId2Form.value.idType
        );

        // Checks the validations for the first id
        this.nextDisabled = false;
        if (!selectedIdType1 || !selectedIdType2) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.secondaryId1Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType1 && selectedIdType1.hasExpiry) {
          if (!this.secondaryId1Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType1 && selectedIdType1.hasBackOfId) {
          console.log(this.secondaryId1FormBack.value.file);
          if (!this.secondaryId1FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }

        // Checks the validation for the second id
        if (!this.secondaryId2Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType2 && selectedIdType2.hasExpiry) {
          if (!this.secondaryId2Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType2 && selectedIdType2.hasBackOfId) {
          console.log(
            'IC check secondary id value back',
            this.secondaryId2FormBack.value
          );
          if (!this.secondaryId2FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });

    this.secondaryId2Form.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'secondary') {
        const selectedIdType1 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId1Form.value.idType
        );
        const selectedIdType2 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId2Form.value.idType
        );

        // Checks the validations for the first id
        this.nextDisabled = false;

        if (!selectedIdType1 || !selectedIdType2) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.secondaryId1Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType1 && selectedIdType1.hasExpiry) {
          if (!this.secondaryId1Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType1 && selectedIdType1.hasBackOfId) {
          console.log(this.secondaryId1FormBack.value);
          if (!this.secondaryId1FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }

        // Checks the validation for the second id
        if (!this.secondaryId2Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType2 && selectedIdType2.hasExpiry) {
          if (!this.secondaryId2Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType2 && selectedIdType2.hasBackOfId) {
          console.log(this.secondaryId2FormBack.value);
          if (!this.secondaryId2FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });

    this.secondaryId2FormBack.valueChanges.subscribe((valueChanges) => {
      if (this.idCategory === 'secondary') {
        const selectedIdType1 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId1Form.value.idType
        );
        const selectedIdType2 = this.idTypes.find(
          (idType) => idType.name === this.secondaryId2Form.value.idType
        );

        // Checks the validations for the first id
        this.nextDisabled = false;

        if (!selectedIdType1 || !selectedIdType2) {
          console.log('Has not selected id type');
          this.nextDisabled = true;
        }

        if (!this.secondaryId1Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType1 && selectedIdType1.hasExpiry) {
          if (!this.secondaryId1Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType1 && selectedIdType1.hasBackOfId) {
          console.log(this.secondaryId1FormBack.value.file);
          if (!this.secondaryId1FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }

        // Checks the validation for the second id
        if (!this.secondaryId2Form.value.file) {
          console.log('Has no front id');
          this.nextDisabled = true;
        }
        if (selectedIdType2 && selectedIdType2.hasExpiry) {
          if (!this.secondaryId2Form.value.expiration) {
            console.log('Has no expiration date');
            this.nextDisabled = true;
          }
        }
        if (selectedIdType2 && selectedIdType2.hasBackOfId) {
          console.log(
            'IC check secondary id value back',
            this.secondaryId2FormBack.value
          );
          if (!this.secondaryId2FormBack.value.file) {
            console.log('Has no back of id');
            this.nextDisabled = true;
          }
        }
      }
    });
  }

  public get idTypes() {
    return idTypes;
  }

  onDocumentChange(
    type: 'primaryId' | 'secondaryId1' | 'secondaryId2',
    event: DocumentChangeEvent | undefined
  ) {
    if (!event) {
      return;
    }
    this.formData[type] = event;
  }

  handleUploadError(error: Error, file: File | null | undefined): boolean {
    if (file instanceof File) {
      this.uploadError =
        'Something went wrong with the upload, please try again.';
      console.error('Error uploading primary ID: ', error, file.size);
      this.isLoading = false;
      return false;
    } else {
      console.error('File is not valid:', file);
      this.isLoading = false;
      return false;
    }
  }

  async validateAndHandleLargeFile(
    file: File,
    idType: string
  ): Promise<File | null> {
    if (file.size > this.fileSizeLimit) {
      console.log('IC00 Trigger for image compression', file.size, idType);
      const compressedFile = await this.uploadService.compressImage(file);
      if (!compressedFile) {
        this.isLoading = false;
        return null;
      }
      return compressedFile;
    }
    return file;
  }

  onNextPressed = async () => {
    this.isLoading = true;
    console.log('Next pressed', this.formData);

    if (this.idCategory === 'primary') {
      console.log('IC01 Primary submitting', this.merchantId);

      for (let idtype of idTypes) {
        if (idtype.name === this.primaryIdForm.value.idType) {
          this.primaryIdForm.controls['expiration'].setValidators(
            idtype.hasExpiry ? Validators.required : null
          );
          if (idtype.hasBackOfId) {
            this.primaryIdFormBack.controls['expiration'].setValidators(
              idtype.hasExpiry ? Validators.required : null
            );
          }
        }
      }

      return await this.onPrimarySubmitted().then((primarySubmitResult) => {
        return primarySubmitResult;
      });
    } else if (this.idCategory === 'secondary') {
      console.log('IC01 Secondary submitting', this.merchantId);

      for (let idtype of idTypes) {
        if (idtype.name === this.secondaryId1Form.value.idType) {
          this.secondaryId1Form.controls['expiration'].setValidators(
            idtype.hasExpiry ? Validators.required : null
          );
          if (idtype.hasBackOfId) {
            this.secondaryId1FormBack.controls['expiration'].setValidators(
              idtype.hasExpiry ? Validators.required : null
            );
          }
        }
        if (idtype.name === this.secondaryId2Form.value.idType) {
          this.secondaryId2Form.controls['expiration'].setValidators(
            idtype.hasExpiry ? Validators.required : null
          );
          if (idtype.hasBackOfId) {
            this.secondaryId2FormBack.controls['expiration'].setValidators(
              idtype.hasExpiry ? Validators.required : null
            );
          }
        }
      }
      return await this.onSecondarySubmitted().then((result) => {
        return result;
      });
    }
    return false;
  };

  async onPrimarySubmitted(): Promise<boolean> {
    // Checks if there is a merchant ID
    if (!this.merchantId) {
      this.uploadError =
        'Something went wrong with the upload, please try again';
      console.log('No merchant ID on primary ID upload');
      this.isLoading = false;
      return false;
    }

    this.primaryIdForm.markAllAsTouched();
    this.primaryIdFormBack.controls['expiration'].setValue(
      this.primaryIdForm.value.expiration
        ? this.primaryIdForm.value.expiration
        : null
    );
    this.primaryIdFormBack.controls['idType'].setValue(
      this.primaryIdForm.value.idType ? this.primaryIdForm.value.idType : null
    );

    // Checks if form is valid
    if (!this.primaryIdForm.valid) {
      this.isLoading = false;
      console.log('Primary ID form is not valid');
      return false;
    }

    // Checks if there is a file
    const file = this.primaryIdForm.value.file;
    if (!file) {
      this.uploadError = 'A file is required before proceeding';
      console.log('No primary ID file uploaded');
      this.isLoading = false;
      return false;
    }

    console.log('IC File size', file.size);

    // Checks if the file size is too large
    const validatedPrimaryIdFile = await this.validateAndHandleLargeFile(
      file,
      'Primary ID Front'
    );

    // Uploads the front of the primary form
    const payload = {
      file: validatedPrimaryIdFile,
      merchant_id: this.merchantId,
      status: 'active',
      uid: uuidv4(),
      document_name: 'primary_id_front',
      extras: JSON.stringify({
        id_subtype: this.primaryIdForm.value.idType || null,
        expiration_date: this.primaryIdForm.value.expiration || null,
      }),
    };

    console.log('Primary Front Payload', payload, this.merchantId);
    return await this.uploadFile(payload)
      .then(async () => {
        const primaryIdBackFile = this.primaryIdFormBack.value.file;

        // Uploads the back of the primary form if it has one
        if (primaryIdBackFile) {
          const validatedPrimaryIdBackFile =
            await this.validateAndHandleLargeFile(
              primaryIdBackFile,
              'Primary ID Back'
            );

          const payload_back = {
            file: validatedPrimaryIdBackFile,
            merchant_id: this.merchantId,
            status: 'active',
            uid: uuidv4(),
            document_name: 'primary_id_back',
            extras: JSON.stringify({
              id_subtype: this.primaryIdFormBack.value.idType || null,
              expiration_date: this.primaryIdFormBack.value.expiration || null,
            }),
          };
          console.log('Primary Back Payload', payload_back, this.merchantId);
          return await this.uploadFile(payload_back)
            .then(() => {
              this.isLoading = false;
              return true;
            })
            .catch((error) => {
              return this.handleUploadError(
                error,
                this.primaryIdFormBack.value.file
              );
            });
        } else {
          this.isLoading = false;
          return true;
        }
      })
      .catch((error) => {
        return this.handleUploadError(error, file);
      });
  }

  async onSecondarySubmitted(): Promise<boolean> {
    this.secondaryId1Form.markAllAsTouched();
    this.secondaryId2Form.markAllAsTouched();
    this.primaryIdFormBack.controls['expiration'].setValue(
      this.primaryIdForm.value.expiration
        ? this.primaryIdForm.value.expiration
        : null
    );
    this.primaryIdFormBack.controls['idType'].setValue(
      this.primaryIdForm.value.idType ? this.primaryIdForm.value.idType : null
    );

    // Checks if there is a merchant ID
    if (!this.merchantId) {
      this.uploadError =
        'Something went wrong with the upload, please try again';
      console.log('No merchant ID on secondary ID upload');
      this.isLoading = false;
      return false;
    }

    // Checks if form is valid
    if (!this.secondaryId1Form.valid || !this.secondaryId2Form.valid) {
      this.nextDisabled = true;
      this.isLoading = false;
      console.log('Primary ID form is not valid');
      return false;
    }

    // Checks if there is a file
    const secondaryId1FrontFile = this.secondaryId1Form.value.file;
    const secondaryId2FrontFile = this.secondaryId2Form.value.file;
    if (!secondaryId1FrontFile || !secondaryId2FrontFile) {
      this.uploadError = 'A file is required before proceeding';
      console.log('Missing at least one secondary file');
      this.isLoading = false;
      return false;
    }

    const validatedSecondaryId1File = await this.validateAndHandleLargeFile(
      secondaryId1FrontFile,
      'Secondary ID 1 Front'
    );

    const validatedSecondaryId2File = await this.validateAndHandleLargeFile(
      secondaryId2FrontFile,
      'Secondary ID 2 Front'
    );

    // Uploads secondary id 1
    const payload_1 = {
      file: validatedSecondaryId1File,
      merchant_id: this.merchantId,
      status: 'active',
      uid: uuidv4(),
      document_name: 'secondary_id_1',
      extras: JSON.stringify({
        id_subtype: this.secondaryId1Form.value.idType || null,
        expiration_date: this.secondaryId1Form.value.expiration || null,
      }),
    };
    console.log('Secondary 1 Front Payload', payload_1, this.merchantId);
    return this.uploadFile(payload_1)
      .then(async () => {
        const secondaryId1BackFile = this.secondaryId1FormBack.value.file;
        if (secondaryId1BackFile) {
          const validatedSecondaryId1BackFile =
            await this.validateAndHandleLargeFile(
              secondaryId1BackFile,
              'Secondary ID 1 Back'
            );

          const payload_1_back = {
            file: validatedSecondaryId1BackFile,
            merchant_id: this.merchantId,
            status: 'active',
            uid: uuidv4(),
            document_name: 'secondary_id_1_back',
            extras: JSON.stringify({
              id_subtype: this.secondaryId1Form.value.idType || null,
              expiration_date: this.secondaryId1Form.value.expiration || null,
            }),
          };

          console.log(
            'Secondary 1 Back Payload',
            payload_1_back,
            this.merchantId
          );
          return await this.uploadFile(payload_1_back)
            .then(async () => {
              const payload_2 = {
                file: validatedSecondaryId2File,
                merchant_id: this.merchantId,
                status: 'active',
                uid: uuidv4(),
                document_name: 'secondary_id_2',
                extras: JSON.stringify({
                  id_subtype: this.secondaryId2Form.value.idType || null,
                  expiration_date:
                    this.secondaryId2Form.value.expiration || null,
                }),
              };

              console.log(
                'Secondary 2 Front Payload',
                payload_2,
                this.merchantId
              );
              return await this.uploadFile(payload_2)
                .then(async () => {
                  const secondaryId2BackFile =
                    this.secondaryId2FormBack.value.file;

                  if (secondaryId2BackFile) {
                    const validatedSecondaryId2BackFile =
                      await this.validateAndHandleLargeFile(
                        secondaryId2BackFile,
                        'Secondary ID 2 Back'
                      );

                    const payload_2_back = {
                      file: validatedSecondaryId2BackFile,
                      merchant_id: this.merchantId,
                      status: 'active',
                      uid: uuidv4(),
                      document_name: 'secondary_id_2_back',
                      extras: JSON.stringify({
                        id_subtype: this.secondaryId2Form.value.idType || null,
                        expiration_date:
                          this.secondaryId2Form.value.expiration || null,
                      }),
                    };

                    console.log(
                      'Secondary 2 Back Payload',
                      payload_2_back,
                      this.merchantId
                    );
                    return await this.uploadFile(payload_2_back)
                      .then(async () => {
                        this.isLoading = false;
                        return true;
                      })
                      .catch((error) => {
                        return this.handleUploadError(
                          error,
                          this.secondaryId2FormBack.value.file
                        );
                      });
                  } else {
                    this.isLoading = false;
                    return true;
                  }
                })
                .catch((error) => {
                  return this.handleUploadError(
                    error,
                    this.secondaryId2Form.value.file
                  );
                });
            })
            .catch((error) => {
              return this.handleUploadError(
                error,
                this.secondaryId1FormBack.value.file
              );
            });
        } else {
          const payload_2 = {
            file: validatedSecondaryId2File,
            merchant_id: this.merchantId,
            status: 'active',
            uid: uuidv4(),
            document_name: 'secondary_id_2',
            extras: JSON.stringify({
              id_subtype: this.secondaryId2Form.value.idType || null,
              expiration_date: this.secondaryId2Form.value.expiration || null,
            }),
          };

          console.log('Secondary 2 Front Payload', payload_2, this.merchantId);
          return await this.uploadFile(payload_2)
            .then(async () => {
              const secondaryId2BackFile = this.secondaryId2FormBack.value.file;

              if (secondaryId2BackFile) {
                const validatedSecondaryId2BackFile =
                  await this.validateAndHandleLargeFile(
                    secondaryId2BackFile,
                    'Secondary ID 2 Back'
                  );

                const payload_2_back = {
                  file: validatedSecondaryId2BackFile,
                  merchant_id: this.merchantId,
                  status: 'active',
                  uid: uuidv4(),
                  document_name: 'secondary_id_2_back',
                  extras: JSON.stringify({
                    id_subtype: this.secondaryId2FormBack.value.idType || null,
                    expiration_date:
                      this.secondaryId2FormBack.value.expiration || null,
                  }),
                };

                console.log(
                  'Secondary 2 Back Payload',
                  payload_2_back,
                  this.merchantId
                );
                return await this.uploadFile(payload_2_back)
                  .then(async () => {
                    this.isLoading = false;
                    return true;
                  })
                  .catch((error) => {
                    return this.handleUploadError(
                      error,
                      this.secondaryId2FormBack.value.file
                    );
                  });
              } else {
                this.isLoading = false;
                return true;
              }
            })
            .catch((error) => {
              return this.handleUploadError(
                error,
                this.secondaryId2Form.value.file
              );
            });
        }
      })
      .catch((error) => {
        return this.handleUploadError(error, this.secondaryId1Form.value.file);
      });
  }

  uploadFile(payload: any): Promise<any> {
    return this.uploadService
      .uploadFile(
        payload.file,
        payload.merchant_id,
        payload.uid,
        payload.document_name,
        payload.status,
        payload.extras
      )
      .toPromise();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
