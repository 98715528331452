import { ValidationErrors } from '@angular/forms';
import { ValidationMessageFn } from '../types/validation-message-fn';

/**
 * This returns the error message based on the first error key
 * @param errors The errors object
 * @param validationMessage  The validation message object
 * @returns
 */
export const getErrorMessage = (
  errors: ValidationErrors | null | undefined,
  validationMessage: Record<string, ValidationMessageFn>
): string => {
  if (!errors) return '';
  const errorKey = Object.keys(errors)[0];
  const errorFn = validationMessage[errorKey];
  return errorFn ? errorFn(errors[errorKey]) : `Error: ${errorKey}`;
};
