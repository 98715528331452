<div class="main-container">
  <div class="container" *ngIf="idCategory == 'primary'">
    <h3 class="title">
      Upload Primary ID<span class="tw-text-[#9FE9FE]">*</span>
    </h3>
    <span class="note">
      Upload a clear picture of your ID, make sure that your full name, picture,
      signature and ID number are visible and without obstruction. The ID
      provided should be that of the business owner or authorized
      representative.
    </span>

    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('primaryId', $event)"
      [form]="primaryIdForm"
      [secondaryForm]="primaryIdFormBack"
      [id]="'primary-id'"
    />
  </div>

  <div class="container" *ngIf="idCategory == 'secondary'">
    <h3 class="title">
      Upload Two Secondary IDs<span class="tw-text-[#9FE9FE]">*</span>
    </h3>
    <span class="note">
      Upload a clear picture of your IDs. Make sure that your full names,
      pictures, signatures, and ID numbers are visible and without obstruction.
      The IDs provided should be that of the business owner or authorized
      representative.
    </span>

    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('secondaryId1', $event)"
      [form]="secondaryId1Form"
      [secondaryForm]="secondaryId1FormBack"
      [id]="'secondary-id-1'"
    />
    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('secondaryId2', $event)"
      [form]="secondaryId2Form"
      [secondaryForm]="secondaryId2FormBack"
      [id]="'secondary-id-2'"
    />
  </div>
  @if (uploadError) {
  <div>
    <p class="error-message">{{ uploadError }}</p>
  </div>
  }
  <app-cta-buttons
    currentPage="poi"
    [pageNames]="advancedPages"
    tier="advanced"
    [disabled]="nextDisabled"
    (nextEvent)="onNextPressed()"
    [nextCallback]="onNextPressed"
    [isLoading]="isLoading"
  />
</div>
