<div class="container tw-text-white">
  <!-- <app-progress-bar></app-progress-bar> -->
  <router-outlet></router-outlet>
  <div class="tw-px-[16px] tw-pb-20" *ngIf="loaded">
    <div class="tw-h-[32px]"></div>
    <div class="tw-font-bold tw-text-[24px]">Complete the Challenges</div>
    <div class="tw-h-[32px]"></div>

    <app-card-challenge
      imgUri="assets/images/verify_image_1.svg"
      labelText="Add 3 products"
      [doneCount]="totalProduct"
      [total]="3"
      [destination]="'/tabs/inventory'"
    />

    <app-card-challenge
      imgUri="assets/images/verify_image_2.svg"
      labelText="Complete 5 Web Store orders"
      [doneCount]="totalOrders"
      [total]="5"
      [type]="'WEBSTORE'"
      [destination]="'/tabs/my-account'"
      [parameters]="{ action: 'QR' }"
    />

    <app-card-challenge
      imgUri="assets/images/verify_image_3.svg"
      labelText="Upload a Store Banner"
      [doneCount]="totalBanner"
      [total]="1"
      [destination]="'/tabs/settings/online-stores'"
    />
  </div>

  <router-outlet name="verify-outlet"></router-outlet>

  <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-p-[16px]">
    <button
      class="tw-w-full tw-h-[56px] tw-bg-[#0099E0] tw-text-white tw-font-semibold tw-text-[18px]"
      (click)="claimVerify()"
      [disabled]="!canClaim()"
      [ngClass]="{ 'tw-bg-[#555a5a]': !canClaim() }"
    >
      <span>Claim My Badge</span>
    </button>
  </div>
</div>
