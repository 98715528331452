import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { starterPages } from './starter-data';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';

type MerchantDataKey =
  | 'merchant-tier'
  | 'merchant-store'
  | 'has-seen-splash'
  | 'merchant-user-info'
  | 'user'
  | 'merchant-info'
  | 'authuser'
  | 'token';
@Injectable({
  providedIn: 'root',
})
export class StarterFormDataService {
  public merchantData: any;
  public shopDescription: string = '';
  public shopBusinessHours: any;
  public storeType: any;
  public storeCategories: any;
  private storeLogoImg: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public storeLogo$ = this.storeLogoImg.asObservable();
  private tokenAuth = '';
  public latLong: any;

  constructor(
    private http: HttpClient,
    private authservice: AuthService,
    private appservice: AppService,
    private activatedroute: ActivatedRoute
  ) {}

  private formData: any = {};

  activateStandardShipping() {
    return this.http.post(
      `${environment.PLATFORM_API_URL}/api/v1/apps/standard-shipping/activate/`,
      null
    );
  }

  activateOndemandShipping() {
    return this.http.post(
      `${environment.PLATFORM_API_URL}/api/v1/apps/ondemand-shipping/activate/`,
      null
    );
  }

  sendOTP() {
    // const token = localStorage.getItem('token');
    const token = this.authservice.getToken();
    if (typeof token === 'string') {
      console.log('send email otp start');
      this.http
        .get(`${environment.PLATFORM_API_URL}/api/v1/auth/user/`)
        .subscribe((data: any) => {
          console.log('send email otp resend');
          this.http
            .post(
              `${environment.PLATFORM_API_URL}/api/v1/signup/resend-email/`,
              {
                email: data['email'],
              }
            )
            .subscribe((response: any) => {
              console.log('send email otp response', response);
            });
        });
    } else {
      // const uid = this.activatedroute.snapshot.queryParamMap.get("uid")!
      // this.appservice.getToken(uid).subscribe((results) => {
      // localStorage.setItem("token", results['secretToken'])
      setTimeout(() => {
        console.log('send email otp start v2');
        this.http
          .get(`${environment.PLATFORM_API_URL}/api/v1/auth/user/`)
          .subscribe((data: any) => {
            console.log('send email otp resend');
            this.http
              .post(
                `${environment.PLATFORM_API_URL}/api/v1/signup/resend-email/`,
                {
                  email: data['email'],
                }
              )
              .subscribe((response: any) => {
                console.log('send email otp response', response);
              });
          });
      }, 2000);

      // })
    }
  }

  setFormData(data: any) {
    this.formData = { ...this.formData, ...data };
  }

  getFormData() {
    return this.formData;
  }
  getMerchantIdString(): string | null {
    // const storeDataJSON = localStorage.getItem('merchant-store');
    // const storeData = storeDataJSON && JSON.parse(storeDataJSON);
    // const merchantId = storeData.pk.toString();
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    console.log(merchantId);

    if (merchantId && typeof merchantId === 'string') {
      return merchantId;
    } else {
      return null;
    }
  }

  updateStoreLogoURL(imageUrl: string) {
    this.storeLogoImg.next(imageUrl);
  }

  getMerchantDetailsV2() {
    const token = localStorage.getItem('token');
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.tokenAuth
    });

    console.log('VD Token', token);
    console.log('VD merchantId', merchantId);
    console.log('VD headers', headers);
    console.log(
      'VD url',
      `${environment.PLATFORM_API_URL}/api/v1/merchant-info/${merchantId}/`
    );

    return this.http.get<any>(
      `${environment.PLATFORM_API_URL}/api/v1/merchant-info/${merchantId}/`,
      { headers: headers }
    );
  }

  getStoreCategoriesV2(storeType: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${environment.PLATFORM_API_URL}/api/v1/store-type/${storeType}/store-category`,
      {
        headers: headers,
      }
    );
  }

  getShopDescriptionV2() {
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${environment.MERCHANT_EXTRAS}/extrasapi/shopdescription/${merchantId}`, // url
      {
        // options
        headers: headers,
      }
    );
  }

  getShopBusinessHoursV2() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization' : this.tokenAuth
    });

    return this.http.get<any>(
      `${environment.PLATFORM_API_URL}/api/v1/business-hours/`,
      {
        headers: headers,
      }
    );
  }

  getShopBusinessHoursV3() {
    const rawToken = this.authservice.getToken();

    if (!rawToken) {
      console.error('Authorization token is missing or invalid.');
      return of(null); // Return an empty observable or handle appropriately
    }

    const token = rawToken.startsWith('Token ')
      ? rawToken
      : `Token ${rawToken}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token,
    });

    return this.http.get<any>(
      `${environment.PLATFORM_API_URL}/api/v1/business-hours/`,
      { headers: headers }
    );
  }

  getMerchantDetails() {
    // const storeDataJSON = localStorage.getItem('merchant-store');
    // console.log('qwerty', storeDataJSON)
    // const storeData = storeDataJSON && JSON.parse(storeDataJSON);
    // const merchantId = storeData.pk.toString();
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.tokenAuth
    });

    this.http
      .get<any>(
        `${environment.PLATFORM_API_URL}/api/v1/merchant-info/${merchantId}/`,
        { headers: headers }
      )
      .subscribe(
        async (response) => {
          this.merchantData = response;
          console.log('merchant-info', this.merchantData);
          await this.getStoreCategories(this.merchantData.store_type.id);
          await this.getShopDescription();
          await this.getShopBusinessHours();
        },
        (error) => {
          console.error('merchant details, error:', error);
        }
      );
  }

  // get lat long
  getLatLong(): { lat: number; lng: number } {
    const loc = localStorage.getItem('loc');
    if (loc) {
      return JSON.parse(loc);
    }
    throw new Error('Lat long not found');
  }

  // get store data
  getStoreData(dbKey: MerchantDataKey): any | null {
    const storeDataJSON = localStorage.getItem(dbKey);
    const storeData = storeDataJSON && JSON.parse(storeDataJSON);

    return storeData == null ? null : storeData;
  }

  getEmailAddress(user_id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const payload = {
      user_id: user_id,
    };
    return this.http.post<any>(
      `${environment.MERCHANT_EXTRAS}/onboarding/get_email_address`,
      payload,
      { headers: headers }
    );
  }

  // send email otp
  // sendEmailOTP(email_address: string) {
  //   const merchantEmail = this.getStoreData('merchant-user-info').email;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     // 'authorization': this.tokenAuth
  //   });
  //   const body = {
  //     email: email_address
  //   };

  //   this.http.post<any>(
  //     `${environment.PLATFORM_API_URL}/api/v1/signup/resend-email/`,
  //     body,
  //     {
  //       headers: headers,
  //     }
  //   )
  //   .subscribe(
  //     (response) => {
  //       console.log('starter integration: shop description', response)
  //      this.shopDescription = response.shopdescription
  //      console.log('starter integration: shop description', this.shopDescription)
  //     },
  //     (error) => {
  //       console.error('shop description, error:', error);
  //     }
  //   );
  // }

  // get shop description
  async getShopDescription(): Promise<void> {
    // const storeDataJSON = localStorage.getItem('merchant-store');
    // const storeData = storeDataJSON && JSON.parse(storeDataJSON);
    // const merchantId = storeData.pk.toString();
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .get<any>(
        `${environment.MERCHANT_EXTRAS}/extrasapi/shopdescription/${merchantId}`, // url
        {
          // options
          headers: headers,
        }
      )
      .subscribe(
        (response) => {
          console.log('starter integration: shop description', response);
          this.shopDescription = response.shopdescription;
          console.log(
            'starter integration: shop description',
            this.shopDescription
          );
        },
        (error) => {
          console.error('shop description, error:', error);
        }
      );
  }

  async getShopBusinessHours(): Promise<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization' : this.tokenAuth
    });

    this.http
      .get<any>(`${environment.PLATFORM_API_URL}/api/v1/business-hours/`, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          console.log('starter integration: shop businesshours', response);
          this.shopBusinessHours = response;
        },
        (error) => {
          console.error('shop description, error:', error);
        }
      );
  }

  // update store description
  async updateDescription(value: string) {
    const merchantId = parseInt(localStorage.getItem('user_id')!);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body = {
      shopdescription: value,
    };

    return await lastValueFrom(
      this.http.post<any>(
        `${environment.MERCHANT_EXTRAS}/extrasapi/shopdescription/${merchantId}/update`, // url
        body,
        { headers: headers }
      )
    ).then(
      (response) => {
        console.log('starter integration: shop description', response);
        this.shopDescription = response.shopdescription;
        console.log(
          'starter integration: shop description',
          this.shopDescription
        );
        return true;
      },
      (error) => {
        console.error('shop description, error:', error);
        return false;
      }
    );
  }

  /**
   * update firstname and lastname of merchant
   * token authorization is the identifier
   * @param first_name, last_name
   * @returns
   */
  updateMerchantDetails(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/profile/`,
      payload,
      {
        headers: headers,
      }
    );
    return response;
  }

  // update business hours
  async updateVacationMode(storeId: string, value: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body = {
      is_vacation: value,
    };

    return await lastValueFrom(
      this.http.put<any>(
        `${environment.PLATFORM_API_URL}/api/v1/apps/webstore/setting/${storeId}/update/`,
        body,
        {
          headers: headers,
        }
      )
    ).then(
      (response) => {
        console.log('starter integration', response);
        this.shopDescription = response.shopdescription;
        console.log('starter integration', this.shopDescription);
        return true;
      },
      (error) => {
        console.error('shop description, error:', error);
        return false;
      }
    );
  }

  // get store type
  getStoreCategories(storeType: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let response = this.http.get<any>(
      `${environment.PLATFORM_API_URL}/api/v1/store-type/${storeType}/store-category`,
      {
        headers: headers,
      }
    );

    return response;
  }

  // update shop description
  updateStoreDescription(storeDescription: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/profile/`,
      {
        shopDescription: storeDescription,
      },
      {
        headers: headers,
      }
    );
    return response;
  }
  // verify otp
  verifyOtp(otp: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/signup/verify-email-code/`,
      {
        code: otp,
      },
      {
        headers: headers,
      }
    );
    return response;
  }

  // update bank account details
  updateBankAccountDetails(payload: any): Observable<any> {
    // const storeDataJSON = localStorage.getItem('merchant-store');
    // const storeData = storeDataJSON && JSON.parse(storeDataJSON);
    // const merchantId = storeData.pk.toString();
    const merchantId = parseInt(localStorage.getItem('user_id')!);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });
    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/merchant-info/${merchantId}/`,
      payload,
      {
        headers: headers,
      }
    );

    return response;
  }

  // update store details
  updateStoreDetails(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    let response = this.http.post<any>(
      `${environment.PLATFORM_API_URL}/api/v1/store/`,
      data,
      {
        headers: headers,
      }
    );
    return response;
  }

  // save business hours
  saveBusinessHours(data: any): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    this.http
      .post<any>(`${environment.PLATFORM_API_URL}/api/v1/store/`, data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          console.log('saveBusinessHours', response);
        },
        (error) => {
          console.error('saveBusinessHours', error);
        }
      );
  }

  // update business hours
  updateBusinessHours(data: any): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });

    this.http
      .post<any>(`${environment.PLATFORM_API_URL}/api/v1/store/`, data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          console.log('updateBusinessHours', response);
        },
        (error) => {
          console.error('updateBusinessHours', error);
        }
      );
  }

  // set token
  async setToken(): Promise<void> {
    const storeDataJSON = localStorage.getItem('token');
    this.tokenAuth = 'Token ' + storeDataJSON;
    console.log('tokenAuth was set', this.tokenAuth);
  }

  // save address
  async updateAddress(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'authorization': this.tokenAuth
    });
    return await lastValueFrom(
      this.http.post<any>(
        `${environment.PLATFORM_API_URL}/api/v1/shipping-address/`,
        data,
        {
          headers: headers,
        }
      )
    ).then(
      (response) => {
        console.log('updateAddress', response);
        return true;
      },
      (error) => {
        console.error('updateAddress', error);
        return false;
      }
    );
  }

  // set checkPointIndex
  setCheckPointIndex(index: number): void {
    const userId = localStorage.getItem('userId');
    if (userId) {
      console.log(userId);
      const checkPointIndex = `${index.toString()}_${userId}`;
      localStorage.setItem('checkPointIndex', checkPointIndex);
    }
  }

  // update store logo
  async updateStoreLogo(data: any) {
    const merchantId = localStorage.getItem('user_id')!;

    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    await lastValueFrom(
      this.http.post<any>(
        `${environment.PLATFORM_API_URL}/api/v1/merchant-shop-image/${merchantId}/`,
        data
      )
    ).then(
      (response) => {
        console.log('updateStoreLogo', response);
        return true;
      },
      (error) => {
        console.error('updateStoreLogo', error);
        return false;
      }
    );
  }

  // get
  getCheckPointIndex(): number {
    const checkPointIndex = localStorage.getItem('checkPointIndex');
    if (checkPointIndex) {
      const index = checkPointIndex.split('_')[0];
      console.log('IC Index', index);
      return parseInt(index);
    }
    return 0;
  }
}
