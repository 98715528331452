<div class="container">
  <div class="content">
    <!-- <div class="section tw-mb-4">
      <h3 class="title">Your Pickup Address*</h3>
      <p class="note">
        Provide the address where your orders will be picked up for delivery
      </p>
    </div> -->

    
    <div class="section">
      <h3 class="title tw-mb-2">{{ 'address-info.delivery-address' | i18next }}</h3>
      <div class="delivery-address-info">
        
        <span>
          {{ addressInput}}
        </span>
        <button
        (click)="onChangeClick()"
        class="tw-font-semibold tw-text-[#000066] tw-underline"
        >
        Change
      </button>
    </div>
      <!-- @if (changeAddress) {
      <app-address-autocomplete
        class="tw-color-white tw-w-full"
        (placeChange)="onPlaceChanged($event)"
        addressType="geocode"
        [address]="address()"
      />} -->
    </div>
    <google-map
      [width]="'100%'"
      [height]="'330px'"
      class="tw-w-full"
      [options]="options"
      (mapInitialized)="onMapInitialized($event)"
      (mapClick)="onMapClick($event)"
    >
      @if (selectedPosition()) {
      <map-marker
        [position]="selectedPosition()!"
        [options]="{ draggable: false }"
        (mapDragend)="onMarkerDrag($event)"
        (mapClick)="onMapClick($event)"
      >
      </map-marker>
      }
    </google-map>
    
    <p class="note">
      You may pinch and swipe to move the map to make your pin location more accurate.
    </p>
  </div>

  <!-- <app-cta-buttons
    currentPage="pickup-address"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="!isConfirmed"
    [runOnNext]="toggleNext.bind(this)"
  ></app-cta-buttons> -->
</div>
