import {
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  runInInjectionContext,
} from '@angular/core';
import { ReactiveDropdownInputComponent } from '../inputs/reactive-dropdown-input/reactive-dropdown-input.component';
import { ReactiveFileInputComponent } from '../inputs/reactive-file-input/reactive-file-input.component';
import { CommonModule } from '@angular/common';
import { idTypes } from 'src/app/pages/advanced/advanced-data';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveDatePickerComponent } from '../inputs/reactive-date-picker/reactive-date-picker.component';
import { AdvancedAddressOption } from 'src/app/services/advanced/schemas/advanced-address-option.schema';
import {
  DocumentUploadForm,
  DocumentUploadFormKeys,
  DocumentUploadFormModel,
} from './models/document-upload-form.model';
import { getErrorMessage } from '../inputs/utils/get-error-message.utils';
import { ValidationMessageFn } from '../inputs/types/validation-message-fn';
import { AdvancedService } from 'src/app/services/advanced/advanced.service';

export type DocumentChangeEvent = {
  idType?: string;
  expiration?: Date;
  file?: File | null;
};

@Component({
  selector: 'app-document-upload',
  standalone: true,
  imports: [
    ReactiveDropdownInputComponent,
    ReactiveFileInputComponent,
    ReactiveFormsModule,
    CommonModule,
    ReactiveDatePickerComponent,
  ],
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  @Input() public shouldUseNativePicker: boolean = false;
  @Input() public id = '';
  @Input() public labelText: string = '';
  @Input() public labelText2: string = 'Back of ID Card';
  @Input() public sampleImages: string[] = [
    '../../../assets/images/sample-ids/drivers-license-front.png',
  ];
  @Input() public withIdDetails: boolean = true;
  @Input() public form: DocumentUploadForm = DocumentUploadFormModel.build();
  @Input() public secondaryForm: DocumentUploadForm =
    DocumentUploadFormModel.build();
  @Input() options: AdvancedAddressOption[] = [];
  @Input() capturecam: string | null = null;
  idCategory: string = '';
  hasBackOfId: boolean = false;
  hasExpiry: boolean = true;

  @Output('documentChange')
  documentChange: EventEmitter<DocumentChangeEvent | undefined> =
    new EventEmitter<DocumentChangeEvent | undefined>();

  fb = inject(FormBuilder);

  injector = inject(Injector);
  private advancedService = inject(AdvancedService);

  public get idTypes() {
    return idTypes;
  }

  public get documentUploadFormKeys() {
    return DocumentUploadFormKeys;
  }

  public errorMessage(key: DocumentUploadFormKeys) {
    const control = this.form.controls[key];
    if (!control) {
      return '';
    }

    return getErrorMessage(control.errors, this.validationMessages);
  }

  public get validationMessages(): Record<string, ValidationMessageFn> {
    return {
      required: () => 'Field is required.',
    };
  }

  ngOnInit(): void {
    if (this.id == 'secondary-id-2') {
      this.sampleImages = ['../../../assets/images/sample-ids/bir-id.png'];
    } else if (this.id == 'secondary-id-1') {
      this.sampleImages = ['../../../assets/images/sample-ids/postal-id.png'];
    }

    runInInjectionContext(this.injector, () => {
      const formValue = toSignal(this.form.valueChanges);

      effect(() => {
        const data = formValue();
        if (!data) {
          return;
        }

        this.documentChange.emit({
          idType: data.idType ?? '',
          expiration: data.expiration ?? new Date(),
          file: data.file,
        });
      });

      this.idCategory = this.advancedService.getIdCategory();
      // Check if the id is "selfie" and set sampleImages accordingly
      if (this.id === 'selfie') {
        this.sampleImages = [
          '../../../assets/images/sample-ids/selfie-sample-pic.png',
        ];
      }

      this.form.controls.idType.valueChanges.subscribe((newValue) => {
        const selectedIdType = this.idTypes.find(
          (idType) => idType.name === newValue
        );
        if (selectedIdType) {
          console.log('IC sdelected id type', selectedIdType);
          this.sampleImages = selectedIdType.sample;
          console.log('Sample Images:', selectedIdType.sample);

          this.hasBackOfId = selectedIdType.hasBackOfId;
          this.hasExpiry = selectedIdType.hasExpiry;
          console.log('ID Cat:', this.idCategory);
          console.log('back:', this.hasBackOfId);
          console.log('exp:', this.hasExpiry);
        } else {
          this.sampleImages = [];
        }
      });
    });
  }

  public get filteredIdTypes() {
    return this.idTypes.filter((idType) => idType.category === this.idCategory);
  }
}
