import {
  Component,
  effect,
  inject,
  Injector,
  OnInit,
  runInInjectionContext,
  signal,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { advancedPages } from '../advanced-data';
import { ReactiveTextField2Component } from 'src/app/components/inputs/reactive-text-field2/reactive-text-field2.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ReactiveDropdownInputComponent } from 'src/app/components/inputs/reactive-dropdown-input/reactive-dropdown-input.component';
import {
  BusinessInfoForm,
  BusinessInfoFormKeys,
  BusinessInfoFormModel,
} from './models/business-info-form.model';
import { AdvancedAddressOption } from 'src/app/services/advanced/schemas/advanced-address-option.schema';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { AdvancedService } from 'src/app/services/advanced/advanced.service';
import { ValidationMessageFn } from 'src/app/components/inputs/types/validation-message-fn';
import { ReactiveNgSelectFieldComponent } from 'src/app/components/inputs/reactive-ng-select-field/reactive-ng-select-field.component';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { StarterFormDataService } from '../../starter/starter-form-data.service';
import { lastValueFrom } from 'rxjs';

interface MerchantDetails {
  business_name: string;
  shipping_address: {
    province: string;
    city: string;
    barangay: string;
    line_1: string;
    postal_code: string;
  };
  business_address: {
    province: string;
    city: string;
    barangay: string;
    line_1: string;
    postal_code: string;
  };
  [key: string]: any; // This allows additional fields
}

interface ShippingAddress {
  pickupaddress: {
    barangay: {
      id: number;
      name: string;
    };
    city: {
      id: number;
      name: string;
    };
    latitude: number;
    line_1: string;
    longitude: number;
    postal_code: string;
    province: {
      id: number;
      name: string;
    };
  };
}

@Component({
  selector: 'app-business-info',
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    CtaButtonsComponent,
    ReactiveTextField2Component,
    ReactiveFormsModule,
    ReactiveDropdownInputComponent,
    ReactiveNgSelectFieldComponent,
  ],
  templateUrl: './business-info.component.html',
  styleUrl: './business-info.component.scss',
})
export class BusinessInfoComponent implements OnInit {
  constructor() {}

  advancedPages: string[] = advancedPages;
  businessInfoForm: BusinessInfoForm = BusinessInfoFormModel.build();
  merchantId: number | null = null;
  shippingaddress: any = null;
  injector = inject(Injector);
  advancedService = inject(AdvancedService);
  province: Signal<AdvancedAddressOption | null | undefined> = signal(null);
  city: Signal<AdvancedAddressOption | null | undefined> = signal(null);

  starterFormDataService = inject(StarterFormDataService);

  provinceQuery = injectQuery(() => ({
    queryKey: ['province'],
    queryFn: () => this.advancedService.getProvinces(),
  }));

  cityQuery = injectQuery(() => ({
    queryKey: ['city'],
    queryFn: () => {
      const province = this.province();
      console.log('provinceQuery', this.province());
      if (!province) {
        return [];
      }
      return this.advancedService.getCities(province.id);
    },
  }));

  barangayQuery = injectQuery(() => ({
    queryKey: ['barangay'],
    queryFn: () => {
      const city = this.city();
      if (!city) {
        return [];
      }
      return this.advancedService.getBarangays(city.id);
    },
  }));

  public get validationMessages(): Record<string, ValidationMessageFn> {
    return {
      required: () => 'Field is required *',
    };
  }

  public get businessInfoFormKeys() {
    return BusinessInfoFormKeys;
  }

  async ngOnInit(): Promise<void> {
    this.businessInfoForm = BusinessInfoFormModel.build();

    runInInjectionContext(this.injector, () => {
      this.city = toSignal(this.businessInfoForm.controls['city'].valueChanges);
      this.province = toSignal(
        this.businessInfoForm.controls['province'].valueChanges
      );
      const isSameAsPickup = toSignal(
        this.businessInfoForm.controls['sameAsPickup'].valueChanges
      );

      effect(
        () => {
          const city = this.city();
          this.businessInfoForm.controls['barangay'].reset();
          this.barangayQuery.refetch();
        },
        {
          allowSignalWrites: true,
        }
      );
      effect(
        () => {
          const province = this.province();
          console.log('Province Changed: ', province);
          this.businessInfoForm.controls['city'].reset();
          this.cityQuery.refetch();
        },
        {
          allowSignalWrites: true,
        }
      );

      effect(
        async () => {
          try {
            if (!isSameAsPickup()) {
              return;
            }

            console.log('isSameAsPickup', isSameAsPickup());
            const merchantDetails = await this.fetchMerchantDetails();

            if (!merchantDetails) {
              return;
            }

            const { shipping_address } = merchantDetails;

            if (!shipping_address) {
              return;
            }

            this.updateAddress(shipping_address);
            this.shippingaddress = shipping_address;
          } catch (error) {
            console.error('[ADVANCED] Error Fetching Data');
          }
        },
        {
          allowSignalWrites: true,
        }
      );
    });

    try {
      const merchantDetails = await this.fetchMerchantDetails();

      if (!merchantDetails) {
        return;
      }

      const { business_address, business_name } = merchantDetails;

      if (business_name) {
        this.businessInfoForm.controls['businessName'].setValue(
          merchantDetails.business_name as string
        );
      }

      if (!business_address) {
        return;
      }

      await this.updateAddress(business_address);
    } catch (error) {
      console.error('[ADVANCED] Error Fetching Data');
    }
  }

  public getFieldError(field: BusinessInfoFormKeys) {
    return this.businessInfoForm.get(field)?.errors;
  }

  private fetchMerchantDetails = async () => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      throw Error('[ADVANCED] User ID is not found in localStorage');
    }

    this.merchantId = parseInt(userId, 10);

    try {
      return (await this.advancedService.getMerchantDetails(
        this.merchantId
      )) as MerchantDetails;
    } catch (error) {
      console.error('[ADVANCED] Error fetching merchant details: ', error);
      return null;
    }
  };

  private updateAddress = async (
    address:
      | MerchantDetails['business_address']
      | MerchantDetails['shipping_address']
  ) => {
    if (!address) {
      return;
    }

    const provinces = await this.advancedService.getProvinces();
    const currentProvince = address.province;
    const province = provinces.find(
      (province) => province.name === currentProvince
    );

    if (province) {
      this.businessInfoForm.controls['province'].setValue(province);

      const cities = await this.advancedService.getCities(province.id);
      const currentCity = address.city;
      const city = cities.find((city) => city.name === currentCity);

      if (city) {
        this.businessInfoForm.controls['city'].setValue(city);
        const barangays = await this.advancedService.getBarangays(city.id);
        const currentBarangay = address.barangay;
        const barangay = barangays.find(
          (barangay) => (barangay.name = currentBarangay)
        );

        if (barangay) {
          this.businessInfoForm.controls['barangay'].setValue(barangay);
        }
      }
    }

    this.businessInfoForm.controls['zipCode'].setValue(address.postal_code);
    this.businessInfoForm.controls['line1'].setValue(address.line_1);
  };

  async handleNextClick() {
    try {
      await this.businessInfoForm.markAllAsTouched();

      if (!this.businessInfoForm.valid) {
        return;
      }

      await this.advancedService.updateBusinessAddress({
        business_name: this.businessInfoForm.value.businessName,
        line_1: this.businessInfoForm.value.line1,
        barangay:
          this.businessInfoForm.controls['sameAsPickup'].value == true
            ? this.shippingaddress['barangay']
            : this.businessInfoForm.value.barangay?.name,
        city:
          this.businessInfoForm.controls['sameAsPickup'].value == true
            ? this.shippingaddress['city']
            : this.businessInfoForm.value.city?.name,
        province:
          this.businessInfoForm.controls['sameAsPickup'].value == true
            ? this.shippingaddress['province']
            : this.businessInfoForm.value.province?.name,
        postal_code: this.businessInfoForm.value.zipCode,
      });
      console.log('handlenextclick', this.businessInfoForm.value);

      await lastValueFrom(this.advancedService.activateNonCOD());

      parent.postMessage(
        JSON.stringify({
          target: '/advanced-manual-success',
        }),
        '*'
      );
    } catch (error) {
      console.error(error);
    }
  }

  onNextPressed = async () => {
    try {
      console.log(this.businessInfoForm);

      console.log('On Next ', this.businessInfoForm.value);

      parent.postMessage(
        JSON.stringify({
          target: '/advanced-manual-success',
        }),
        '*'
      );

      return true;
    } catch (error) {
      return false;
    }
  };
}
