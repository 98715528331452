import {
  Component,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { ReactiveFileInputComponent } from 'src/app/components/inputs/reactive-file-input/reactive-file-input.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { ReactiveImageUploadComponent } from '../../../components/inputs/reactive-image-upload/reactive-image-upload.component';
import { ReactiveTextAreaInputComponent } from '../../../components/inputs/reactive-text-area-input/reactive-textarea-input.component';
import { ReactiveDropdownInputComponent } from '../../../components/inputs/reactive-dropdown-input/reactive-dropdown-input.component';
import { starterPages } from '../starter-data';
import { StarterFormDataService } from '../starter-form-data.service';
import { CommonModule } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-details',
  standalone: true,
  templateUrl: './store-details.component.html',
  styleUrl: './store-details.component.scss',
  imports: [
    ReactiveTextInputComponent,
    ReactiveFormsModule,
    ProgressBarComponent,
    ReactiveFileInputComponent,
    CtaButtonsComponent,
    ReactiveImageUploadComponent,
    ReactiveTextAreaInputComponent,
    ReactiveDropdownInputComponent,
    FormsModule,
    CommonModule,
  ],
})
export class StoreDetailsComponent {
  starterPages: string[] = starterPages;
  loading: boolean = false;
  storeCategories = [
    {
      label: 'Bakery',
      value: 'bakery',
    },
    {
      label: 'Restaurant',
      value: 'restaurant',
    },
    {
      label: 'Toys',
      value: 'toys',
    },
  ];
  formGroup: FormGroup;
  firstName: FormControl = new FormControl();
  lastName: FormControl = new FormControl();
  storeType: string = '';
  storeLogo: string = '';
  storeCategory: FormControl = new FormControl();
  storeDescription: FormControl = new FormControl();
  dropdownControl = new FormControl();
  allFilled: boolean = false;
  shopImageUrl: string = '';
  isEmptyFields: boolean = true;
  userToken: string = '';

  formData: any = {
    firstName: null,
    lastName: null,
    storeType: null,
    storeCategory: null,
    storeLogo: null,
    storeDescription: null,
  };

  constructor(
    private starterService: StarterFormDataService,
    private http: HttpClient,
    private router: Router
  ) {
    this.formGroup = new FormGroup({
      usernameControl: new FormControl('', Validators.required),
    });

    this.firstName.valueChanges.subscribe(() => {
      this.formData.firstName = this.firstName.value;
      this.checkRequiredFields();
    });

    this.lastName.valueChanges.subscribe(() => {
      this.formData.lastName = this.lastName.value;
      this.checkRequiredFields();
    });

    this.storeCategory.valueChanges.subscribe(() => {
      this.formData.storeCategory = this.storeCategory.value;
      this.checkRequiredFields();
    });

    this.storeDescription.valueChanges.subscribe(() => {
      this.formData.storeDescription = this.storeDescription.value;
      this.checkRequiredFields();
      console.log('Form Data:', this.formData);
      this.isEmptyFields = this.checkEmptyFields();
      console.log('The fields are empty: ', this.checkEmptyFields());
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkRequiredFields();

    console.log('Form Datas:', this.formData);
  }

  ngOnInit() {
    this.starterService.setCheckPointIndex(4);
    if (
      typeof this.starterService.merchantData === 'undefined' ||
      typeof this.starterService.shopDescription === 'undefined'
    ) {
      this.loadStoreImage();

      this.starterService.getShopDescriptionV2().subscribe((data: any) => {
        this.starterService.shopDescription = data.shopdescription;
        this.storeDescription.setValue(this.starterService.shopDescription);
      });
    } else {
      this.shopImageUrl = this.starterService.merchantData.shop_image;
      this.storeDescription.setValue(this.starterService.shopDescription);
      console.log('starter integration: shopimage', this.shopImageUrl);
      console.log(
        'starter integration: shopdescription',
        this.starterService.merchantData.shop_description
      );
    }

    // Check if the iframe was closed and the flag is set
    if (localStorage.getItem('iframeClosed') === 'true') {
      // Clear the flag
      localStorage.removeItem('iframeClosed');

      this.reloadData();
    }

    window.addEventListener('message', (event) => {
      if (event.data === 'closeIframe') {
        this.reloadData();
      }
    });

    this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
      this.starterService.merchantData = data;
      this.shopImageUrl = this.starterService.merchantData.shop_image;
    });
  }

  checkRequiredFields() {
    this.allFilled =
      !this.firstName.invalid &&
      !this.lastName.invalid &&
      !this.storeCategory.invalid &&
      !this.storeDescription.invalid;
  }

  checkEmptyFields() {
    const allFieldsEmpty = Object.values(this.formData).every(
      (value) => value === null || value === ''
    );
    return allFieldsEmpty;
  }

  removeImage(): void {
    this.formData.storeLogo = null;
    console.log('Image removed');
  }

  handleNext = async () => {
    this.loading = true;
    this.starterService.setCheckPointIndex(3);
    const saveFormData = new FormData();
    saveFormData.append('shop_image', this.formData.storeLogo);

    try {
      await this.starterService.updateStoreLogo(saveFormData);
      await this.starterService.updateDescription(this.storeDescription.value);
      await this.loadStoreImage();
      this.loading = false;
      return true;
    } catch (error) {
      console.error('Error:', error);
      this.loading = false;
      return false;
    }
  };

  async loadStoreImage() {
    return await lastValueFrom(this.starterService.getMerchantDetailsV2()).then(
      (data: any) => {
        this.starterService.merchantData = data;
        this.shopImageUrl = this.starterService.merchantData.shop_image;
        return true;
      }
    );
  }

  isImageExisting(): boolean {
    return this.shopImageUrl !== '';
  }

  openAILink(intent: string, promptValue?: string) {
    const baseurl = environment.ENSTACK_AI_URL;

    this.http
      .get(`${baseurl}/aiapi/v1/generatetoken`)
      .toPromise()
      .then((response: any) => {
        console.log('generate', response.token, intent);

        // Navigate to the iframe route with intent and token as parameters
        this.router.navigate(['/iframe', intent, response.token]);
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  }

  reloadData() {
    // Fetch the latest store description and update the form control
    this.starterService.getShopDescriptionV2().subscribe((data: any) => {
      this.starterService.shopDescription = data.shopdescription;
      this.storeDescription.setValue(this.starterService.shopDescription);
    });

    // Fetch the latest store logo and update the image URL
    this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
      this.starterService.merchantData = data;
      this.shopImageUrl = this.starterService.merchantData.shop_image;
    });
  }
}
