import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { ReactiveFileInputComponent } from 'src/app/components/inputs/reactive-file-input/reactive-file-input.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CardChallengeComponent } from './card-challenge/card-challenge.component';
import { VerifiedService } from './verified.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-verified',
    standalone: true,
    templateUrl: './verified.component.html',
    styleUrl: './verified.component.scss',
    imports: [
        ReactiveFileInputComponent,
        ReactiveFormsModule,
        // ProgressBarComponent,
        CtaButtonsComponent,
        RouterModule,
        CardChallengeComponent,
        CommonModule
    ]
})
export class VerifiedComponent {
  pages: string[] = [];
  count: number = 0;
  router: any;
  user_id: number = parseInt(localStorage.getItem("user_id")!)||0
  totalProduct: number = 0
  totalOrders: number = 0
  totalBanner: number = 0
  isVerified: boolean = false
  productEnable: boolean = false
  orderEnable: boolean = false
  bannerEnable: boolean = false
  loaded: boolean = false

  constructor(private verifiedService: VerifiedService, private activatedroute:ActivatedRoute) {
    this.pages = ['business-entity', 'upload-document'];
  }

  ngOnInit(): void {
    // const pathSegments = this.router.url.split('/');
    // const endPath = pathSegments[pathSegments.length - 1];
    // this.count = this.pages.indexOf(endPath);
    this.activatedroute.queryParams.subscribe((params:any)=>{
      if(("user" in params)){
        this.user_id = params["user"]
        this.verifiedService.getTotalProduct(this.user_id).subscribe(
          (results) => {
            console.log(results)
            this.totalProduct = results.product_count
            
            this.verifiedService.getTotalOrders(this.user_id).subscribe(
              (results) => {
                console.log(results)
                this.totalOrders = results.order_count
                
                this.verifiedService.getTotalBanner(this.user_id).subscribe(
                  (results) => {
                    console.log(results)
                    this.totalBanner = results.banner_count
                    this.loaded = true
                  },
                  (error) => {
                    console.log(error)
                  }
                )
              },
              (error) => {
                console.log(error)
              }
            )
          },
          (error) => {
            console.log(error)
          }
        )

      }
    })

  }

  receiveCount($event: number) {
    this.count = $event;

    this.router.navigateByUrl(`/advanced/${this.pages[this.count]}`);
  }
  
  canClaim(): boolean{
    const isProduct: boolean = this.totalProduct >= 3
    const isOrder: boolean = this.totalOrders >= 5
    const isBanner: boolean = this.totalBanner >= 1

    return isProduct && isOrder && isBanner
  }
  
  claimVerify(){

    this.verifiedService.activatePoB(this.user_id).subscribe((data:any) => {
      console.log("activated")
      this.verifiedService.setVerify(this.user_id).subscribe(
        (result) => {
          if (result.success){
  
            this.verifiedService.activateVerify().subscribe((data) => {
              console.log("hi u passed")
  
  
              parent.postMessage(
                JSON.stringify({
                  "target": "/verified-success"
                }), "*"
              )          
            })
          }
        }
      )
    })

  }
}
