<div class="time-picker-container">
  <div class="start-time time">
    <div class="time-input-wrapper">
      <input
        matInput
        [ngxMatTimepicker]="startTimePicker"
        placeholder="Start Time"
        [formControl]="startTime"
      />
      <ngx-mat-timepicker #startTimePicker></ngx-mat-timepicker>
    </div>
  </div>

  <div class="end-time time">
    <div class="time-input-wrapper">
      <input
        matInput
        [ngxMatTimepicker]="endTimePicker"
        placeholder="End Time"
        [formControl]="endTime"
      />
      <ngx-mat-timepicker #endTimePicker></ngx-mat-timepicker>
    </div>
  </div>
</div>
