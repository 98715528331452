import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-reactive-text-field2',
  templateUrl: './reactive-text-field2.component.html',
  styleUrls: ['./reactive-text-field2.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class ReactiveTextField2Component implements OnInit, OnChanges {
  showPassword: boolean = false;
  @Input() inputType: 'text' | 'number' | 'email' | 'password' = 'text';
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() id: string = '';
  @Input() control: FormControl = new FormControl('');
  @Input() placeholder: string = '';
  @Input() required: boolean = false; // Add required attribute
  @Input() errorMessage: string = 'Something is wrong!';
  @Input() hasError: boolean = false;
  @Input() errorType: string = '';
  @Input() socialmedia: string = ''; // Add input for social media type
  @Input() reference: string = '';

  constructor() {}

  switchShowPasswordState() {
    this.showPassword = !this.showPassword;

    this.showPassword
      ? (this.inputType = 'text')
      : (this.inputType = 'password');
  }

  ngOnInit() {}

  ngOnChanges(): void {
    // Set up validators based on user input
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.type === 'email') {
      validators.push(Validators.email);
    }
    if (this.type === 'text') {
      validators.push(this.customTextValidator());
    }
    if (this.type === 'url') {
      validators.push(this.urlValidator(this.socialmedia));
    }
    if (this.type === 'invoiceId') {
      validators.push(this.invoiceIdValidator());
    }
    if (this.reference === 'letters-only') {
      validators.push(this.lettersOnlyValidator());
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity(); // Trigger validation
  }

  private customTextValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return null;
    };
  }

  private urlValidator(socialmedia: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.toLowerCase();
      if (!value) {
        return null;
      }

      const socialMediaPatterns: { [key: string]: RegExp } = {
        facebook: /fb\.com|facebook\.com|fb\.watch/,
        twitter: /twitter\.com|x\.com/,
        tiktok: /tiktok\.com/,
        instagram: /instagram\.com/,
        youtube: /youtube\.com/,
        facebookLive: /fb\.com|facebook\.com|fb\.watch/,
      };

      if (socialmedia && socialMediaPatterns[socialmedia]) {
        if (!socialMediaPatterns[socialmedia].test(value)) {
          return { invalidURL: true };
        }
      } else {
        return { invalidURL: true };
      }

      return null;
    };
  }

  private invoiceIdValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!(value && /\d$/.test(value))) {
        return { invalidInvoiceId: true };
      }
      return null;
    };
  }

  private lettersOnlyValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && !/^[a-zA-Z\s]*$/.test(value)) {
        return { lettersOnly: true };
      }
      return null;
    };
  }
}
