import { CommonModule, Location } from '@angular/common';
import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { starterPages } from 'src/app/pages/starter/starter-data';
import { CtaButtonAddDataPayload } from './types/cta-add-data-payload.type';
import { StarterFormDataService } from 'src/app/pages/starter/starter-form-data.service';
import { AdvancedService } from 'src/app/services/advanced/advanced.service';

@Component({
  selector: 'app-cta-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cta-buttons.component.html',
  styleUrls: ['./cta-buttons.component.scss'],
})
export class CtaButtonsComponent {
  public location = inject(Location);
  @Output() countEvent = new EventEmitter<number>();
  @Output() nextEvent = new EventEmitter<void>(); // New event emitter for next button click
  @Input() disabled: boolean = false;
  @Input() data: any;
  @Input() currentPage: string = '';
  @Input() tier: string = '';
  @Input() pageNames: string[] = [];
  pages: number = starterPages.length;
  count: number = 1;
  @Input() backButton: boolean = true;
  @Input() nextButton: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() runOnNext: () => void = () => { };
  /**
   *
   * @returns Promise<boolean> - if the next button should be enabled or not
   */
  @Input() nextCallback: () => Promise<boolean> = async () => true;
  @Input() backCallback: () => Promise<boolean> = async () => true;

  @Input() sample: any;
  @Input() isSuccess: boolean = false;
  @Input() isAllowedToNext: boolean = true;
  @Input() isEmptyFields: boolean = false;
  @Input() runblock: () => Promise<boolean> = async () => false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private starterService: StarterFormDataService,
    private advancedService: AdvancedService
  ) { }

  param1: string | null = null;
  param2: string | null = null;

  ngOnInit(): void {
    this.count = this.starterService.getCheckPointIndex();
    this.route.queryParamMap.subscribe((params) => {
      // Extract the desired query parameters
      const pages = params.get('pages');
      const count = params.get('count');

      if (pages != null) {
        this.pages = parseInt(pages);
      } else {
        this.pages = this.pageNames.length;
      }
      if (count != null) {
        this.count = parseInt(count);
      } else {
        this.count = 0;
      }

      // Do something with the retrieved query parameters
      console.log('Pages:', pages);
      console.log('Pages:', count);
    });
  }

  async nextCount() {
    const shouldNext = await this.nextCallback();
    console.log('shouldNext', shouldNext)
    if (!shouldNext) {
      return;
    }
    this.nextEvent.emit(); // Emit the nextEvent
    console.log('posting from iframe');
    console.log('tier', this.tier);
    // window.opener.postMessage("Hello from next iframe")
    console.log('nextEvent: isSuccess', this.isSuccess);
    console.log('IC 01');
    // Run the input function
    if (this.runOnNext) {
      this.runOnNext();
    }

    if (this.runblock) {
      let xx = this.runblock().then((data) => {
        console.log('runblock response', data);
      });
    }

    console.log('IC 02');
    const queryParams = {
      pages: this.pages,
      count: this.count + 1,
    };

    if (queryParams.count < this.pages) {
      this.router.navigate(
        [`/${this.tier}/${this.pageNames[queryParams.count]}`],
        {
          queryParams,
        }
      );
    } else if (this.tier === 'starter') {
      this.starterService.activateStandardShipping().subscribe((data1: any) => {
        console.log('activate standard shipping', data1);
        this.starterService
          .activateOndemandShipping()
          .subscribe((data2: any) => {
            console.log('activate on demand shipping', data1);
            parent.postMessage(
              JSON.stringify({
                target: '/starter-success',
                store_name: localStorage.getItem('store_name'),
              }),
              '*'
            );
            console.log('starter success');
          });
      });

      // } else if (this.tier === 'advanced') {
      //   parent.postMessage(
      //     JSON.stringify({
      //       "target": "/advanced-success"
      //     }), "*"
      //   )
      //   console.log('advanced success')
    } else if (this.tier === 'advanced') {
      parent.postMessage(
        JSON.stringify({
          target: '/advanced-manual-success',
        }),
        '*'
      );
      console.log('advanced manual success');
    } else {
      parent.postMessage(
        JSON.stringify({
          target: '/verified-success',
        }),
        '*'
      );
      console.log('verified success');
    }
    // Remove or define the addData method if needed
    // this.addData();
  }

  async previousCount() {
    const result = await this.backCallback();

    if (!result) {
      return;
    }

    const queryParams = {
      pages: this.pages,
      count: this.count - 1,
    };

    try {
      if (this.tier == 'advanced') {
        (queryParams as any)['type'] = this.advancedService.getIdCategory();
      }
    } catch (ex) {
      console.log('catch exception in advanced back', ex);
    }
    if (this.count > 0) {
      this.router.navigate(
        [`/${this.tier}/${this.pageNames[queryParams.count]}`],
        {
          queryParams,
        }
      );
    } else {
      this.location.back();
    }
  }
}
