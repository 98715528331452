import { Component, OnInit } from '@angular/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { starterPages } from '../starter-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StarterFormDataService } from '../starter-form-data.service';
import { ReactiveNgSelectFieldComponent } from 'src/app/components/inputs/reactive-ng-select-field/reactive-ng-select-field.component';
import { bankOptions } from '../starter-data';
import { BankOption } from '../starter-data';
import { lastValueFrom } from 'rxjs';
import { SharedModule } from 'src/app/shared.module';
import { NavigationEnd, Router } from '@angular/router';
import { ValidationMessageFn } from 'src/app/components/inputs/types/validation-message-fn';

@Component({
  selector: 'app-account-details',
  standalone: true,
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  imports: [
    ReactiveTextInputComponent,
    CtaButtonsComponent,
    ReactiveNgSelectFieldComponent,
    SharedModule,
  ],
})
export class AccountDetailsComponent implements OnInit {
  starterPages: string[] = starterPages;
  bankOptions: BankOption[] = bankOptions;
  bankNames: string[] = [];
  bankName: FormControl = new FormControl(null, Validators.required);
  accountName: FormControl = new FormControl();
  accountNumber: FormControl = new FormControl();
  minNum: number = 10; // Default minNum
  maxNum: number = 12; // Default maxNum
  isAllFilled = false;

  formData: any = {
    bankName: null,
    accountName: null,
    accountNumber: null,
  };
  formGroup: FormGroup;

  constructor(private starterService: StarterFormDataService, router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log('navigated to ', val);
        this.fetchMerchantData();
      }
    });

    this.formGroup = new FormGroup({
      bankName: this.bankName,
      accountName: this.accountName,
      accountNumber: this.accountNumber,
    });

    this.bankName.valueChanges.subscribe((value) => {
      this.formData.bankName = value;
      this.updateBankDetails(value);
      this.checkRequiredFields();
    });

    this.accountName.valueChanges.subscribe(() => {
      this.formData.accountName = this.accountName.value;
      this.checkRequiredFields();
    });

    this.accountNumber.valueChanges.subscribe(() => {
      this.formData.accountNumber = this.accountNumber.value;
      this.checkRequiredFields();
    });
  }

  checkRequiredFields() {
    this.isAllFilled =
      this.bankName.valid &&
      this.bankName.touched &&
      this.accountName.valid &&
      this.accountNumber.valid;
  }

  ngOnInit() {
    this.starterService.setCheckPointIndex(3);
    this.bankNames = this.bankOptions.map((bank) => bank.name);
    this.fetchMerchantData();

    console.log('account name value', this.accountName.value);
  }

  fetchMerchantData() {
    if (!this.starterService.merchantData) {
      this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
        this.starterService.merchantData = data;
        this.populateForm();
      });
    } else {
      this.populateForm();
    }
  }

  populateForm() {
    const merchantData = this.starterService.merchantData;
    if (!merchantData) return;

    const fullName = `${merchantData.first_name} ${merchantData.last_name}`;

    this.bankName.setValue(
      this.starterService.merchantData.bank_details?.bank_name || ''
    );

    this.accountName.setValue(fullName);

    this.accountNumber.setValue(
      this.starterService.merchantData.bank_details.account_number
    );
  }

  // check if form is valid
  checkIfFormIsValid() {
    this.isAllFilled = this.formGroup.valid;
  }

  public getFieldError(field: string) {
    return this.formGroup.get(field)?.errors;
  }

  public get validationMessages(): Record<string, ValidationMessageFn> {
    return {
      required: () => 'Field is required *',
    };
  }

  public getFormControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }

  updateBankDetails(bankName: string) {
    const selectedBank = this.bankOptions.find(
      (bank) => bank.name === bankName
    );
    this.minNum = selectedBank?.minNum ?? 10;
    this.maxNum = selectedBank?.maxNum ?? 12;
  }

  handleNext = async () => {
    var merchantData = this.starterService.merchantData;
    merchantData.bank_details.bank_name =
      this.bankName.value == 'Maya' ? 'PayMaya' : this.bankName.value;
    merchantData.bank_details.account_number = this.accountNumber.value;
    merchantData.bank_details.account_name = this.accountName.value;
    merchantData.account_number = this.accountNumber.value;
    merchantData.bank_name =
      this.bankName.value == 'Maya' ? 'PayMaya' : this.bankName.value;
    merchantData.account_name = this.accountName.value;
    console.log("bank account", merchantData)
    await lastValueFrom(this.starterService.updateBankAccountDetails(merchantData));
    return lastValueFrom(this.starterService.updateMerchantDetails(merchantData));
    // process.env["START_SUCCESS_URL"]
  };
}
