import { Component, Output, EventEmitter, Input } from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'reactive-slide-toggle',
  templateUrl: 'reactive-slide-toggle.component.html',
  styleUrls: ['./reactive-slide-toggle.component.scss'],
  standalone: true,
  imports: [MatSlideToggleModule],
})
export class ReactiveSlideToggleComponent {
    @Input() isChecked: boolean = false
    @Input() toggleOffDisabled: boolean = false
    @Input() isDisabled: boolean = false
    @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>()

    handleToggleChange(event: any) {
        this.toggleChanged.emit(event.checked)
    }
}