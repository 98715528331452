import { Component } from '@angular/core';

@Component({
  selector: 'app-verified-header',
  standalone: true,
  imports: [],
  templateUrl: './verified-header.component.html',
})
export class VerifiedHeaderComponent {

}
