import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let token = localStorage.getItem('token');

    console.log('VD token interceptor', token);
    if (token) {
      const clonedRequest = req.clone({
        headers: req.headers.append('Authorization', `Token ${token}`),
      });
      return next.handle(clonedRequest);
    }
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}
