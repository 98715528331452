/**
 * Convert base64 to blob
 * @param base64  base64 string
 * @param mime mime type
 * @returns
 */
export function base64ToBlob(base64: string, mime: string) {
  const byteString = atob(base64.split(',')[1]); // Decode base64 string
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mime });
}
