import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iframe',
  standalone: true,
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit, OnDestroy {
  iframeUrl: SafeResourceUrl = '';
  private routerSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const baseurl = environment.ENSTACK_AI_URL;
      const intent = params['intent'];
      const token = params['token'];
      const userId = localStorage.getItem('userId');
      let url = '';

      if (intent === 'shopdescription') {
        url = `${baseurl}/description-generator?tid=${token}&uid=${userId}`;
      } else if (intent === 'shoplogo') {
        url = `${baseurl}?intent=${intent}&tid=${token}&uid=${userId}`;
      } else if (intent.startsWith('productdescription')) {
        const metadata = ''; // Define metadata here if needed
        url = `${baseurl}/description-generator?intent=${intent}&tid=${token}&uid=${userId}&metadata=${metadata}`;
      }

      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });

    // Listen for the navigation start event
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          // Set a flag in localStorage
          localStorage.setItem('iframeClosed', 'true');

          // Alternatively, you can update the query parameters if needed
          // this.router.navigate([], {
          //   queryParams: { iframeClosed: 'true' },
          //   queryParamsHandling: 'merge',
          // });

          // Notify the parent window to reload when back navigation is triggered
          window.parent.postMessage('closeIframe', '*');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
