import { Component } from '@angular/core';

@Component({
  selector: 'app-starter-header',
  standalone: true,
  imports: [],
  templateUrl: './starter-header.component.html',
})
export class StarterHeaderComponent {

}
