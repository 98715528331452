import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFileInputComponent } from './components/inputs/reactive-file-input/reactive-file-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReactiveTextInputComponent } from './components/inputs/reactive-text-input/reactive-text-input.component';
import { ErrorStateMatcher } from '@angular/material/core';

import { HeaderComponent } from './components/header/header.component';
import { StarterComponent } from './pages/starter/starter.component';
import { AdvancedComponent } from './pages/advanced/advanced.component';
import { VerifiedComponent } from './pages/verified/verified.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CtaButtonsComponent } from './components/cta-buttons/cta-buttons.component';
import { EmailVerificationComponent } from './pages/starter/email-verification/email-verification.component';
import { BusinessHoursComponent } from './pages/starter/business-hours/business-hours.component';
import { StoreOwnerComponent } from './pages/starter/store-owner/store-owner.component';
import { StoreTypeComponent } from './pages/starter/store-type/store-type.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
} from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { NgSelectModule } from '@ng-select/ng-select';
import { TouchedErrorStateMatcher } from './matchers/touched-error-state.matcher';
import { AddHeaderInterceptor } from './interceptor/httpheaderinterceptor';
import { CropperDialogComponent } from './components/cropper-dialog/cropper-dialog.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DatadogService } from './services/datadog.service';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './i18n';

@NgModule({
  declarations: [AppComponent, CropperDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFileInputComponent,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    NgSelectModule,
    StarterComponent,
    VerifiedComponent,
    ReactiveFormsModule,
    AdvancedComponent,
    ReactiveTextInputComponent,
    CommonModule,
    ProgressBarComponent,
    EmailVerificationComponent,
    CtaButtonsComponent,
    BusinessHoursComponent,
    StoreOwnerComponent,
    StoreTypeComponent,
    HttpClientModule,
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    ImageCropperComponent,
    MatButtonModule,
    MatDialogModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    provideHttpClient(),
    provideAngularQuery(new QueryClient()),
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher },
    DatadogService,
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private datadogService: DatadogService) {
    this.datadogService.configureDatadog();
  }
}
