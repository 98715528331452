<div class="main-container">
  <div class="text-input" [class.invalid]="control.invalid && control.touched">
    <div class="form-floating no-border">
      <input
        [type]="inputType"
        class="form-control"
        [id]="id"
        [placeholder]="placeholder"
        [formControl]="control"
        [class.invalid]="control.invalid && control.touched"
      />
      <label for="floatingPassword"
        >{{ label }}<span *ngIf="required">*</span></label
      >
    </div>
  </div>

  <div *ngIf="control.invalid && control.touched" class="error-message">
    <div *ngIf="control.errors?.['required']">Field is required *</div>
    <div *ngIf="control.errors?.['email']">
      Please enter a valid email address
    </div>
    <div *ngIf="control.errors?.['invalidText']">
      Name can only contain letters and spaces
    </div>
    <div *ngIf="control.errors?.['invalidURL']">
      Please enter a valid {{ socialmedia }} link
    </div>
    <div *ngIf="control.errors?.['invalidInvoiceId']">
      Invoice number sequence must end with a number.
    </div>
    <div *ngIf="control.errors?.['lettersOnly']">
      Input can only contain letters and spaces
    </div>
    <div *ngIf="control.errors?.['other']">{{ errorMessage }}</div>

    <span class="error-message" *ngIf="errorType == 'mismatch'"
      >The mobile number and/or password is incorrect</span
    >
  </div>
</div>
