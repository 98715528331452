<div class="container">
  <button *ngIf="backButton" class="left-cta" (click)="previousCount()">
    <span>Back</span>
  </button>
  <button
    *ngIf="nextButton"
    class="right-cta"
    (click)="nextCount()"
    [disabled]="disabled || isLoading"
    [class.disabled]="disabled || isLoading"
  >
    @if (isEmptyFields) {
    <span>Skip for Now</span>
    } @else if (isLoading) {
    <span>Loading...</span>
    } @else {
    <span>Next</span>
    }
  </button>
</div>
