import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatTimepickerModule,
    CommonModule,
  ],
})
export class TimePickerComponent implements OnInit {
  @Input() startTime = new FormControl('9:00 AM');
  @Input() endTime = new FormControl('6:00 PM');
  @Input() day: string = '';
  @Output() timeChanged = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];

  constructor() {}

  ngOnInit() {
    // Subscribe to startTime and endTime changes
    this.subscriptions.push(
      this.startTime.valueChanges.subscribe(() => this.onTimeChange()),
      this.endTime.valueChanges.subscribe(() => this.onTimeChange())
    );
  }

  onTimeChange() {
    this.timeChanged.emit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
