<div class="container">
  <div class="page-info">
    <h3 class="title">
      <!-- Upload a Selfie with your ID -->
      {{ 'advanced.upload-selfie' | i18next }}
      <span class="tw-text-[#9FE9FE]">*</span>
    </h3>
    <p class="note tw-my-[12px]">
      <!-- Make sure you are holding the ID you've submitted. -->
      {{ 'advanced.uploadselfie-note' | i18next }}
    </p>

    <ol class="tw-text-[14px] tw-text-white tw-list-decimal tw-pl-6 tw-font-normal tw-mb-[12px]">
      <li>{{ 'advanced.uploadselfie-guide-1' | i18next }}</li>
      <li>{{ 'advanced.uploadselfie-guide-2' | i18next }}</li>
      <li>{{ 'advanced.uploadselfie-guide-3' | i18next }}</li>
    </ol>
    <p class="tw-text-[14px] tw-text-white tw-font-normal">
      {{ 'advanced.uploadselfie-guide-4' | i18next }}
    </p>
  </div>

  <!-- <app-document-upload
    sampleImageUrl="../../../../assets/images/sample-ids/selfie-sample-pic.png"
    [withIdDetails]="false"
    (documentChange)="onDocumentChange($event)"
    [form]="form"
    id="selfie"
  /> -->

  <app-document-upload
    sampleImageUrl="../../../../assets/images/sample-image-id.png"
    [shouldUseNativePicker]="true"
    (documentChange)="onDocumentChange('selfie', $event)"
    [form]="form"
    [capturecam]="'user'"
    [id]="'selfie'"
  />

  <div class="end-note">
    @if (uploadError) {
    <div>
      <p class="error-message">{{ uploadError }}</p>
    </div>
    }
  </div>
</div>

<app-cta-buttons
  currentPage="poi"
  [nextCallback]="onNextPressed"
  [pageNames]="advancedPages"
  [disabled]="!this.form.valid"
  tier="advanced"
  (nextEvent)="onNextPressed()"
  [isLoading]="loading"
></app-cta-buttons>
