<div class="tw-flex tw-relative">
  <!-- image -->
  <div (click)="handleclick()">
    <img [src]="imgUri" alt="Test" width="100px" height="100px" />
  </div>
  <div class="tw-w-[32px]" (click)="handleclick()"></div>
  <!-- label / progress -->
  <div class="tw-flex-col tw-w-full tw-justify-center">
    <!-- label -->
    <div class="tw-font-bold tw-text-[16px] tw-relative">
      <span (click)="handleclick()">
        {{ labelText }}
      </span>
      <span
        *ngIf="type == 'WEBSTORE'"
        (click)="showTooltip()"
        class="tw-relative tw-cursor-pointer"
        style="position: relative"
      >
        ⓘ
      </span>
      <app-custom-tooltip
        *ngIf="isTooltipVisible"
        [tooltipText]="tooltipText"
        (close)="hideTooltip()"
      ></app-custom-tooltip>
    </div>
    <div class="tw-h-[8px]" (click)="handleclick()"></div>
    <!-- progress -->
    <div *ngIf="doneCount >= total; else elseBlock">
      <div
        class="tw-bg-[#0C8448] tw-h-[28px] tw-w-full"
        (click)="handleclick()"
      ></div>
      <div
        class="tw-flex tw-justify-center tw-relative tw-top-[-26px] tw-text-[14px]"
        (click)="handleclick()"
      >
        Completed
      </div>
    </div>
    <ng-template #elseBlock>
      <div class="tw-w-full tw-flex" (click)="handleclick()">
        <div
          *ngFor="let part of progressArray"
          [ngClass]="part.class"
          class="tw-h-[28px] tw-flex-grow"
        ></div>
      </div>
      <div
        class="tw-flex tw-justify-center tw-relative tw-top-[-26px] tw-text-[14px]"
        (click)="handleclick()"
      >
        {{ doneCount }} / {{ total }}
      </div>
    </ng-template>
  </div>
</div>
<div class="tw-h-[32px]"></div>
