import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from 'src/app/components/time-picker/time-picker.component';
import { ToggleItemComponent } from 'src/app/components/toggle-item/toggle-item.component';
import { distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import { starterPages } from '../starter-data';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { StarterFormDataService } from '../starter-form-data.service';

type BusinessHoursOld = {
  key: string;
  day: string;
  startTime: FormControl;
  endTime: FormControl;
  isAvailable: boolean;
  dayprio?: number;
}[];

type SavedBusinessHours = {
  key: string;
  isAvailable: boolean;
  openHrs: {
    startTime: string;
    endTime: string;
  }[];
}[];

type BusinessHours = {
  key: string;
  isAvailable: boolean;
  isSet: boolean;
  openHrs: {
    startTime: FormControl;
    endTime: FormControl;
  }[];
}[];

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
  standalone: true,
  imports: [
    ToggleItemComponent,
    TimePickerComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CtaButtonsComponent,
  ],
})
export class BusinessHoursComponent implements OnInit {
  @Input({ required: true }) form!: FormGroup;
  @Input() isSaveHidden: FormControl = new FormControl(true);
  initialBusinessHoursValue: any;
  isAlwaysOpen: boolean = false;
  starterPages = starterPages;
  formData: FormData[] = [];
  newBusinessHoursArray: any[] = [];
  loading: boolean = false;
  isEditing: boolean[] = [];
  errorMessages: string[] = [];
  isBusinessHoursVisible: boolean[] = [];
  savedBusinessHours: SavedBusinessHours = [];

  constructor(
    private fb: FormBuilder,
    private starterService: StarterFormDataService
  ) {}

  startAndEndTimes = this.fb.group({
    mondaysStartTime: new FormControl('9:00 AM'),
    mondaysEndTime: new FormControl('6:00 PM'),
    tuesdaysStartTime: new FormControl('9:00 AM'),
    tuesdaysEndTime: new FormControl('6:00 PM'),
    wednesdaysStartTime: new FormControl('9:00 AM'),
    wednesdaysEndTime: new FormControl('6:00 PM'),
    thursdaysStartTime: new FormControl('9:00 AM'),
    thursdaysEndTime: new FormControl('6:00 PM'),
    fridaysStartTime: new FormControl('9:00 AM'),
    fridaysEndTime: new FormControl('6:00 PM'),
    saturdaysStartTime: new FormControl('9:00 AM'),
    saturdaysEndTime: new FormControl('6:00 PM'),
    sundaysStartTime: new FormControl('9:00 AM'),
    sundaysEndTime: new FormControl('6:00 PM'),
  });

  daypriorities: { [key: string]: number } = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
  };

  businessHoursArrayOld: BusinessHoursOld = [
    {
      key: 'MONDAY',
      day: 'Mondays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.mondaysStartTime,
      endTime: this.startAndEndTimes.controls.mondaysEndTime,
    },
    {
      key: 'TUESDAY',
      day: 'Tuesdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.tuesdaysStartTime,
      endTime: this.startAndEndTimes.controls.tuesdaysEndTime,
    },
    {
      key: 'WEDNESDAY',
      day: 'Wednesdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.wednesdaysStartTime,
      endTime: this.startAndEndTimes.controls.wednesdaysEndTime,
    },
    {
      key: 'THURSDAY',
      day: 'Thursdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.thursdaysStartTime,
      endTime: this.startAndEndTimes.controls.thursdaysEndTime,
    },
    {
      key: 'FRIDAY',
      day: 'Fridays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.fridaysStartTime,
      endTime: this.startAndEndTimes.controls.fridaysEndTime,
    },
    {
      key: 'SATURDAY',
      day: 'Saturdays',
      isAvailable: false,
      startTime: this.startAndEndTimes.controls.saturdaysStartTime,
      endTime: this.startAndEndTimes.controls.saturdaysEndTime,
    },
    {
      key: 'SUNDAY',
      day: 'Sundays',
      isAvailable: false,
      startTime: this.startAndEndTimes.controls.sundaysStartTime,
      endTime: this.startAndEndTimes.controls.sundaysEndTime,
    },
  ];

  businessHoursArray: BusinessHours = [
    {
      key: 'Mondays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.mondaysStartTime,
          endTime: this.startAndEndTimes.controls.mondaysEndTime,
        },
      ],
    },
    {
      key: 'Tuesdays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.tuesdaysStartTime,
          endTime: this.startAndEndTimes.controls.tuesdaysEndTime,
        },
      ],
    },
    {
      key: 'Wednesdays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.wednesdaysStartTime,
          endTime: this.startAndEndTimes.controls.wednesdaysEndTime,
        },
      ],
    },
    {
      key: 'Thursdays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.thursdaysStartTime,
          endTime: this.startAndEndTimes.controls.thursdaysEndTime,
        },
      ],
    },
    {
      key: 'Fridays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.fridaysStartTime,
          endTime: this.startAndEndTimes.controls.fridaysEndTime,
        },
      ],
    },
    {
      key: 'Saturdays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.saturdaysStartTime,
          endTime: this.startAndEndTimes.controls.saturdaysEndTime,
        },
      ],
    },
    {
      key: 'Sundays',
      isAvailable: false,
      isSet: false,
      openHrs: [
        {
          startTime: this.startAndEndTimes.controls.sundaysStartTime,
          endTime: this.startAndEndTimes.controls.sundaysEndTime,
        },
      ],
    },
  ];

  ngOnInit() {
    this.starterService.setCheckPointIndex(3);
    this.startAndEndTimes.valueChanges.subscribe(() => {
      this.saveTime();
    });

    if (this.form) {
      this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.initializeBusinessHours();
      });
    }

    this.form.value.business_hours?.controls.has_loaded_business_hours.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.initializeBusinessHours();
      });

    if (typeof this.starterService.shopBusinessHours === 'undefined') {
      this.starterService.getShopBusinessHoursV2().subscribe((data: any) => {
        this.starterService.shopBusinessHours = data;
        this.setBusinessHours();
      });
    } else {
      this.setBusinessHours();
    }

    if (typeof this.starterService.merchantData === 'undefined') {
      this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
        this.starterService.merchantData = data;
      });
    }

    // Update business hours logic
    this.startAndEndTimes.valueChanges.subscribe(() => {
      this.saveTime();
    });

    this.isBusinessHoursVisible = Array(this.businessHoursArray.length).fill(
      true
    );
    this.isEditing = Array(this.businessHoursArray.length).fill(false);

    this.updateSavedBusinessHours();
  }

  updateSavedBusinessHours() {
    this.savedBusinessHours = this.businessHoursArray.map((day) => ({
      key: day.key,
      isAvailable: day.isAvailable,
      openHrs: day.openHrs.map((hrs) => ({
        startTime: hrs.startTime?.value || '',
        endTime: hrs.endTime?.value || '',
      })),
    }));
  }

  setBusinessHours() {
    // set default time if new user
    // check if business hours time is 00:00
    var businessHours = this.starterService.shopBusinessHours.business_hours;
    var isNew = businessHours.every(
      (day: any) =>
        day.schedule_hour_from === '00:00:00' &&
        day.schedule_hour_to === '00:00:00'
    );

    console.log('isUserNew', isNew);

    // is whole day
    this.isAlwaysOpen = Boolean(
      this.starterService.shopBusinessHours.take_order_status.is_whole_day
    );
    if (!isNew) {
      // set day hours
      const businessWeekHours =
        this.starterService.shopBusinessHours.business_hours;
      this.businessHoursArrayOld = [];
      businessWeekHours.forEach((dayObj: any) => {
        this.businessHoursArrayOld.push({
          key: dayObj.day,
          day: dayObj.day,
          dayprio: this.daypriorities[dayObj.day as string] as number,
          isAvailable: dayObj.is_active,
          startTime: new FormControl(
            this.convertTo24HourFormat(dayObj.schedule_hour_from)
          ),
          endTime: new FormControl(
            this.convertTo24HourFormat(dayObj.schedule_hour_to)
          ),
        });
      });

      console.log('sorted', this.businessHoursArrayOld);
    }
    // this.setFormValues(formValue, this.startAndEndTimes)
    (this.businessHoursArrayOld as any[]).sort(function (a: any, b: any) {
      let daypriorities: { [key: string]: number } = {
        MONDAY: 0,
        TUESDAY: 1,
        WEDNESDAY: 2,
        THURSDAY: 3,
        FRIDAY: 4,
        SATURDAY: 5,
        SUNDAY: 6,
      };
      let keyA = daypriorities[a.key];
      let keyB = daypriorities[b.key];
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    console.log('sorted2', this.businessHoursArrayOld);
  }

  initializeBusinessHours() {
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;
      if (formValue) {
        this.businessHoursArray.forEach((businessDay) => {
          const updatedItem = formValue.find(
            (item: any) => item?.day === businessDay.key
          );
          if (updatedItem) {
            businessDay.isAvailable = updatedItem.is_active;
          }
        });
        this.setFormValues(formValue, this.startAndEndTimes);
        this.initialBusinessHoursValue = formValue.map((item: any) => ({
          ...item,
        }));
      }
    }
  }

  setFormValues(scheduleArray: any[], form: FormGroup) {
    scheduleArray.forEach((schedule) => {
      const day = schedule.day.toLowerCase();
      form.controls[`${day}sStartTime`].setValue(
        this.convertTo12HourFormat(schedule.schedule_hour_from)
      );
      form.controls[`${day}sEndTime`].setValue(
        this.convertTo12HourFormat(schedule.schedule_hour_to)
      );
    });
    this.isSaveHidden.setValue(true);
  }

  saveTime() {
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;
      const currentArray: BusinessHours = this.businessHoursArray;

      if (formValue) {
        formValue.forEach((businessDay: any) => {
          const updatedItem = currentArray.find(
            (item) => item?.key === businessDay.day
          );
          if (
            updatedItem &&
            updatedItem.isSet &&
            updatedItem.openHrs.length > 0
          ) {
            const firstOpenHr = updatedItem.openHrs[0];
            if (firstOpenHr.startTime && firstOpenHr.endTime) {
              // Ensure startTime and endTime exist
              businessDay.schedule_hour_from = this.convertTo24HourFormat(
                firstOpenHr.startTime.value
              );
              businessDay.schedule_hour_to = this.convertTo24HourFormat(
                firstOpenHr.endTime.value
              );
              businessDay.is_active = updatedItem.isAvailable;
            }
          }
        });
      }
      const hasChanges = isEqual(
        this.form.value.business_hours?.business_hours,
        this.initialBusinessHoursValue
      );
      this.isSaveHidden.setValue(hasChanges);
    }
  }

  setAvailability(dayIndex: number, dayKey: string, status: boolean) {
    this.businessHoursArrayOld[dayIndex].isAvailable = status;
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;

      if (formValue) {
        const businessDayToUpdate = formValue.find(
          (item: any) => item?.day === dayKey
        );
        if (businessDayToUpdate) {
          businessDayToUpdate.is_active = status;
        }
      }

      const hasChanges = isEqual(formValue, this.initialBusinessHoursValue);
      this.isSaveHidden.setValue(hasChanges);
    }
  }

  toggleAlwaysOpen(status: boolean): void {
    this.starterService.shopBusinessHours.take_order_status.is_whole_day.setValue(
      status
    );
    if (status) {
      this.businessHoursArray.forEach((day) => {
        day.openHrs = [
          {
            startTime: new FormControl('12:00 AM'),
            endTime: new FormControl('11:59 PM'),
          },
        ];
        day.isAvailable = false;
      });
    }

    this.updateSavedBusinessHours();
  }

  convertTo24HourFormat(time: string): string {
    if (!time) return '';

    const [timePart, period] = time.split(' ');
    if (!timePart || !period) return '';

    const [hours, minutes] = timePart.split(':').map(Number);
    let convertedHours = hours;

    if (isNaN(hours) || isNaN(minutes)) return '';

    if (period === 'PM' && hours < 12) {
      convertedHours += 12;
    } else if (period === 'AM' && hours === 12) {
      convertedHours = 0;
    }

    return `${convertedHours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  convertTo12HourFormat(time: string): string {
    if (!time) return '';
    const [timePart, period] = time.split(' ');
    if (!timePart || !period) return '';
    const [hoursStr, minutesStr] = timePart.split(':');
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (isNaN(hours) || isNaN(minutes)) return '';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${hours}:${formattedMinutes} ${period}`;
  }

  handleNextClick = async () => {
    this.loading = true;
    return await this.saveBusinessHours()
      .then(() => {
        this.loading = false;
        return true;
      })
      .catch(() => {
        this.loading = false;
        return false;
      });
  };

  async saveBusinessHours() {
    this.businessHoursArrayOld.forEach((businessDay) => {
      this.newBusinessHoursArray.push({
        day: businessDay.key,
        is_active: businessDay.isAvailable,
        schedule_hour_from: this.convertTo24HourFormat(
          businessDay.startTime.value
        ),
        schedule_hour_to: this.convertTo24HourFormat(businessDay.endTime.value),
      });
    });

    var store = {
      store: {
        shop_name: this.starterService.merchantData.shop_name,
        is_whole_day: this.isAlwaysOpen,
      },
      business_hours: this.newBusinessHoursArray,
    };

    console.log('toSave:', store);
    this.starterService.updateBusinessHours(store);

    // set vacation to true if everything is false
    var isVacation = false;
    if (
      this.businessHoursArrayOld.every((day) => !day.isAvailable) &&
      !this.isAlwaysOpen
    ) {
      isVacation = true;
    }

    return await this.starterService
      .updateVacationMode(this.starterService.merchantData.store_id, isVacation)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  onStartTimeChange(newStartTime: Event): void {
    console.log('valueChanged: New start time:', newStartTime);
  }

  onEndTimeChange(newEndTime: Event): void {
    console.log('valueChanged: New end time:', newEndTime);
  }

  handleChangeTime(index: number, key: string, event: any) {
    console.log('valueChanged:', index, key, event);
  }

  toggleEditMode(index: number): void {
    this.isEditing[index] = true;
  }

  saveChanges(index: number): void {
    if (!this.hasErrors(index)) {
      this.isEditing[index] = false;
      this.saveTime();
    }

    console.log(
      'Updated Business Hours Array:',
      JSON.stringify(this.savedBusinessHours, null, 2),
      this.savedBusinessHours
    );
  }

  hasErrors(index: number): boolean {
    return !!this.errorMessages[index];
  }

  getBusinessHoursDisplay(dayIndex: number): string {
    const day = this.businessHoursArray[dayIndex];
    if (day.isAvailable) return 'Closed';
    if (this.has24HourRange(day.openHrs)) return 'Open 24 Hours';
    return day.openHrs
      .map(
        (hrs) =>
          `${this.convertTo12HourFormat(
            hrs.startTime.value
          )} - ${this.convertTo12HourFormat(hrs.endTime.value)}`
      )
      .join('\n');
  }

  has24HourRange(
    openHrs: { startTime: FormControl; endTime: FormControl }[]
  ): boolean {
    return (
      openHrs.length === 1 &&
      openHrs[0].startTime.value === '12:00 AM' &&
      openHrs[0].endTime.value === '11:59 PM'
    );
  }

  setBusinessHoursOption(dayIndex: number, option: string): void {
    const day = this.businessHoursArray[dayIndex];

    if (option === '24hrs') {
      // Set 24-hour range for the day
      day.openHrs = [
        {
          startTime: new FormControl('12:00 AM'),
          endTime: new FormControl('11:59 PM'),
        },
      ];
      day.isAvailable = false;
      this.errorMessages[dayIndex] = ''; // Clear any error messages for 24-hour setting
    } else if (option === 'closed') {
      // Mark the day as closed
      day.isAvailable = true;
      day.openHrs = []; // Clear any open hours for closed days
      this.errorMessages[dayIndex] = '';
    } else if (option === 'custom') {
      day.isAvailable = false;
      day.openHrs = [];
      this.addDefaultBusinessHour(dayIndex);
      this.errorMessages[dayIndex] = '';
    }

    console.log('Updated Business Hours:', this.businessHoursArray[dayIndex]);
  }

  addDefaultBusinessHour(dayIndex: number): void {
    const day = this.businessHoursArray[dayIndex];
    day.openHrs = [
      {
        startTime: new FormControl('9:00 AM'),
        endTime: new FormControl('6:00 PM'),
      },
    ];
  }

  checkForRemainingErrors(dayIndex: number): void {
    const day = this.businessHoursArray[dayIndex];
    let errorMessage = '';

    // Check for invalid end times
    const hasInvalidEndTime = day.openHrs.some((hrs) => {
      const start = this.convertTo24HourFormat(hrs.startTime.value);
      const end = this.convertTo24HourFormat(hrs.endTime.value);
      return end <= start && end !== '00:00';
    });

    if (hasInvalidEndTime) {
      errorMessage +=
        'Time slots after midnight should be set for the next day. Please set the end time no later than 11:59 PM.\n';
    }

    // Check for overlaps only if there's more than one time range
    if (day.openHrs.length > 1) {
      const hasOverlap = day.openHrs.some((hrs, index) =>
        day.openHrs.some((otherHrs, otherIndex) => {
          if (index === otherIndex) return false;

          const start = this.convertTo24HourFormat(hrs.startTime.value);
          const end = this.convertTo24HourFormat(hrs.endTime.value);
          const otherStart = this.convertTo24HourFormat(
            otherHrs.startTime.value
          );
          const otherEnd = this.convertTo24HourFormat(otherHrs.endTime.value);

          // Adjust end times for midnight rollover
          const endTimeAdjusted =
            end === '00:00' || end < start ? '24:00' : end;
          const otherEndTimeAdjusted =
            otherEnd === '00:00' || otherEnd < otherStart ? '24:00' : otherEnd;

          return (
            (start >= otherStart && start < otherEndTimeAdjusted) ||
            (endTimeAdjusted > otherStart &&
              endTimeAdjusted <= otherEndTimeAdjusted) ||
            (start <= otherStart && endTimeAdjusted >= otherEndTimeAdjusted)
          );
        })
      );

      if (hasOverlap) {
        errorMessage +=
          'This time slot overlaps with your existing business hours. Please adjust the time.\n';
      }
    }

    // Update error messages
    this.errorMessages[dayIndex] = errorMessage.trim();

    // If no errors, sort the time ranges
    if (!errorMessage) {
      this.sortTimeRanges(day);
    }
  }

  private sortTimeRanges(day: {
    openHrs: { startTime: FormControl; endTime: FormControl }[];
  }): void {
    day.openHrs.sort((a, b) => {
      const startA = this.convertTo24HourFormat(a.startTime.value);
      const startB = this.convertTo24HourFormat(b.startTime.value);
      return startA.localeCompare(startB);
    });
  }

  removeTimeRange(dayIndex: number, timeRangeIndex: number): void {
    const day = this.businessHoursArray[dayIndex];

    day.openHrs.splice(timeRangeIndex, 1);

    if (day.openHrs.length === 1) {
      this.errorMessages[dayIndex] = '';
    } else {
      this.checkForRemainingErrors(dayIndex);
    }

    if (day.openHrs.length === 0) {
      day.isAvailable = true;
    }

    this.updateSavedBusinessHours();
  }

  addTimeRange(dayIndex: number): void {
    const day = this.businessHoursArray[dayIndex];
    const newStartTime = '9:00 AM';
    const newEndTime = '6:00 PM';
    day.isAvailable = false;

    // Add the new time range
    day.openHrs.push({
      startTime: new FormControl(newStartTime),
      endTime: new FormControl(newEndTime),
    });

    // Check for overlap errors
    if (
      day.openHrs.length > 1 &&
      this.hasOverlap(dayIndex, newStartTime, newEndTime)
    ) {
      this.errorMessages[dayIndex] =
        'This time slot overlaps with your existing business hours. Please adjust the time.';
    } else {
      this.checkForRemainingErrors(dayIndex);

      // Sort openHrs by start time if no errors exist
      if (!this.hasErrors(dayIndex)) {
        this.sortTimeRanges(day);
      }
    }
  }

  hasOverlap(
    dayIndex: number,
    newStartTime: string,
    newEndTime: string
  ): boolean {
    const day = this.businessHoursArray[dayIndex];
    const newStart = this.convertTo24HourFormat(newStartTime);
    const newEnd = this.convertTo24HourFormat(newEndTime);

    return day.openHrs.some((hrs) => {
      const existingStart = this.convertTo24HourFormat(hrs.startTime.value);
      const existingEnd = this.convertTo24HourFormat(hrs.endTime.value);

      return (
        (newStart >= existingStart && newStart < existingEnd) ||
        (newEnd > existingStart && newEnd <= existingEnd) ||
        (newStart <= existingStart && newEnd >= existingEnd)
      );
    });
  }
}
