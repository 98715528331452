<div class="main-container">
  <!-- Store Owner -->
  <div class="sections-container">
    <div class="store-logo section">
      <h3 class="section-title">Store Logo</h3>

      <app-reactive-image-upload
        [(ngModel)]="formData.storeLogo"
        [displayimage]="shopImageUrl"
      ></app-reactive-image-upload>

      <button
        class="enstack-magic"
        (click)="openAILink('shoplogo', 'Help me make a store logo')"
      >
        <img src="../../../assets/icons/sparkle.svg" alt="Enstack Magic" />
        <span>Generate Logo</span>
      </button>
    </div>

    <div class="store-description section">
      <h3 class="section-title">Store Description</h3>
      <app-reactive-textarea-input
        [required]="false"
        [control]="storeDescription"
      ></app-reactive-textarea-input>

      <button
        class="enstack-magic"
        (click)="
          openAILink('shopdescription', 'Help me write my store description')
        "
      >
        <img src="../../../assets/icons/sparkle.svg" alt="Enstack Magic" />
        <span>Generate Description</span>
      </button>
    </div>
  </div>

  <app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [data]="formData"
    (isAllowedToNextEvent)="(false)"
    (nextEvent)="handleNext()"
    [nextCallback]="handleNext"
    [isLoading]="loading"
    [isEmptyFields]="isEmptyFields"
  ></app-cta-buttons>
</div>
