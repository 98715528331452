<button
  class="tw-flex tw-items-center tw-space-x-[10px]"
  (click)="onBackClick()"
>
  <img src="../../../assets/icons/back-icon.svg" />
  <span
    class="tw-font-inter tw-font-semibold tw-text-[14px] back-btn"
    [class.back-btn-starter]="currentUrl.includes('/starter')"
    >Back
  </span>
</button>
