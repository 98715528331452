import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';
import { generate } from 'rxjs';
import * as uuid from 'uuid';
import { AuthGuard } from '../auth.guard';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor(private authService: AuthService, private authGuard: AuthGuard) {}

  async configureDatadog() {
    datadogLogs.init({
      clientToken: environment.DDCLIENTTOKEN,
      site: 'datadoghq.com',
      service: 'onboarding-' + environment.STAGE,
      env: environment.STAGE,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });

    datadogRum.init({
      applicationId: environment.DDAPPLICATIONID,
      clientToken: environment.DDCLIENTTOKEN,
      site: 'datadoghq.com',
      service: 'onboarding-' + environment.STAGE,
      env: environment.STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    try {
      const user_id_auth_guard =
        await this.authGuard.getUserIdFromQueryParams();
      const user_id_auth_service = this.authService.getuserid();
      const email = this.authService.getemail();
      console.log('email from auth service', email);
      console.log('user_id from auth service', user_id_auth_service);

      if (!user_id_auth_guard && !user_id_auth_service) {
        const user_id = await this.waitForLocalStorageValue('userId');
        if (user_id) {
          datadogRum.setUser({
            id: user_id,
            email: email ?? '',
          });
          console.log('[DATADOG] setting user in datadog', user_id, email);
        }
      
        console.log(
          '[DATADOG] setting user in datadog',
          user_id,
          email
        );
      } else {
        console.log(
          '[DATADOG] setting user in datadog',
          user_id_auth_guard,
          user_id_auth_service,
          email
        );
        
        datadogRum.setUser({
          id: user_id_auth_guard ?? user_id_auth_service ?? '',
          email: email ?? '',
        });
      }
    } catch (error) {
      console.error(
        '[DATADOG] user not found. Not setting user in datadog',
        error
      );
    }

    datadogRum.startSessionReplayRecording();
  }

  private waitForLocalStorageValue(key: string, timeout = 5000): Promise<string | null> {
    return new Promise((resolve) => {
      const value = localStorage.getItem(key);
      if (value) {
        resolve(value);
        return;
      }
  
      const listener = (event: StorageEvent) => {
        if (event.key === key && event.newValue) {
          window.removeEventListener('storage', listener);
          resolve(event.newValue);
        }
      };
      window.addEventListener('storage', listener);
  
      setTimeout(() => {
        window.removeEventListener('storage', listener);
        resolve(localStorage.getItem(key));
      }, timeout);
    });
  }
}
