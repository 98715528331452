import { CommonModule, NgIf } from '@angular/common';
import {
  Component,
  Input,
  Provider,
  forwardRef,
  signal,
  computed,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  CropperDialogComponent,
  CropperDialogData,
  CropperDialogResult,
} from '../../cropper-dialog/cropper-dialog.component';

import { FileUrlPipe } from './pipes/file-url.pipe';
import { ValidationMessageFn } from '../types/validation-message-fn';
import { StarterFormDataService } from 'src/app/pages/starter/starter-form-data.service';
import { lastValueFrom, Subscription } from 'rxjs';

const REACTIVE_FILE_INPUT_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveImageUploadComponent),
  multi: true,
};

@Component({
  selector: 'app-reactive-image-upload',
  templateUrl: './reactive-image-upload.component.html',
  styleUrls: ['./reactive-image-upload.component.scss'],
  providers: [REACTIVE_FILE_INPUT_ACCESSOR],
  standalone: true,
  imports: [CommonModule, FileUrlPipe],
})
export class ReactiveImageUploadComponent implements ControlValueAccessor {
  @Input()
  public labelText: string = 'File Input';

  @Input()
  public placeholder: string = 'Upload or Take a Photo';

  @Input()
  public id: string = 'file-input';

  @Input()
  public sampleImageUrl: string = '';

  @Input()
  public acceptedFileTypes: Array<string> = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  @Input()
  public errors: ValidationErrors | null | undefined = null;

  @Input()
  public validationMessage: Record<string, ValidationMessageFn> = {};

  @Input()
  public displayimage: string | null = null;

  public file = signal<File | null>(null);
  public hasFile = computed(() => this.file() !== null);
  public isFileImage = computed(() => {
    const file = this.file();
    return file ? file.type.includes('image') : false;
  });
  private imgSubscription!: Subscription;

  constructor(
    private dialog: MatDialog,
    private starterService: StarterFormDataService
  ) {}

  ngOnInit() {
    this.imgSubscription = this.starterService.storeLogo$.subscribe(
      (newValue) => {
        this.displayimage = newValue;
      }
    );
  }

  public get hasErrors(): boolean {
    return this.errors !== null;
  }

  public buildAcceptString(): string {
    return this.acceptedFileTypes.join(',');
  }

  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0] || null;

    console.log('VD File Change ', file);

    if (file) {
      this.openCropperDialog(file);
    } else {
      this.file.set(null);
      this.onChange(null);
    }
  }

  public onFileRemoved(): void {
    this.file.set(null);
    this.displayimage = null;
    this.onChange(null);
  }

  public get errorMessage(): string {
    const firstError = Object.keys(this.errors || {})[0];
    const messageFn = this.validationMessage[firstError];
    return messageFn ? messageFn(this.errors) : '';
  }

  private openCropperDialog(file: File): void {
    const dialogRef = this.dialog.open(CropperDialogComponent, {
      data: { image: file, width: 400, height: 400 }, // Adjust width and height as needed
    });

    dialogRef
      .afterClosed()
      .subscribe((result: CropperDialogResult | undefined) => {
        if (result) {
          const croppedFile = new File([result.blob], file.name, {
            type: file.type,
          });
          this.file.set(croppedFile);
          this.onChange(croppedFile);
          this.onTouched();
        }
      });
  }

  private onChange: (value: File | null) => void = (value) => {
    console.log('VD On Change ', value);
  };
  private onTouched: () => void = () => {};

  writeValue(obj: File): void {
    this.file.set(obj);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
