import { Component, forwardRef, Input, OnInit, Provider, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import {
  provideNativeDateAdapter,
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
  MatDateRangeInput,
  MatDatepicker
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MomentDateAdapter,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { ValidationMessageFn } from '../types/validation-message-fn';
import { CommonModule } from '@angular/common';

const REACTIVE_NG_DATE_PICKER_FIELD_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveDatePickerComponent),
  multi: true,
};

const MAT_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reactive-date-picker',
  standalone: true,
  imports: [
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    CommonModule,
  ],
  templateUrl: './reactive-date-picker.component.html',
  styleUrl: './reactive-date-picker.component.scss',
  providers: [
    REACTIVE_NG_DATE_PICKER_FIELD_ACCESSOR,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMAT },
  ],
})
export class ReactiveDatePickerComponent implements ControlValueAccessor {
  @ViewChild('picker') picker!: MatDatepicker<any>;
  @Input() placeholder: string = 'Select a date';
  @Input() formControl: FormControl = new FormControl();
  @Input() errors?: ValidationErrors | null = null;
  @Input() validationMessage: Record<string, ValidationMessageFn> = {};
  value: Date | null = null;
  @Input() isDisabled: boolean = false;
  @Input() isTouched: boolean = false;
  private onChange: (value: Date | null) => void = () => {};
  private onTouched: () => void = () => {};

  get hasErrors(): boolean {
    return this.errors !== null;
  }

  public get errorMessage(): string {
    const firstError = Object.keys(this.errors || {})[0];
    const messageFn = this.validationMessage[firstError];
    return messageFn ? messageFn(this.errors) : 'Testing';
  }
  writeValue(obj: Date | null): void {
    this.onChange(obj);
  }
  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  onDateChange(event: MatDatepickerInputEvent<Moment>): void {
    const value = event.value?.toDate() ?? null;
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.isTouched = true;
  }
  toggleDatepicker() {
    console.log("Clicking picker");
    this.picker.open()
  }

  onFocus() {
    this.onTouched();
    this.isTouched = true;
  }

  onBlur() {
    this.onTouched();
    this.isTouched = true;
  }
}
