<div class="input-container">
  <label for="{{ id }}">{{ label }}</label>
  <input
    type="{{ type }}"
    [id]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [class.ng-invalid]="control.invalid && control.touched"
  />
  <div *ngIf="control.invalid && control.touched" class="error-message">
    <div *ngIf="control.errors?.['required']">Field is required *</div>
    <div *ngIf="control.errors?.['bankNumberInvalid']" class="error-message">
      Bank number must be
      {{ minNum === maxNum ? minNum : minNum + "-" + maxNum }} digits long
    </div>
    <div *ngIf="control.errors?.['other']">{{ errorMessage }}</div>
  </div>
</div>
