import { Environment } from "./environment.type";

export const environment = {
  PLATFORM_API_URL: 'https://staging-api.ph.enstack.com',
  ENVIRONMENT: 'staging',
  MERCHANT_EXTRAS: 'https://extra-data-staging.enstack.com',
  HANDOVER_URL:
    'https://6gqpxvjitfhyrn2hgwvnpqjjpu0rdybf.lambda-url.ap-southeast-1.on.aws',
  UPLOAD_URL: 'https://lju875duta.execute-api.ap-southeast-1.amazonaws.com',
  ENSTACK_AI_URL: 'https://ai-staging.enstack.com',
  DDCLIENTTOKEN: 'pub464ee12369d205e7b22b9ca74bdbab7d',
  DDAPPLICATIONID: 'ef5a5246-04e0-44e9-a10e-104fe41cd530',
  STAGE: 'staging',
  LANGUAGE_CONFIG: {
    th: {
      PLATFORM_API_URL: 'https://th-staging.enstack.com',
      MERCHANT_EXTRAS: 'http://th.extra-data-staging.enstack.com',
    },
    en: {
      PLATFORM_API_URL: 'https://staging-api.ph.enstack.com',
      MERCHANT_EXTRAS: 'https://extra-data-staging.enstack.com',
    },
  },
} satisfies Environment;
