import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
// import { AdvancedBusinessAddressForm } from './schemas/advanced-business-address-form.schema';
import { AdvancedAddressOption } from './schemas/advanced-address-option.schema';
import { environment } from 'src/environments/environment';
import { AdvancedBusinessAddressForm } from './schemas/advanced-business-address-form.schema';

const PROVINCE_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/country`;
const CITY_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/province`;
const BARANGAY_API_URL = `${environment.PLATFORM_API_URL}/api/webstore/address/city`;

@Injectable({
  providedIn: 'root',
})
export class AdvancedService {
  idCategory: string = 'primary';
  private http = inject(HttpClient);

  constructor() {}

  getIdCategory(): string {
    return this.idCategory;
  }

  updateIdCategory(category: string) {
    this.idCategory = category;
  }

  async getProvinces(country: number = 1) {
    return lastValueFrom(
      this.http.get<AdvancedAddressOption[]>(
        `${PROVINCE_API_URL}/${country}/province/`
      )
    );
  }

  async getCities(province: number) {
    return lastValueFrom(
      this.http.get<AdvancedAddressOption[]>(
        `${CITY_API_URL}/${province}/city/`
      )
    );
  }

  async getBarangays(city: number) {
    return lastValueFrom(
      this.http.get<AdvancedAddressOption[]>(
        `${BARANGAY_API_URL}/${city}/barangay/`
      )
    );
  }

  async uploadPrimaryId(primaryIdFile: File) {
    const formData = new FormData();
    formData.append('primary_id', primaryIdFile);

    return lastValueFrom(
      this.http.post('/api/v1/ph/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  }

  async uploadSelfie(selfieFile: File) {
    const formData = new FormData();
    formData.append('selfie', selfieFile);

    return lastValueFrom(
      this.http.post('/api/v1/ph/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  }

  async uploadSecondaryIDs(payload: { images: File[]; deletedIds: string[] }) {
    if (payload.images.length === 0) {
      throw new Error('Secondary IDs cannot be blank');
    }
    const formData = new FormData();

    for (const secondaryIdImage of payload.images) {
      formData.append('secondary_id', secondaryIdImage);
    }

    for (const secondaryId of payload.deletedIds) {
      formData.append('deleted_secondary_id', secondaryId);
    }

    return lastValueFrom(
      this.http.post('/api/v1/ph/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  }

  activateNonCOD(){
    return this.http.post(
      `${environment.PLATFORM_API_URL}/api/v1/apps/non-cod-payments/activate/`,
      null
    )
  }

  async uploadProofOfBilling(proofOfBillingFile: File) {
    const formData = new FormData();
    formData.append('proof_of_billing', proofOfBillingFile);

    return lastValueFrom(
      this.http.post('/api/v1/ph/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  }
  
  getShippingAddress(merchantId: number) {
    return lastValueFrom(this.http.get(`${environment.PLATFORM_API_URL}/api/v1/merchant-info/`))
  }

  async getMerchantDetails(merchantId: number) {
    return lastValueFrom(this.http.get(`${environment.PLATFORM_API_URL}/api/v1/merchant-info/${merchantId}/`));
  }

  async updateBusinessAddress(data: AdvancedBusinessAddressForm) {
    return lastValueFrom(this.http.post(`${environment.PLATFORM_API_URL}/api/v1/business-address/`, data));
  }
}
