<div class="main-container">
  <div class="container" *ngIf="idCategory == 'primary'">
    <h3 class="title">
      <!-- Upload Primary ID -->
       {{'advanced.upload-primary-id' | i18next}}
      <span class="tw-text-[#9FE9FE]">*</span>
    </h3>
    <span class="note">
      {{ 'advanced.uploadid-note' | i18next }}
    </span>
    <ol class="tw-text-[14px] tw-text-white tw-list-decimal tw-pl-6 tw-font-normal tw-mb-[12px]">
      <li>{{ 'advanced.uploadid-guide-1' | i18next }}</li>
      <li>{{ 'advanced.uploadid-guide-2' | i18next }}</li>
    </ol>
    

    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('primaryId', $event)"
      [form]="primaryIdForm"
      [secondaryForm]="primaryIdFormBack"
      [id]="'primary-id'"
    />
  </div>

  <div class="container" *ngIf="idCategory == 'secondary'">
    <h3 class="title">
      <!-- Upload Two Secondary IDs -->
      {{ 'advanced.upload-secondary-id' | i18next }}
      <span class="tw-text-[#9FE9FE]">*</span>
    </h3>
    <span class="note">
      {{ 'advanced.uploadid-note' | i18next }}
    </span>
    <ol class="tw-text-[14px] tw-text-white tw-list-decimal tw-pl-6 tw-font-normal tw-mb-[12px]">
      <li>{{ 'advanced.uploadid-guide-1' | i18next }}</li>
      <li>{{ 'advanced.uploadid-guide-2' | i18next }}</li>
    </ol>

    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('secondaryId1', $event)"
      [form]="secondaryId1Form"
      [secondaryForm]="secondaryId1FormBack"
      [id]="'secondary-id-1'"
    />
    <app-document-upload
      labelText="Front of ID Card"
      [shouldUseNativePicker]="true"
      sampleImageUrl="../../../../assets/images/sample-image-id.png"
      (documentChange)="onDocumentChange('secondaryId2', $event)"
      [form]="secondaryId2Form"
      [secondaryForm]="secondaryId2FormBack"
      [id]="'secondary-id-2'"
    />
  </div>
  @if (uploadError) {
  <div>
    <p class="error-message">{{ uploadError }}</p>
  </div>
  }
  <app-cta-buttons
    currentPage="poi"
    [pageNames]="advancedPages"
    tier="advanced"
    [disabled]="nextDisabled"
    (nextEvent)="onNextPressed()"
    [nextCallback]="onNextPressed"
    [isLoading]="isLoading"
  />
</div>
