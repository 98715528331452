import {
  Component,
  OnInit,
  SimpleChanges,
  inject,
  Injector,
  effect,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { AddressService } from 'src/app/services/address/address.service';
import { AddressOption } from 'src/app/services/address/models/address-option.model';
import { StarterFormDataService } from '../starter-form-data.service';
import { starterPages } from '../starter-data';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { ValidationMessageFn } from 'src/app/components/inputs/types/validation-message-fn';
import { ReactiveNgSelectFieldComponent } from 'src/app/components/inputs/reactive-ng-select-field/reactive-ng-select-field.component';
import { ReactiveTextField2Component } from 'src/app/components/inputs/reactive-text-field2/reactive-text-field2.component';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss'],
  standalone: true,
  imports: [
    ReactiveTextInputComponent,
    CtaButtonsComponent,
    ReactiveNgSelectFieldComponent,
    ReactiveTextField2Component,
  ],
})
export class AddressInfoComponent implements OnInit {
  @ViewChild(CtaButtonsComponent) ctaButtons!: CtaButtonsComponent;
  starterPages: string[] = starterPages;
  selectedProvince: FormControl = new FormControl(null, Validators.required);
  selectedCity: FormControl = new FormControl(null, Validators.required);
  selectedBarangay: FormControl = new FormControl(null, Validators.required);
  zipCode: FormControl = new FormControl(null, Validators.required);
  addressDetails: FormControl = new FormControl(null, Validators.required);
  formGroup: FormGroup;
  loading: boolean = false;

  formData: any = {
    province: null,
    city: null,
    barangay: null,
    zipCode: null,
    addressDetails: null,
  };
  allFilled: boolean = false;

  provinceQuery = injectQuery(() => ({
    queryKey: ['province'],
    queryFn: () => this.addressService.getProvinces(),
  }));

  cityQuery = injectQuery(() => ({
    queryKey: ['city'],
    queryFn: () => {
      const province = this.selectedProvince.value;
      if (!province) {
        return [];
      }
      return this.addressService.getCities(province.id); // Ensure province.id is used
    },
  }));

  barangayQuery = injectQuery(() => ({
    queryKey: ['barangay'],
    queryFn: () => {
      const city = this.selectedCity.value;
      if (!city) {
        return [];
      }
      return this.addressService.getBarangays(city.id); // Ensure city.id is used
    },
  }));

  public get validationMessages(): Record<string, ValidationMessageFn> {
    return {
      required: () => 'Field is required *',
    };
  }

  constructor(
    private formDataService: StarterFormDataService,
    private addressService: AddressService
  ) {
    this.formGroup = new FormGroup({
      selectedProvince: this.selectedProvince,
      selectedCity: this.selectedCity,
      selectedBarangay: this.selectedBarangay,
      zipCode: this.zipCode,
      addressDetails: this.addressDetails,
    });

    this.selectedProvince.valueChanges.subscribe(async (value) => {
      this.formData.province = value ? value.name : null; // Adjust based on your AddressOption model
      await this.cityQuery.refetch();
      this.checkRequiredFields();
    });

    this.selectedCity.valueChanges.subscribe(async (value) => {
      this.formData.city = value ? value.name : null; // Adjust based on your AddressOption model
      await this.barangayQuery.refetch();
      this.checkRequiredFields();
    });

    this.selectedBarangay.valueChanges.subscribe((value) => {
      this.formData.barangay = value ? value.name : null; // Adjust based on your AddressOption model
      this.checkRequiredFields();
    });

    this.zipCode.valueChanges.subscribe((value) => {
      this.formData.zipCode = value;
      this.checkRequiredFields();
    });

    this.addressDetails.valueChanges.subscribe((value) => {
      this.formData.addressDetails = value;
      this.checkRequiredFields();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkRequiredFields();
  }

  async ngOnInit(): Promise<void> {
    this.formDataService.setCheckPointIndex(6);
    await this.provinceQuery.refetch();
    const address = localStorage.getItem('address');

    if (address) {
      this.formGroup.controls['addressDetails'].setValue(address);
    }
    console.log(this.formData);
  }

  checkRequiredFields() {
    this.allFilled =
      this.selectedProvince.valid &&
      this.selectedCity.valid &&
      this.selectedBarangay.valid &&
      this.zipCode.valid &&
      this.addressDetails.valid;

    console.log('All Filled Status:', this.allFilled);
  }

  handleNextClick = async () => {
    if (this.formGroup.invalid) {
      return false;
    }
    this.loading = true;
    this.formDataService.setCheckPointIndex(6);
    console.log('Form Data:', this.formData);

    console.log(
      'selected',
      this.getFormControl('selectedProvince'),
      this.selectedBarangay.value,
      this.getFormControl('barangay')
    );
    var data = {};
    try {
      data = {
        address: {
          line_1: this.addressDetails.value,
          barangay:
            this.selectedBarangay.value.name || this.selectedBarangay.value,
          city: this.selectedCity.value.name || this.selectedCity.value,
          province: this.selectedProvince.value.name || this.selectedCity.value,
          postal_code: this.zipCode.value,
          latitude: this.formDataService.getLatLong().lat,
          longitude: this.formDataService.getLatLong().lng,
        },
        from_onboarding: true,
      };
    } catch (error) {
      if (this.ctaButtons) {
        this.ctaButtons.previousCount();
      }
    }

    console.log('Hello these changes work');
    console.log('IC data', data);
    return await this.formDataService.updateAddress(data).then((response) => {
      this.loading = false;
      return response;
    });
  };

  public getFieldError(field: string) {
    return this.formGroup.get(field)?.errors;
  }

  public getFormControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }
}
