<div class="main-container">
  <div class="content">
    <div class="section-text">
      <h3 class="section-title">Store Owner*</h3>
      <p class="section-note">
        Type in the name of the owner of your store or the name of an authorized
        representative. This name should match the valid ID that will be
        submitted when applying for non-cash payments.
      </p>
    </div>

    <app-reactive-text-field2
      label="First Name"
      [control]="firstNameControl"
      [required]="true"
      [type]="'text'"
      reference="letters-only"
    />
    <app-reactive-text-field2
      label="Last Name"
      [control]="lastNameControl"
      [required]="true"
      reference="letters-only"
    />
  </div>

  <app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="!isFormValid()"
    [data]="formData"
    (nextEvent)="handleNextClick()"
    [nextCallback]="handleNextClick"
    [isLoading]="loading"
    [backButton]="false"
  ></app-cta-buttons>
</div>
