import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'app-card-challenge',
  standalone: true,
  imports: [CommonModule, CustomTooltipComponent],
  templateUrl: './card-challenge.component.html',
  styleUrls: ['./card-challenge.component.scss'],
})
export class CardChallengeComponent implements OnInit {
  @Input() labelText: string = '';
  @Input() imgUri: string = '';
  @Input() doneCount: number = 0;
  @Input() total: number = 0;
  @Input() type: string | null = null;
  @Input() destination: string = '';
  @Input() parameters: { [key: string]: any } | null = null;

  isTooltipVisible: boolean = false;
  tooltipText: string =
    'You need 5 delivered Web Store orders with standard or same-day shipping using cash or non-cash payment, or customer pickup and own shipping with a non-cash payment';

  progressArray: { class: string }[] = [];

  ngOnInit(): void {
    this.updateProgressArray();
  }

  updateProgressArray(): void {
    this.progressArray = [];
    for (let i = 0; i < this.total; i++) {
      if (i < this.doneCount) {
        this.progressArray.push({ class: 'tw-bg-filled' });
      } else {
        this.progressArray.push({ class: 'tw-bg-not-filled' });
      }
    }
  }

  handleclick() {
    parent.postMessage(
      JSON.stringify({
        target: this.destination,
        parameters: this.parameters,
      }),
      '*'
    );
  }

  showTooltip() {
    this.isTooltipVisible = true;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }
}
