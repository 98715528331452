<div
  class="container"
  [style.background-color]="
    currentUrl?.includes('/starter')
      ? '#9FE9FE'
      : currentUrl?.includes('/advanced')
      ? '#096999'
      : currentUrl?.includes('/verified')
      ? '#2C3333'
      : '#FFF'
  "
>
  <app-header />

  <div class="router-outlet">
    <router-outlet />
  </div>
</div>

<!-- <div>
  <div class="container">
    <span>
      Upload a clear picture of your business documents. Make sure that your
      full name or business name and address are visible and without
      obstruction.
    </span>
    <form [formGroup]="form">
      <app-reactive-file-input
        [id]="'file'"
        formControlName="file"
        sampleImageUrl="assets/images/image 9.png"
        [acceptedFileTypes]="['image/*', 'application/pdf']"
        [errors]="form.errors?.['file']"
        [validationMessage]="fileValidationMessage"
      />
    </form>
  </div>
</div> -->
