import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { starterPages } from '../starter-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StarterFormDataService } from '../starter-form-data.service';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { AddressAutocompleteComponent } from 'src/app/components/address-autocomplete/address-autocomplete.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'app-pickup-address',
  standalone: true,
  imports: [
    ReactiveTextInputComponent,
    CtaButtonsComponent,
    GoogleMap,
    MapMarker,
    AddressAutocompleteComponent,
    CommonModule,
    SharedModule
  ],
  templateUrl: './pickup-address.component.html',
  styleUrl: './pickup-address.component.scss',
})
export class PickupAddressComponent implements OnInit {
  starterPages: string[] = starterPages;

  // constructor(private formDataService: StarterFormDataService) {
  //   this.formGroup = new FormGroup({
  //     usernameControl: new FormControl('', Validators.required),
  //     // Add more form controls as needed
  //   });
  // }

  isAllFilled = false;
  @Input() province: string = '';
  @Input() city: string = '';
  @Input() barangay: string = '';
  @Input() zipCode: string = '';
  @Input() addressDetails: string = '';
  addressInput: string = '';
  changeAddress: boolean = false;
  @Output() togglePinLocation = new EventEmitter<void>();
  @Output() longlat = new EventEmitter<any>();

  onChangeClick() {
    this.togglePinLocation.emit();
  }
  
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private starterService: StarterFormDataService
  ) {}

  ngOnInit() {
    // this.starterService.setCheckPointIndex(5);
    this.addressInput = [
      this.addressDetails,
      this.barangay, 
      this.city,
      this.province,
      this.zipCode
    ].filter(Boolean).join(', ');

    this.addressInput = this.capitalizeWords(this.addressInput);

    this.getCoordinatesFromAddress(this.addressInput);

    // this.address.set(this.capitalizeWords(this.addressInput));
    // this.addressInput = this.province + ' ' + this.city + ' ' + this.barangay + ' ' + this.zipCode + ' ' + this.addressDetails;
  }

  async getCoordinatesFromAddress(address: string) {
    const geocoder = new google.maps.Geocoder();
    
    try {
      const response = await geocoder.geocode({ address });
      
      if (response.results.length > 0) {
        const location = response.results[0].geometry.location;
        
        this.center = {
          lat: location.lat(),
          lng: location.lng()
        };
  
        // Store coordinates in localStorage
        localStorage.setItem('loc', JSON.stringify(this.center));
  
        // Update map position
        this.googleMap()?.setCenter(this.center);
        this.selectedPosition.set(this.center);
        console.log('address on init', this.address());
        console.log('latlong on init', this.center);
        
        return this.center;
      }
    } catch (error) {
      console.error('Geocoding failed:', error);
    }
    return null;
  }


  ngAfterViewInit() {
    // set coordinates
    const latLong = localStorage.getItem('loc');
    console.log('latlong', latLong);
    if (latLong) {
      this.center = {
        lat: Number(JSON.parse(latLong).lat),
        lng: Number(JSON.parse(latLong).lng),
      };
    } else {
      this.center = {
        lat: 14.582919,
        lng: 120.979683,
      };
    }
    console.log('latlong', this.center);
  }

  googleMap = signal<google.maps.Map | null>(null);
  selectedPosition = signal<google.maps.LatLngLiteral | null>(null);
  selectedPlace = signal<google.maps.places.PlaceResult | null>(null);
  address = signal<string | null>(null);

  zoom = 12;

  // set lat long here
  center: google.maps.LatLngLiteral = {
    lat: 14.582919,
    lng: 120.979683,
  };

  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    maxZoom: 20,
    minZoom: 8,
    clickableIcons: false,
    // center: this.center
  };

  isPinMoved: boolean = false;
  isConfirmed: boolean = false;

  getAddress(position: google.maps.LatLngLiteral): void {
    const geocoder = new google.maps.Geocoder();
    console.log('geocoder 1', geocoder);
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK') {
        if (results && results[0]) {
          const formattedAddress = results[0].formatted_address;
          console.log('geocoder 2', formattedAddress);
          this.address.set(formattedAddress); // Update the address signal
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to:', status);
      }
    });
  }

  onMarkerDrag(event: google.maps.MapMouseEvent): void {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();

    if (lat !== undefined && lng !== undefined) {
      const newPosition: google.maps.LatLngLiteral = {
        lat: lat,
        lng: lng,
      };
      this.selectedPosition.set(newPosition);
      this.isConfirmed = false;
      this.isPinMoved = true;

      this.longlat.emit(newPosition);
      console.log('latlong on marker drag new position', newPosition);
      console.log('onMarkerDrag', lat, lng, this.isPinMoved, this.isConfirmed);
  
      if (newPosition) {
        localStorage.setItem('loc', JSON.stringify(newPosition));
        console.log('loc in storage', localStorage.getItem('loc'));
        this.getAddress(newPosition);
        console.log('addressinput new input', this.address());
        localStorage.setItem('address', this.address() ?? '');
      }
    }


  }

  public onMapClick(event: google.maps.MapMouseEvent) {
    const position = event.latLng?.toJSON();
    if (!position) return;

    this.selectedPosition.set(position);
    this.isPinMoved = true;
    this.isConfirmed = false;
    console.log('onMapClick', position, this.isPinMoved, this.isConfirmed);
    if (position) {
      localStorage.setItem('loc', JSON.stringify(position));
      console.log('loc in storage', localStorage.getItem('loc'));
      this.getAddress(position);
      console.log('addressinput mapclick', this.address());
      localStorage.setItem('address', this.address() ?? '');
    }

    // this.onUseEntry();
  }

  public onPlaceChanged(place: google.maps.places.PlaceResult) {
    const { geometry } = place;
    console.log('onPlaceChanged', geometry);

    if (!geometry) {
      return;
    }

    const { location } = geometry;

    if (!location) {
      return;
    }

    this.selectedPosition.set({
      lat: location.lat(),
      lng: location.lng(),
    });
    this.selectedPlace.set(place);

    this.panCamera({
      lat: location.lat(),
      lng: location.lng(),
    });

    console.log('latlong', location.lat(), location.lng());
    // this.starterService.latLong = location
    var newPosition: google.maps.LatLngLiteral = {
      lat: location.lat(),
      lng: location.lng(),
    };

    var latLong = {
      latitude: location.lat(),
      longitude: location.lng(),
    };

    this.starterService.latLong = latLong;

    console.log('saved latlong', this.starterService);

    this.getAddress(newPosition);
    this.isAllFilled = true;
    this.longlat.emit(latLong);
    console.log('latlong on place changed', latLong);
  }

  public panCamera(position: google.maps.LatLngLiteral) {
    const map = this.googleMap();

    if (!map) {
      return;
    }

    map.panTo(position);
  }

  public async onMapInitialized(event: google.maps.Map) {
    this.googleMap.set(event);

    try {
      const currentPosition = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        }
      );

      const position = {
        lat: currentPosition.coords.latitude,
        lng: currentPosition.coords.longitude,
      } satisfies google.maps.LatLngLiteral;
      console.log('Current position:', position);

      this.panCamera(position);
      this.selectedPosition.set(position);
    } catch (error) {
      console.error('Geolocation error:', error);
      const fallbackPosition = {
        lat: 14.582919,
        lng: 120.979683,
      };
      console.log('Using fallback position:', fallbackPosition);

      this.panCamera(fallbackPosition);
      this.selectedPosition.set(fallbackPosition);
    }
  }

  toggleNext() {
    this.starterService.setCheckPointIndex(3);
    console.log('Toggling next');
    const position = this.selectedPosition();
    const address = this.address();

    if (position !== null) {
      localStorage.setItem('loc', JSON.stringify(position));
      console.log('addressinput 1', address);
      if (address !== null) {
        localStorage.setItem('address', address);
      }
      console.log('addressinput 2', address);
    }
    // this.router.navigateByUrl('/tabs/addresses/(address-outlet:address-details)')
  }

  toggleBack() {
    this.router.navigateByUrl('/tabs/settings/shipping');
  }

  onUseEntry() {
    this.isConfirmed = true;
    this.isPinMoved = false;
    this.getAddress(this.center);
    console.log('onUseEntry center pin confirm', this.center, this.isPinMoved, this.isConfirmed);
    
    const position = this.selectedPosition();
    const address = this.address();
    
    if (position !== null) {
      localStorage.setItem('loc', JSON.stringify(position));
      console.log('addressinput 1', address);
      if (address !== null) {
        localStorage.setItem('address', address);
      }
      console.log('addressinput 2', address);
    }
    // localStorage.setItem('loc', JSON.stringify(this.longlat));
  }

  capitalizeWords(input: string): string {
    if (!input) return '';
    return input
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
