import { Component } from '@angular/core';
import { ReactiveTextInputComponent } from 'src/app/components/inputs/reactive-text-input/reactive-text-input.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { ReactiveFileInputComponent } from 'src/app/components/inputs/reactive-file-input/reactive-file-input.component';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { OtpComponent } from 'src/app/components/otp/otp.component';
import { starterPages } from '../starter-data';
import { StarterFormDataService } from '../starter-form-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-email-verification',
  standalone: true,
  imports: [
    ReactiveTextInputComponent,
    ReactiveFormsModule,
    ProgressBarComponent,
    ReactiveFileInputComponent,
    CtaButtonsComponent,
    FormsModule,
    OtpComponent,
    CtaButtonsComponent,
  ],
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.scss',
})
export class EmailVerificationComponent {
  formGroup: FormGroup;
  inputValue: any;
  data = '';
  starterPages: string[] = starterPages;
  email_address: string = '';
  allowedToNext: boolean = false;

  constructor(
    private starterService: StarterFormDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.formGroup = new FormGroup({
      usernameControl: new FormControl('', Validators.required),
      // Add more form controls as needed
    });
  }
  errorMessage = '';

  pin: string[] = ['', '', '', '', '', ''];

  print() {
    console.log('hello');
  }

  async ngOnInit() {
    // await this.starterService.setToken()
    console.log('email-verification, onInit');
    this.starterService.getMerchantDetails();
    this.starterService.sendOTP();
    this.startCountdown();
    // const user_id = Number(localStorage.getItem('user_id'));
    // this.starterService.getEmailAddress(user_id).subscribe(
    //   (result)=>{
    //     this.email_address = result.email_address
    //     this.starterService.sendEmailOTP(result.email_address)
    //   },
    //   (error) => {
    //     console.log(error)
    //   }
    // )
  }

  focusNext(event: any, index: number): void {
    const input = event.target;
    if (input.value.length >= 1 && index < this.pin.length) {
      const nextInput = input.parentElement?.parentElement?.querySelector(
        `input:nth-child(${index + 1})`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  validated = false;

  async handlechange() {
    console.log('change', this.data);
    if (this.data.length == 6) {
      // try{
      //   const response = await firstValueFrom(this.starterService.verifyOtp(this.data));
      //   console.log("change  response", response)
      //   if (response["success"]){
      this.validated = true;
      //   }
      // }catch(ex){
      //   console.log("change error", ex)
      // }
    } else {
      this.validated = false;
    }
  }

  // async validate(): Promise<boolean>{
  //   console.log("runblock", this.data);
  //   const response = await firstValueFrom(this.starterService.verifyOtp(this.data));
  //   console.log("next pressed response", response)
  //   return false
  // }

  async handleNext(): Promise<boolean> {
    console.log('next pressed', this.data);
    if (this.data.length != 6) {
      return false;
    }
    try {
      const response = await firstValueFrom(
        this.starterService.verifyOtp(this.data)
      );
      console.log('OTP Verification Response:', response);
      if (response.success) {
        return true;
      } else {
        console.log(response, 'error');
        this.setErrorMessage(response.error.error);
        return false;
      }
    } catch (error) {
      console.error('OTP Verification Error:', error);
      var errorMessage = (error as any).error.error;
      if (errorMessage == 'Invalid code')
        errorMessage = 'The OTP you entered is incorrect.';
      this.setErrorMessage(errorMessage);
      return false;
    }
    // const response = await firstValueFrom(this.starterService.verifyOtp(this.data));
    // console.log("next pressed response", response)
    // await this.starterService.verifyOtp(this.data)
    // .subscribe((value) => {
    //   this.starterService.setCheckPointIndex(1)
    //   console.log('email verification', value)
    //   return true
    // }, (error) => {
    //   console.log(error)
    //   return false
    // });
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = '';
    }, 5000); // Clear the error message after 5 seconds
  }

  resendOtp(event: Event): void {
    event.preventDefault();
    if (!this.canResend) {
      return;
    }

    this.startCountdown();
    this.starterService.sendOTP();
  }

  countdown: number = 120; // countdown timer in seconds
  interval: any;
  countdownString: string = 'Resend 02:00';
  canResend: boolean = false;

  // start resend countdown otp
  startCountdown() {
    this.countdown = 120;
    this.updateCountdownString();
    this.interval = setInterval(() => {
      this.countdown--;
      this.updateCountdownString();
      if (this.countdown <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }
  updateCountdownString(): void {
    const minutes: number = Math.floor(this.countdown / 60);
    const seconds: number = this.countdown % 60;
    if (this.countdown <= 0) {
      this.countdownString = `Resend`;
    } else {
      this.countdownString = `Resend ${this.padZero(minutes)}:${this.padZero(
        seconds
      )}`;
    }

    if (this.countdown <= 0) {
      this.canResend = true;
    }
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
