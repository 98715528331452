<!-- <p>poi works!</p> -->

<div class="container">
  <div class="section">
    <div class="section-info">
      <h3 class="title">
        Proof of Identity<span class="tw-text-[#9FE9FE]">*</span>
      </h3>
      <span class="note">Select which proof of identity to upload</span>
    </div>

    <div class="poi-options">
      <div class="poi-option" (click)="changeIdCategory('primary')">
        <div class="option-details">
          <h3 class="title">One Primary ID</h3>
          <p class="note">
            Passport, Driver’s License, SSS/GSIS, PRC, Postal ID, Voter’s ID,
            UMID, ACR/Immigrant COR, PhilSys ID (National ID & e-PhilID)
          </p>
        </div>
        <img src="../../../../assets/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
      <div class="separator">
        <div class="bar"></div>
        <div>
          <span>OR</span>
        </div>
        <div class="bar"></div>
      </div>
      <div class="poi-option" (click)="changeIdCategory('secondary')">
        <div class="option-details">
          <h3 class="title">Two Secondary IDs</h3>
          <p class="note">
            GSIS e-Card, AFP ID, PNP ID, BPF ID, PWD ID, OFW ID, DSWD
            Certification, Seaman’s Book, Police Clearance, NBI Clearance
          </p>
        </div>
        <img src="../../../../assets/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
    </div>
  </div>

  <app-cta-buttons
    currentPage="poi"
    [pageNames]="advancedPages"
    tier="advanced"
    [nextButton]="false"
  ></app-cta-buttons>
</div>
