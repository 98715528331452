<div
  class="tw-flex tw-flex-col"
  [ngClass]="{
    'tw-space-y-2': labelSlot || label !== '',
  }"
>
  <ng-container *ngIf="labelSlot; else defaultLabel">
    <ng-container *ngTemplateOutlet="labelSlot"></ng-container>
  </ng-container>
  <ng-template #defaultLabel>
    <label class="tw-text-sm tw-font-medium tw-text-gray-700" [for]="id">
      {{ label }}
    </label>
  </ng-template>

  <ng-select
    class="reactive-ng-select"
    [dropdownPosition]="dropdownPosition"
    [appendTo]="appendTo"
    [items]="options"
    [inputAttrs]="{
      id: id
    }"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [ariaPlaceholder]="placeholder"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [formControl]="formControl"
    [appearance]="appearance"
    [loading]="loading"
    [loadingText]="loadingText"
    [id]="id"
    [searchable]="false"
    [trackByFn]="trackByFn"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (open)="onOpen($event)"
    (close)="onClose($event)"
    (clear)="onClear($event)"
    (remove)="onRemove($event)"
    (add)="onAdd($event)"
  >
    <ng-container *ngIf="optionsSlot">
      <ng-container *ngTemplateOutlet="optionsSlot"></ng-container>
    </ng-container>
  </ng-select>

  <ng-container *ngIf="formControl.touched && formControl.errors">
    <span class="tw-text-[14px] tw-text-enstack-status-red tw-mt-[0.25rem]">
      {{ errorMessage }}
    </span>
  </ng-container>
</div>
