import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvancedAddressOption } from 'src/app/services/advanced/schemas/advanced-address-option.schema';

export enum BusinessInfoFormKeys {
  businessName = 'businessName',
  sameAsPickup = 'sameAsPickup',
  zipCode = 'zipCode',
  province = 'province',
  city = 'city',
  barangay = 'barangay',
  line1 = 'line1',
}

export type BusinessInfoForm = ReturnType<typeof BusinessInfoFormModel.build>;

export class BusinessInfoFormModel {
  public static build() {
    const fb = new FormBuilder();
    return fb.group({
      [BusinessInfoFormKeys.businessName]: fb.control<string>('', {
        validators: [Validators.required],
      }),
      [BusinessInfoFormKeys.sameAsPickup]: fb.control<boolean>(false),
      [BusinessInfoFormKeys.zipCode]: fb.control<string>('', {
        validators: [Validators.required],
      }),
      [BusinessInfoFormKeys.province]: fb.control<AdvancedAddressOption | null>(
        null,
        {
          validators: [Validators.required],
        }
      ),
      [BusinessInfoFormKeys.city]: fb.control<AdvancedAddressOption | null>(
        null,
        {
          validators: [Validators.required],
        }
      ),
      [BusinessInfoFormKeys.barangay]: fb.control<AdvancedAddressOption | null>(
        null,
        {
          validators: [Validators.required],
        }
      ),
      [BusinessInfoFormKeys.line1]: fb.control<string>('', {
        validators: [Validators.required],
      }),
    });
  }
}
