import { z } from 'zod';

export const IframeMessageEvent = z.enum([
  'requestFromIFrame',
  'responseFromIFrame',
  'requestFromParent',
  'responseFromParent',
]);

export type IframeMessageEvent = z.infer<typeof IframeMessageEvent>;

export const IframeRequestPayloadSchema = z.object({
  data: z.string(),
});

export type IframeRequestPayload = z.infer<typeof IframeRequestPayloadSchema>;

export const IframeResponsePayloadSchema = z.object({
  status: z.string(),
  result: z.any(),
});

export const IframeEventSchema = z.object({
  type: IframeMessageEvent,
  payload: z
    .union([IframeRequestPayloadSchema, IframeResponsePayloadSchema])
    .nullish(),
});

export type IframeEvent = z.infer<typeof IframeEventSchema>;
