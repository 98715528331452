import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const EXTRAS_API: string = environment.MERCHANT_EXTRAS

@Injectable({
    providedIn: 'root'
})

export class VerifiedService{
    constructor (private client: HttpClient){}

    getTotalProduct(user_id: number):Observable<any>{
        const payload = {
            "user_id": user_id
        }

        return this.client.post<any>(EXTRAS_API + '/onboarding/get_total/products', payload)
    }

    getTotalOrders(user_id: number):Observable<any>{
        const payload = {
            "user_id": user_id
        }

        return this.client.post<any>(EXTRAS_API + '/onboarding/get_total/order', payload)
    }

    getTotalBanner(user_id: number):Observable<any>{
        const payload = {
            "user_id": user_id
        }

        return this.client.post<any>(EXTRAS_API + '/onboarding/get_total/banners', payload)
    }

    getVerify(user_id: number):Observable<any>{
        const payload = {
            "user_id": user_id
        }

        return this.client.post<any>(EXTRAS_API + '/onboarding/get_verify', payload)
    }

    activateVerify(){
        return this.client.post(
            `${environment.PLATFORM_API_URL}/api/v1/merchant-info/verify/`,
            null
        )
    }

    setVerify(user_id: number): Observable<any>{
        const payload = {
            "user_id": user_id
        }

        return this.client.post<any>(EXTRAS_API + '/onboarding/set_verify', payload)
    }

    activatePoB(user_id:any){
        const payload = {
            "user_id": user_id
        }        
        return this.client.post<any>(EXTRAS_API + '/onboarding/activate/pob', payload)
    }
}

