import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from '../back-button/back-button.component';
import { VerifiedHeaderComponent } from 'src/app/pages/verified/verified-header/verified-header.component';
import { StarterHeaderComponent } from 'src/app/pages/starter/starter-header/starter-header.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule, 
    BackButtonComponent, 
    VerifiedHeaderComponent, 
    StarterHeaderComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  currentUrl: string = '';

  constructor(private router: Router) {
    // Subscribe to router events to update the current URL
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });
  }
}
