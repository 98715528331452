import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');

    console.log('VD token interceptor', token);

    if (token) {
      if (!req.headers.has('Authorization')) {
        const clonedRequest = req.clone({
          headers: req.headers.set('Authorization', `Token ${token}`),
        });
        return next.handle(clonedRequest);
      } else {
        console.warn('Request already has an Authorization header.');
      }
    }

    return next.handle(req);
  }
}
