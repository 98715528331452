import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { advancedPages } from '../advanced-data';
import { AdvancedService } from 'src/app/services/advanced/advanced.service';

@Component({
  selector: 'app-poi',
  standalone: true,
  imports: [CtaButtonsComponent],
  templateUrl: './poi.component.html',
  styleUrl: './poi.component.scss',
})
export class PoiComponent {
  advancedPages: string[] = advancedPages;

  constructor(
    private advancedService: AdvancedService,
    private router: Router
  ) {}

  changeIdCategory(category: string) {
    this.advancedService.updateIdCategory(category);
    this.router.navigate(['advanced/upload-id'], {
      queryParams: { pages: 4, count: 1, type: category },
    });
  }
}
