import { CommonModule, NgIf } from '@angular/common';
import {
  Component,
  Input,
  Provider,
  computed,
  forwardRef,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { ValidationMessageFn } from '../types/validation-message-fn';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../image-dialog/image-dialog.component';
import {
  IframeEvent,
  IframeEventSchema,
} from 'src/app/models/iframe-event.model';
import { base64ToBlob } from 'src/app/utils/base64-to-blob';

const REACTIVE_FILE_INPUT_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveFileInputComponent),
  multi: true,
};

@Component({
  selector: 'app-reactive-file-input',
  templateUrl: './reactive-file-input.component.html',
  styleUrls: ['./reactive-file-input.component.scss'],
  providers: [REACTIVE_FILE_INPUT_ACCESSOR],
  standalone: true,
  imports: [CommonModule, FileUrlPipe],
})
export class ReactiveFileInputComponent implements ControlValueAccessor {
  /**
   * This is will try to communicate to the parent application so that the parent
   * can use the native file picker instead of the custom one.
   */
  @Input()
  public shouldUseNativePicker: boolean = false;

  @Input()
  public labelText: string = 'File Input';

  @Input()
  public placeholder: string = 'Upload or Take a Photo';

  @Input()
  public id: string = 'file-input';

  @Input()
  public sampleImageUrl: string = '';

  // Should we accept PDFs here, update the validateLargeFile functions in ID and selfier upload
  // Image compressor will not work with pdf files
  @Input()
  public acceptedFileTypes: Array<String> = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  @Input()
  public errors: ValidationErrors | null | undefined = null;

  @Input()
  public isTouched = false;

  @Input()
  public capturecam: string | null = null;

  @Input()
  public capture: string = '';

  @Input()
  public validationMessage: Record<string, ValidationMessageFn> = {};

  public file = signal<File | null>(null);
  public hasFile = computed(() => this.file() !== null);
  public isFileImage = computed(() => {
    const file = this.file();
    return file ? file.type.includes('image') : false;
  });

  constructor(private dialog: MatDialog) {}

  public get hasErrors(): boolean {
    return this.errors !== null;
  }

  public buildAcceptString(): string {
    return this.acceptedFileTypes.join(',');
  }

  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0] || null;

    this.file.set(file);
    this.onChange(file);
    this.onTouched();
  }

  public onFileRemoved(): void {
    this.file.set(null);
    this.onChange(null);
    this.onTouched();
  }

  public get errorMessage(): string {
    const firstError = Object.keys(this.errors || {})[0];
    const messageFn = this.validationMessage[firstError];
    return messageFn ? messageFn(this.errors) : '';
  }

  private onChange: (value: File | null) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(obj: File): void {
    this.file.set(obj);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  openImageDialog(imageUrl: string): void {
    this.dialog.open(ImageDialogComponent, {
      data: { imageUrl },
      maxWidth: '80vw',
      maxHeight: '80vh',
    });
  }

  openFileDialog(): void {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    fileInput.click();
  }

  openCameraDialog(): void {
    const cameraInput = document.getElementById(
      'camera-input'
    ) as HTMLInputElement;
    cameraInput.click();
  }

  openChoiceDialog(): void {
    // Open the choice dialog to let the user choose between upload and camera
    const choiceDialog = document.getElementById(
      'choice-dialog'
    ) as HTMLDialogElement;
    choiceDialog.showModal();
  }

  closeChoiceDialog(): void {
    const choiceDialog = document.getElementById(
      'choice-dialog'
    ) as HTMLDialogElement;
    choiceDialog.close();
  }

  async onFileInputClick(e: Event) {
    // Check if the user is on mobile and if the shouldUseNativePicker is true
    const isMobile = localStorage.getItem('isMobile') === 'true';

    const canUseNativePicker = this.shouldUseNativePicker && isMobile;
    if (!canUseNativePicker) {
      return;
    }

    e.preventDefault();

    // Send a signal to the parent component to open the native file picker.
    const event: IframeEvent = {
      type: 'requestFromIFrame',
      payload: {
        data: JSON.stringify({
          type: 'fileInput',
          data: {
            accept: this.buildAcceptString(),
          },
        }),
      },
    };

    parent.postMessage(event, '*');

    /// Wait for the response from the parent.
    const result: any = await new Promise((resolve, reject) => {
      const listener = (e: MessageEvent) => {
        const iframeEventResult = IframeEventSchema.safeParse(e.data);
        if (!iframeEventResult.success) {
          reject(
            new Error(
              `Received invalid message from parent: ${JSON.stringify(e.data)}`
            )
          );
          return;
        }

        const iframeEvent = iframeEventResult.data;

        if (iframeEvent.type !== 'responseFromParent') {
          return;
        }

        const payload = iframeEvent.payload!;

        if (!('status' in payload)) {
          return;
        }

        if (payload.status !== 'success') {
          reject(new Error('Failed to open the native file picker.'));
        }

        console.log('Received response from parent:', payload);
        // Resolve the promise with the result.
        resolve(payload.result);
      };

      window.addEventListener('message', listener);
    });

    if (result instanceof Array) {
      const base64File = result[0];
      const blob = base64ToBlob(base64File.fileBase64, base64File.fileType);
      const file = new File([blob], base64File.fileName, {
        type: base64File.fileType,
      });
      this.file.set(file);
      this.onChange(file);
      this.onTouched();
      return;
    }

    // Set the file value to the base64 result
    const blob = base64ToBlob(result.fileBase64, result.fileType);
    const file = new File([blob], result.fileName, {
      type: result.fileType,
    });

    this.file.set(file);
    this.onChange(file);
    this.onTouched();
  }
}
