import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  standalone: true
})
export class AddressAutocompleteComponent implements OnInit, OnChanges {
  @Input() addressType!: string;
  @Input() address: string | null = null
  @Output() placeChange: EventEmitter<google.maps.places.PlaceResult> =
    new EventEmitter();
  @ViewChild('addressInput') addressInput!: ElementRef<HTMLInputElement>;

  queryWait!: boolean;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['address'] && this.addressInput) {
      this.updateInputValue();
    }
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressInput?.nativeElement,
      {
        componentRestrictions: { country: 'PH' },
        types: [this.addressType], // 'establishment' / 'address' / 'geocode'
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.placeChange.emit(place);
    });
  }

  private updateInputValue() {
    if (this.address) {
      this.addressInput.nativeElement.value = this.address;
    }
  }
}
